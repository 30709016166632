

.HomeContentBody {
   .hh_tilte {
      font-weight: bold;
      position: relative;
      font-size: 15px;
      padding-left: 15px;
   }
   .hh_tilte::after {
      content: '';
      width: 4px;
      height: 13px;
      background-color: #245FA8;
      position: absolute;
      left: 0;
      top: 0;
   }


   .HomeBody {
      width: 100%;
      height: calc(100vh - 50px);
      box-sizing: border-box;
      padding: 15px;

      display: flex;
      justify-content: space-between;
      .left {
         width: 82%;
         height: 100%;
        

         .top {
            height: 65%;
            // border: 1px solid #000;
            display: flex;

            .newsList {
               width: 50%;
               height: 100%;
               box-sizing: border-box;
               background-color: #fff;
               padding: 15px;
               border-radius: 2px;
               position: relative;
               .imgWarp {
                  position: absolute;
                  width: calc(100% - 20px);
                  height: 90%;
                  top: 40px;

                  .content {
                     position: relative;

                     .descript {
                        position: absolute;
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                        width: 100%;
                        background-color: rgba(0,0,0,0.5);
                        z-index: 100;
                        bottom: 60px;
                        padding: 5px 0;
                        padding-left: 20px;
                     }
                  }

                  .ant-carousel {
                     width: 100%;
                     height: 100%;
                  }

                  .Carousel {
                     width: 100%;
                     height: 100%;

                     img {
                        width: 100%;
                        height: 100%;
                        height:530px;
                     }

                  }
                  overflow: hidden;

               }

              

              

            }

            .projectList {
               width: 50%;
               height: 100%;
               background-color: #fff;
               margin-left: 15px;
               padding: 15px;
               font-size: 15px;

               .newsTitle {
                  display: flex;
                  h1 {
                     margin-right: 20px;
                     font-weight: bold;
                     cursor: pointer;
                  }

                  .active {
                     color: #004EA1;
                     position: relative;
                  }
                  .active::after {
                     content: '';
                     width: 100%;
                     height: 3px;
                     position: absolute;
                     bottom: -10px;
                     left: 0;
                     background-color: #004EA1;
                  }
               }
               
               .news {
                  width: 100%;
                  height: 97%;
                  margin-top: 7px;
                  overflow-y: auto;
                  background-color: #fff;
                  padding: 3px;

                  

                  li {
                      justify-content: space-between;
                      padding: 10px;

                      span {
                          background-color: #EE5C6A;
                          color: #fff;
                          margin-right: 20px;
                          font-size: 12px;
                          padding: 1px 3px;
                      }

                  }

                  li:nth-child(odd) {
                      background-color: #EFEFEF;
                  }

                  p {
                      width: calc(100% - 150px);
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      cursor: pointer;
                  }



              }


            }
   

         }

         .bottom {
            margin-top: 15px;
            height:calc(35% - 15px);
            // border: 1px solid #000;
            display: flex;
            justify-content: space-between;
            >div {
               width: 38%;
               background-color: #fff;
               height: 100%;
               padding: 15px;
               border-radius: 2px;
               box-shadow: 0 0 10px rgba(0, 0, 0,0.05);
               >div {
                  width: 100%;
                  height: 100%;

                  .setEchart  {
                     
                     width: 100%;
                     height: 100%;
                  }
               }
            }

            .Attendance {
               position: relative;
               
               .hh_tilte {
                  position: absolute;
               }

               .today {
                  width: 93.9%;
                  position: absolute;
                  text-align: right;
                  top: 45px;
                  font-size: 13px;

                  p {
                     padding-top: 15px;
                     span {
                        font-size: 30px;
                        color: #C24E63;
                        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                     }
                  }
               }
            }



            .notice {
               width: 22%;
               
               .title {
                  display: flex;
                  font-size: 14px;
                  padding-top: 10px;
                  div {
                     text-align: center;
                     width: 20%;
                  }
               }


               ul {
                  margin-top: 10px;
                  font-size: 14px;
                  height: 80%;
                  overflow-y: auto;
               }

               li {
                  justify-content: space-between;
                  padding: 10px 0;

                  p {
                     width: 20%;
                     text-align: center;
                  }

              }

              li:nth-child(odd) {
                  background-color: #EFEFEF;
              }

            //   p {
            //       width: calc(100% - 150px);
            //       white-space: nowrap;
            //       text-overflow: ellipsis;
            //       overflow: hidden;
            //       cursor: pointer;
            //   }


            }
         }

      }
      .right {
         margin-left: 15px;
         width: 18%;
         height: 100%;
         >div {
            padding: 15px;
            border-radius: 2px;
         }
         // border: 1px solid #000;

         .top {
            width: 100%;
            height: 40%;
            background-color: #fff;

            ul {
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               height: 100%;
               li {
                  // margin-top: 40px;
                  width: 50%;
                  display: flex;
                  font-weight: bold;
                  align-items: center;
                  font-size: 15px;
                  
                  // margin-bottom: 25px;
               }

               a {
                  display: flex;
                  align-items: center;
               }

               span {
                  border-radius: 10px;
                  padding: 10px;
                  // border: 1px solid #ccc;
                  margin-right: 15px;
               }
               img {
                  width: 20px;
                  height: 20px;
               }
            }

         }

         .bottom {
            margin-top: 15px;
            width: 100%;
            height: calc(60% - 15px);
            background-color: #fff;
            
            ul {
               padding-left: 50px;
               font-size: 15px;
               height:95%;
               overflow-y: auto;
               
               li {
                  margin-top: 30px;
                  position: relative;
                  .title {
                     color: #B0B0B0;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     margin-bottom: 10px;
                     span {
                        padding: 5px 0;
                        display: inline-block;
                        width: 60px;
                        text-align: center;
                        border-radius: 2px;
                        background-color: #AFAFAF;
                        color: #fff;
                        position: relative;
                        left: -10px;
                     }
                  }
                  .context {
                     line-height: 25px;
                  }


                  .Line {
                     position: absolute;
                     left: -31px;
                     height: 135%;
                     width: 6px;
                     top: 17px;
                     background-color: #C7D8EA;
                  }

                  .cube {
                     position: absolute;
                     left: -40px;
                     // font-size: 20px;
                     top: -3px;
                     svg {
                        font-size: 25px;
                     }

                     .round {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        border: 3px solid #AFAFAF;
                        position: absolute;
                        top: 10px;
                        left: 6.8px;
                     }

                  }

               }

               li:last-child {
                  .Line {
                     display: none;
                  }
               }

               


            }

         }

      }
   }




}


.setHeight {
   img {
      width: 100% !important;
   }

   font-size: 20px;
   line-height: 50px;
   min-height: 400px;
   max-height: 600px !important;
}

.setHeight::-webkit-scrollbar{

   display:none;

}

