

.ConstructionLogModal {

    .box {
        scrollbar-width: none; /* Firefox浏览器 */
        -ms-overflow-style: none; /* Internet Explorer和Edge浏览器 */
    }

    li {
        display: grid;
        grid-template-columns: 150px auto;
        line-height: 40px !important;
        >span {
            width: 150px !important;
            border: .5px solid #F4F5F9;
            border-bottom: none;
        }

        >div {
            border: .5px solid #F4F5F9;
            border-left: none;
            border-bottom: none;
            padding: 0 10px;
        }

        .imgDiv {
            padding: 5px 10px;
        }
    }

    li:last-child {
        >span {
            border-bottom: .5px solid #F4F5F9;
        }

        >div {
            border-bottom: .5px solid #F4F5F9;
        }
    }
}