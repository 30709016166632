.Backstage {
    position: relative;
    margin: 10px;
    margin-right: 3px;
    padding-top: 10px;
    background-color: #fff;
    :global {

        .pos {
           
            margin-top: 20px;
            height: calc(100vh - 250px);
            overflow-y: auto;
        }

        .UseConfigTitle {
            width: 450px;
            padding: 0 20px;
            padding-left: 30px;
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            font-weight: bold;
        }

        .useConfigUl {
            width: 400px;
            overflow-y: auto;
            
            .li {
                position: relative;
                cursor: pointer;
                .setWidth {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    position: relative;

                    div {
                        display: flex;
                        align-items: center;
                    }
                }
                img {
                    width: 20px;
                    margin-right: 5px;
                }

                span {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .toSbmit {
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
            .setPadding {
                padding: 0 30px;
            }
        }

    }
}