



.DrawingListWarp {
    display: flex;
}




.leftWarp {
    width: 450px;
    // height: 100vh


    .table {
        height: calc(100vh - 210px);
        margin-left: 15px;
        overflow-y: auto;
        background: #fff;
        .status2 {
            display: none;
        }

        
        



        ul {
            background-color: #fff;
            height: 100%;
            padding: 10px 5px;


           li {
               margin-bottom: 15px;
               cursor: pointer;
           } 

           .setType3 {
                background-color: #FEBB22 !important;
            }

            .setType4 {
                background-color: #FF5257 !important;
            }





            .list {
                display: flex;
                position: relative;    
                padding: 0px 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #F2F2F2;
               .text {
                   width: 90%;
                   p {
                    width: 95%;
                    overflow: hidden;
                    line-height: 20px;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    color: #808080;
                   }

                   p:first-child {
                       font-size: 15px;
                   }


                p:last-child {
                    font-size: 12px;
                    position: relative;
                } 
                
                .random {
                    padding-left: 15px;
                    margin-top: 5px;
                }


                .random::after {
                    content: '';
                    width: 7px;
                    height: 7px;
                    background-color: #2FC59A;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    border-radius: 50%;
                }
 
               }

               .img {
                width: 10%;
                img {
                    width: 100%;
                }
               }


            }

            .noShow {
                border-bottom: 0px solid #F2F2F2;
                padding-left: 50px;
                margin-top: 8px;
                position: relative;    
                span {
                    font-size: 12px;
                    background-color: #2FC59A;
                    color: #fff;
                    padding: 3px 8px;
                    border-radius: 3px;
                }
            }

            .noShow::after {
                content: '';
                width: 7px;
                height: 7px;
                background-color: #FEBB22;
                position: absolute;
                left: 10px;
                top: 20px;
                border-radius: 50%;
            }



            .noShow::before {
                content: '';
                width: 1px;
                height: 60px;
                background-color: #FEBB22;
                position: absolute;
                left: 13px;
                top: -35px;
            }

            .noShow:first-child::before  {
                height: 28px;
                top: -5px;
            }

        }
        

    }


}


.rightWarp {
    width: calc(100% - 450px);
    margin-left: 15px;



    .setType3 {
        background-color: #FEBB22 !important;
    }

    .setType4 {
        background-color: #FF5257 !important;
    }

    .tableChange {
        display: flex;
        height: 62px;
        li {
            margin-right: 20px;
            background-color: #808080;
            height: 40px;
            line-height: 40px;
            width: 28%;
            margin-top: 22px;
            padding: 0 20px;
            border-radius: 15px 15px 0 0;
            color: #fff;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            .setSpan {
                font-size: 12px;
                background-color: #2FC59A;
                color: #fff;
                padding: 3px 8px;
                margin-right: 10px;
                border-radius: 3px;
            }
        }

  
    }


    .active {
        background-color: #fff !important;
        color: #808080 !important;
    }

    .drawing {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: calc(100vh - 205px);
        overflow-y: auto;
        display: flex;
        justify-content: center;
        canvas {
         //   width: 100% !important;
            width: 100% !important;
            
        }

    }


    .setBtn {
        position: fixed;
        right: 30px;
        z-index: 100;
        top: 220px;
    }

    .setBtn>div {
        margin-bottom: 15px;
    }


}

