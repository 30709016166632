

.HomeRouter {
    overflow: hidden;
 
     .header {
         width: 100%;
         height: 50px;
         display: flex;
         position: relative;
         background-color: #fff;
         justify-content: space-between;
         z-index: 5;
         
         .back {
             position: absolute;
             left: 0;
             width: 27.9%;
             height: 100%;
             transform: skewX(-20deg);
             background-color: #004EA1;
         }
         .back2 {
             position: absolute;
             right: 0;
             width: 71.9%;
             height: 100%;
             transform: skewX(-20deg);
             // background-color: #C71D4C;
             background-color: #C71D4C;
         }

         .buleLayout {
             z-index: 2;
             background-color: #004EA1;
             width: 26%;
             height: 100%;
             display:flex;
            //  justify-content: center;
             align-items: center;
 
             img {
                 width: 380px;
             }

             .projectDes {
               padding-left: 20px;
               color: #fff;
               .china {
                  font-size: 18px;
                  font-weight: bold;
               }
               .english {
                  padding-top: 5px;
                  font-size: 12px;
               }
             }
         }
 
         .redLayout {
             z-index: 2;
             background-color: #C71D4C;
             width: 71%;
             height: 100%;
             display: flex;
             align-items: center;
             color: #fff;
             position: relative;
             .navWarp {
                display: flex;
                align-items: center;
                min-width: 170px;
             }
 
             .nav {
                display: flex;
                align-items: center;
                
                height: 45px;
                position: relative;
                img {
                   width: 20px;
                }
                p {
                   padding-left: 20px;
                   font-size: 13px;
                   white-space: nowrap;
                }
 
                ul {
                   position: absolute;
                   // top: 50px;
                   top: 42px;
                  //  width: 250px;
                   z-index: 3;
                   // height: 0;
                   // display: none;
                   pointer-events: none;

                   .move::-webkit-scrollbar {
                     display: none;
                   }

                   .move {
                      transition: transform 0.6s, opacity 0.6s;
                      transform:translateY(10%);
                      opacity:0;
                      height: 436px;
                     overflow: auto;
                        


                      li {
                         // pointer-events: none;
                         width: 100%;
                         overflow: hidden;//溢出隐藏
                        white-space: nowrap;//禁止换行
                        text-overflow: ellipsis;//...

                         background-color: #C24E63;
                         height: 35px;
                         line-height: 35px;
                         margin-top: 8px;
                         font-size: 13px;
                         padding: 0 10px;
                         cursor: pointer;
                         transition: background 0.5s, color 0.5s, -webkit-transform 0.5s;
                      }
                      li:hover {
                         background-color: #004EA1;
                      }
                   }
                }
             }

             .nav::before {
                 content: '';
                 position: absolute;
                 right: -20px;   
                 width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 8px solid #fff;
             }
             
 
             .nav:hover {
                // display: 'block';
                ul {
                   pointer-events:initial;
                }
                .move {
                   transform:translateY(0%);
                   opacity: 1;
                }
                
             }

           
 
 
             .selectPage {
                display: flex;
                justify-content: end;
                width: 100%;
                font-size: 14px;
                ul {
                   display: flex;
                   align-items: center;
                   padding-right: 20px;
                   li {
                      margin-left: 50px;
                      cursor: pointer;
                      position: relative;
                      font-size: 16px;
 
                      display: flex;
                      align-items: center;
                      img {
                         width: 40px;
                         padding-right: 10px;
                      }
                   }

                   .alertReturn {
                        // pointer-events:initial;
                        position: relative;
                        min-width: 80px;
                        
                        div {
                            transition: transform 0.6s, opacity 0.6s;
                            transform:translateY(20%);
                            opacity:0;
                            position: absolute;
                            top: 30px;
                           //  pointer-events: none;
                            .li {
                              
                               // pointer-events: none;
                               width: 130%;
                               text-align: center;
                               background-color: #C24E63;
                               height: 35px;
                               line-height: 35px;
                               margin-top: 8px;
                               font-size: 12px;
                               padding: 0 10px;
                               cursor: pointer;
                               transition: background 0.5s, color 0.5s, -webkit-transform 0.5s;
                            }
                            .li:hover {
                               background-color: #004EA1;
                            }
                         }
                   }


                  //  .alertReturn:before  {
                  //     div {
                  //       pointer-events: none;
                  //     }
                  //  }

                   .alertReturn:hover {
                        pointer-events:initial;
                        div {
                        transform:translateY(10%);
                        opacity: 1;
                        }
                    
                 }


                   .active {
                      font-weight: bold;
                      position: relative;
                   }
 
                   .active::after {
                      content: '';
                      position: absolute;
                      left:calc(50% - 7px);
                      bottom: -18px;
                      border: 7px solid transparent;
                      border-bottom: 7px solid #fff;
                   }   

                }
             }
           
 
         }
 
 
     }
 
 
 }



