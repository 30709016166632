


.PartyActive {
  

    .table {
    
        background-color: #fff;
    }
    

    .input {
        ul {
            display: flex;
            padding: 0px 20px;
            padding-top: 20px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            height: 30px;
        }

    }


    .input {
        ul {
            li {
                margin-left: -10px !important;
                margin-bottom: 20px;
            }
        }
        
    }


    .table2 {
        padding: 0px 10px;
    
        .allBtn {
            margin-bottom: 0px;
            text-align: right;
            .bt {
                margin-left: 20px;
            }
        }
    }

    .setexImg {
        display: flex;

        h1 {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .setqindan {
            margin-left: 30px;
        }

        span {
            margin-right: 20px;
        }
    }

}


.partyActiveModal {
    min-height: 200px;
    max-height: 600px;
    overflow-y: auto;

    h1 {
        font-size: 20px;
        font-weight: bold;
        line-height: 40px;
    }

    h2 {
        font-size: 15px;
        font-weight: bold;
        margin-top: 30px;
    }
    p {
        text-indent:30px;
    }


    .status {
        margin-top: 20px;
        span {
            padding: 5px 10px;
            background: #F0F0F0;
            color: #888888;
            border-radius: 3px;
        }


        .span1 {
            background: #CD2929;
            color: #fff;
            margin-right: 20px;
            
        }
    }

    .setList {
        display: flex;
        flex-wrap: wrap;
        p {
           width: 10%; 
           text-align: center;
           text-indent:0px;
           color: #888888;
        }
    }

}
