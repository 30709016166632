.productionDetails {
  margin: 15px;
  background-color: #fff;
  .search-wrap {
    padding: 10px 30px;
    border-bottom: 1px solid #e7e7e7;
  }
  .table-wrap {
    padding: 15px;
  }
}