.monitoringManagement {
  padding: 0 10px !important;
  .box {
    background-color: #fff;
    margin-top: 10px;

    h3 {
      line-height: 50px;
      padding-left: 10px;
      border-bottom: 1px solid #f4f1f1;
      color: #a9a6a6;
    }

    .box-content {
      padding: 30px 10px;
    }
  }

  .add-box {
    .box-content {
      position: relative;
      .submit-btn {
        position: absolute;
        left: 100px;
        bottom: 24px;
        line-height: 32px;
        padding: 0 50px;
        background-color: #004EA1;
        color: #fff;
        border-radius: 2px;
        cursor: pointer;
      }
    }

  }

  .list-box {
    .box-content {
      .table-th {
        line-height: 1.5;
        font-size: 12px;
        color: #a9a6a6;
      }
      .height-wrap {
        margin-top: 20px;
        height: 310px;
        overflow-y: scroll;
        overflow-x: hidden;
        .table-td {
          & + .table-td {
            margin-top: 20px;
          }
          .delete-btn {
            line-height: 32px;
            background-color: #ff0000;
            text-align: center;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      
    }
  }
}