.videoSurveillance {

  // .setWidth {
  //   width: 100%;
  //   overflow-x: auto;
    
  // }



  .nav-list {
    display: flex;
    // margin-top: 10px;
    flex-wrap: wrap;

    li {
      margin: 0 10px;
      background-color: #fff;
      padding: 8px 20px;
      border-radius: 3px;
      cursor: pointer;
      white-space:nowrap;
      margin-top: 10px;

      // &:nth-of-type(1) {
      //   margin: 0;
      // } 

      &.active {
        background-color: #004EA1;
        color: #fff;
      }
    }

  }
  .video-list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 10px;
    li {
      flex: 0 0 calc(25% - 20px);
      margin: 0 10px;
      background-color: #fff;
      margin-bottom: 15px;
      cursor: pointer;
      line-height: 0;
      img {
        width: 100%;
        height: 180px;
        
      }
      p {
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .list4 {
    li {
      height: 330px;
      flex: 0 0 calc(50% - 20px);
      img {
        height: 300px !important;
      }
    }
  }



  .pagination-list {
    display: flex;
    justify-content: center;
    li {
      width: 50px;
      height: 5px;
      background-color: #d9d9d9;
      margin: 0 3px;
      cursor: pointer;
      &.active {
        background-color: #63abff;
      }
    }
  }
}