.PageIndex {


    .layer {
        // display: none;
       position: relative;
       width: 100%;
       height: 100%;
    }

    .scale-box{
        transform-origin: 0 0;
        position: absolute;
        left: 50%;
        top: 50%;
        overflow: hidden;
        // transition: 0.3s;

        background-color: #0D1E37;

        font-size: 16px;


        color: #fff;

        padding: 30px;

        h1,h2,h3,h4,h5,h6 {
            color: #fff;
        }

        .head {
            display: flex;
            align-items: center;
            .iconImg {
                width: 200px;
                margin-top: -10px;
            }

            li {
                cursor: pointer;
            }

            .cube {
                width: 3px;
                height: 40px;
                margin-left: 15px;
                background-color: #BCBFC4;
                border-radius: 10px;
            }


            .bannerImg {
                padding-left: 20px;
                h1 {
                    font-size: 25px;
                    padding-bottom: 5px;
                }

                .EngLish {
                    letter-spacing:2px;
                    width: 420px;
                    overflow: hidden;
                    white-space:nowrap;  
                    p {
                        position: relative;
                        animation:move 10s linear infinite;   
                    }
                }


                @keyframes move{
                    0%{-webkit-transform:translate(0px, 0);}
                    25%{-webkit-transform:translate(-120px, 0);}
                    50%{-webkit-transform:translate(-260px, 0);}
                    75%{-webkit-transform:translate(-380px, 0);}
                    100%{-webkit-transform:translate(-500px, 0);}
                }

                
            }

            .select {
                width: calc(100% - 690px);
                display: flex;
                justify-content: space-between;

                
                .wather {
                    position: relative;
                    
                    margin-right: 50px;
                    width: 200px;
                    
                    .setAbsolt {
                        position: absolute;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;
                        line-height: 25px;
                        .icon {
                            width: 40px;
                            height: 40px;
                            // border: 1px solid #fff;
                        }

                        p {
                            font-size: 26px;
                            span {
                                font-size: 18px;
                            }
                        }

                        h4 {
                            font-size: 14px;
                        }

                        h5 {
                            font-size: 12px;
                        }
                    }
                }
            }

            ul {
                margin-left: 50px;
                height: 30px;
                display: flex;
                justify-content: space-between;
                position: relative;
                border-bottom: 1px solid #556173;
                li {
                    width: 150px;
                    text-align: center;
                }
            }
            ul::after {
                content: '';
                width: 7px;
                height: 7px;
                background-color: #06FDFF;
                border-radius: 50%;
                position: absolute;
                bottom: -4px;
                left: -30px;
            }

            ul::before {
                content: '';
                width: 7px;
                height: 7px;
                background-color: #06FDFF;
                border-radius: 50%;
                position: absolute;
                bottom: -4px;
                right: -30px;
            }
            

        }

      }
}


