


.EvaluationRule {
  width: 100%;
  height: 100%;

  .top {
      height: 100px;
    //   background-color: #fff;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      .tips {
          width: calc(66.6666666666666% + 5px);
          background-color: #fff;
          margin-right: 5px;

          padding: 10px;
          
          h1 {
              line-height: 20px;
              font-size: 14px;
              color: #004EA2;  
              font-weight: bold;

             
            }

          p {
              line-height: 30px;
              font-size: 12px;
              span {
                color: #004EA2;
              }

              i{
                  font-weight: bold;
              }
          }

      }

      .TemplateBtn {
        margin-left: 5px;
        width: 33.3333333333333%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        .warp {
            width: calc(50% - 5px);
            height: 100%;
            background-color: #0DC076;
            color: #fff;
            border-radius: 2px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;   
            position: relative;
            svg {
                font-size: 30px;
            }

            a {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
              padding-top: 10px;
            }
        }
        .div {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

        .warp:nth-child(2) {
          background-color: #004EA2;
          margin: 0 10px;
        }

        
        .warp:last-child {
          cursor: pointer;
           background-color: #C71D4C;
        }
      }
  }

  .bottom {
      display: flex;
      justify-content: space-between;
      height: calc(100% - 110px);
      margin-top:10px;
      width: 100%;
      box-sizing: border-box;
      .box { 
        // flex: 0 0 calc(33.33333%);
        width: 33.3333333%;
        height: 100%;

        // margin-left: 10px;
        background-color: #fff;
      }
      .box:nth-child(1) {
        
        margin-right: 10px;
      }

      .box:nth-child(2) {
        margin-right: 5px;
      }

      .box:nth-child(3) {
        margin-left: 5px;
      }

  }

  .currentRule {
        
      .title {
          padding: 30px 0;
          padding-bottom: 10px;
          text-align: center;
          h1 {
              font-size: 20px;
              font-weight: bold;
          }
          p {
              font-size: 12px;
              line-height: 20px;
          }
      }

      .scroll {
          height: calc(100% - 90px);
          overflow-y: hidden;
         position: relative;
        }
      
      .table {
          position: absolute;
          width: 100%;
          height:100%;
          overflow-y: auto;
          display: flex;
          justify-content: center;
          margin-top: 0px;
      }

      .table::-webkit-scrollbar { width: 0 !important }

      table {
          width: 97%;
          td {
              border: 1px solid #F2F2F2;
              padding: 5px 15px;
              line-height: 20px;
              font-size: 12px;
          }

          .setWidth {
              width: 60%;
              text-align: left;
          }

          tbody {
              tr {
                td:first-child {
                    border-left: none;
                }
                td:last-child {
                    border-right: none;
                }
              }


              tr:nth-child(even) {
                background-color: #F0F2F5;
              }
          }

          thead {
            color: #004EA2;
            background-color: #F0F2F5;
            
            th {
                text-align: center;
                padding: 15px 0;
                font-weight: bold;

                
            }  
          }
      }
  }


}