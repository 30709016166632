

.BIDataKanban2 {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0.1rem;
    padding-right: 0.03rem;

    // .scale-box {
    //     transform-origin: 0 0;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    // }

    .head {
        display: flex;
        justify-content: space-between;
    }

    .leftText {
        // width: 300rem;
        width: 35%;
        height: 2rem;
        margin-right: 0.1rem;
        background-color: #fff;

        padding: 0.2rem 0.25rem;
        line-height: 0.2rem;
        font-size: 0.12rem;
        color: #7F7F7F;

        .fenge {
            height: 0.1rem;
        }

        h1 {
            color: #2FC59A;
            font-size: 0.3rem;
            font-weight: bold;
            line-height: 0.4rem;
        }
        .blue {
            color: #106CFF;
        }
    }

    .rightText {
        width: 65%;

        .setRightDom {
             overflow: hidden;
            width: 100%;
            // margin-left: 10rem;
            position: relative;

            .point {
                display: flex;
                position: absolute;
                z-index: 100;
                width: 100%;
                justify-content: center; 
                bottom: 0.1rem;                   
                
                p {
                    width: 0.15rem;
                    height: 0.15rem;
                    margin: 0 0.05rem;
                    border-radius: 50%;
                    background-color: #ccc;
                    cursor: pointer;    
                }

                .active {
                    background-color: #FFC000;
                }
            }

        }


        ul {
            display: flex;
            align-items: flex-end;
            position: relative;

            transition: transform 2s;
            transition-timing-function:linear;
            transform: translate(0rem,0rem);
            li {
                width: 50%;
                height: 2rem;
                background-color: #fff;
                margin-left: 0.1rem;
                padding: 0.1rem 0.3rem;
                // align-items: flex-end;
                padding-top: 0.4rem;
                flex-shrink: 0;
                // flex-wrap: wrap;
                
                .setLabName {
                    line-height: 0.35rem;
                    color: #808080;
                    h1 {
                        padding-bottom: 0.05rem;
                        font-weight: bold;
                        position: absolute;
                        top: 0.1rem;
                        width: 2.33rem;
                        i {
                            color: #FFC000;
                            padding-left: 0.2rem;
                        }
                    }

                    .context {
                        padding-left: 0.2rem;
                    }
                }

                .echartId {
                    width: 3rem;
                    height: 1.5rem;
                    margin-left: 0.2rem;
                }

            }

            li:first-child {
                margin-left: 0 !important;
            }   

        }
    }


    .body {
        margin-top: 0.1rem;
    }

    .leftContex {
        width: 75%;  
        .Histogram {
            background-color: #fff; 
            height: 2.75rem;

            .echarts1 {
                width: 100%;
                height: 100%;
            }
        }

        .line {
            background-color: #fff;
            height: 2.75rem;
            margin-top: 0.1rem;
            .echarts2 {
                width: 100%;
                height: 100%;
            }
            position: relative;
            
            .liHeight {
                position: relative;
                animation-name: example;
                animation-duration: 4s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }

            @keyframes example {
                from {top: 0%;}
                to {top: -100%;}
            }
  
            ul {
                position: absolute;
                width: 2.3rem;
                top: 0.9rem;
                height: 1.6rem;
                overflow: hidden;

                font-weight: normal;
                color: #808080;
                line-height: 0.33rem;
                // overflow-y: auto;
                li {
                    position: relative;
                    padding-left: 0.15rem;
                    z-index: 10;
                    i {
                        display: inline-block;
                        width: 0.7rem;
                        margin-left: 0.1rem;
                    }

                    span {
                        font-weight: bold;
                        color: #333F50;
                        display: inline-block;
                        width: 0.4rem;
                    }

                    b {
                        display: inline-block;
                        width: 0.1rem;
                        height: 0.1rem;
                        border-radius: 50%;
                        position: absolute;
                        background-color: #000;
                        left: 0rem;
                        top: 0.1rem;
                        cursor: pointer;
                    }
                }

                
            }
        }

        .echart {
            padding: 0.2rem 0.3rem;
            font-weight: bold;
        }
    }


    scrollbar-width:none;
        -ms-overflow-style:none;

    .rightContext::-webkit-scrollbar{
        display:none;/*ChromeSafari*/
    }

    .rightContext {
        width: 25%;
        margin-left: 0.1rem;
        height: 5.6rem;
        color: #EBFAF4;
        background-color: #5DD8A8;
        padding: 0.2rem;
        font-size: 0.12rem;
        overflow-y: auto;


        h1 {
            color: #EBFAF4;
            font-weight: bold;
            padding-bottom: 0.2rem;
        }

        .setdetailedInfo {

            h1 {
                padding-left: 0.08rem;
                font-weight: bold;
                color: #EBFAF4;
                font-weight: bold;
            }
    
            li {
                display: flex;
                margin: 0.1rem 0;
                line-height: 0.21rem;
                span {
                    display: block;
                    width: 0.9rem;
                    text-align: center;
                    letter-spacing:0.04rem;
                    color: #EBFAF4;
                }
    
                .noSpacing {
                    letter-spacing:0rem;
                }
    
                div {
                    // width: 60%;
                    width: 1.4rem;
                    padding-left: 0.1rem;
                    line-height: 0.21rem;
                    border-radius: 0.03rem;
                    background-color: #F1F2F6;
                    color: #595959;
                }
    
                .allWidth {
                    width: 3.7rem;
                }
    
            }
        }
    

    }



    .sMsg {
        padding-bottom: 0rem !important;
    }

    .showNone {
        display: none;
    }

    .showBlock {
        display: block;
        line-height: 1.65rem;
        text-align: center;
        position: relative;
        right: 0.5rem;
        color: #bbb;
        font-size: 0.2rem;
        width: 1rem;
    }

}


