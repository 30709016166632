


.Consult {
  

    .table {
    
        background-color: #fff;
    }
    

    .input {
        ul {
            display: flex;
            padding: 0px 10px;
            padding-top: 20px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }

    }


    .input {
        ul {
            li {
                margin-left: -10px !important;
                margin-bottom: 20px;
            }
        }
        
    }


    .table2 {
        padding: 0px 10px;
    
        .allBtn {
            margin-bottom: 20px;
            text-align: right;
            .bt {
                margin-left: 20px;
            }
        }
    }

    .setexImg {
        display: flex;

        h1 {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .setqindan {
            margin-left: 30px;
        }

        span {
            margin-right: 20px;
        }
    }


    .setStatusColor2 {
        color: #F7C027;
    }

    .setStatusColor3 {
        color: #CD2929;
    }

    .setStatusColor4 {
        color: #ccc;
    }

    .remarks {
        margin: 0 auto;
        width: 150px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        text-align: center;
    }

}


.consultModal::-webkit-scrollbar{
        display:none;/*ChromeSafari*/
}

.consultModal {
    min-height: 200px;
    max-height: 650px;
    overflow: auto;
    overflow-y:scroll;
    
    scrollbar-width:none; 
    -ms-overflow-style:none;

    .content {
        margin-top: 30px;
    }

    b {
        color: #CD2929;
        cursor: pointer;
    }

    .titleUl {
        display: flex;
        justify-content: space-around;
        text-align: center;
        padding-bottom: 30px;
        li {
            h3:first-child {
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

    h1 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
    }

    .status {
        margin: 10px 0;
        span {
            background: #EDEDED;
            color: #808080;
            padding: 3px 5px;
            
            margin-right: 10px;
        }

        .statusColor2 {
            color: #fff;
            background: #F7C027;
        }

        .statusColor3 {
            color: #fff;
            background: #CD2929;
        }
    }

    



    h2 {
        color: #B5B5B5;
        font-size: 12px;
    }

    p{
        margin-top: 20px;
    }


    .userList {
        margin-top: 30px;
        li {
            line-height: 50px;
            display: flex;
            justify-content: space-between;
            
            border-top: 1px solid rgba(0,0,0,0.1);
            span {
                margin-right: 20px;
                margin-left: 10px;
                color: #CD2966;
                border-radius: 50%;
                background: #F7F8FA;
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
            }

            i {
                color: #CD2929;
                font-weight: bold;
            }
        }

        li:last-child {
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
        
    }


    .agreen {
        display: flex;
        margin-top: 30px;
        div {
            padding: 5px 0;
            width: 50%;
            min-height: 50px;
            text-align: center;
            background-color: #CD2929;
            color: #fff;
           h5 {
               color: #fff;
           } 
           h5:last-child {
               font-size: 10px;
           }
           position: relative;
        }

        div::after {
            content: "";
            width: 50px;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: #CD2929;
            right: -15px;
            transform: skewX(-30deg);
        }
        div:last-child::after {
            right: 0px;
            left: -15px;
            background-color: #333F50;
        }

        div:first-child {
            width: 80%;
            
        }

        div:last-child {
            margin-left: 40px;
            width: 20%;
            background-color: #333F50;
        }
        

    }

}
