

.signCanvas {
    position: relative;

    :global {


        canvas {
            border: 1px solid #ccc;
        }

 

        .cler {
            position: absolute;
            right: 0px;
            cursor: pointer;
            border: 1px solid #ccc;
            padding: 5px 8px;
            border-radius: 5px;

        }
    }

}