


#container {
    width:100%;
    height:100vh;
    position:relative;
    overflow: hidden;
}


@font-face {
    font-family: mFont;
    src: url('../../assets/font/4124_fzzy_GBK.ttf');
}    


// #container {
   
// }

.Earth {
    p,div,h1,h2, span {
        color: #fff;
        // font-family:"mFont"
    }

    .head>div , h1, h2,  .foot>div{
        font-family:"mFont"
    }

    .province {
        background-color: rgba(8, 93, 190, 0.7);
        line-height: 40px;
        padding: 0 10px;
        font-size: 20px;
    }

    .set000 {
        background-color: rgba(0,0,0,0);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
    }



.pos {
    width: 35%;
    position: absolute;
    top: 120px;
    right: 0;
    z-index: 100;
    
    h1 {
        color: #fff;
        font-size: 26px;
    }

    .English {
        margin-top: 10px;
        font-size: 14px;
    }


    .smallTitle {
        margin-top: 40px;
        position: relative;
        padding-left: 5px;
        img {
            position: absolute;
            left: -5px;
            width: 180px;
            top: -7px;
        }
    }

}

.lv {
    margin-top: 20px;
    ul {
        display: flex;
        text-align: center;
        
        li {
            margin-right: 40px;
            p {
                line-height: 30px;
                
            }
        }

        .color1 {
            color: #FD386D;
            font-size: 20px;
            font-weight: bold;
        }
        .color2 {
            color: #7FC677;
            font-size: 20px;
            font-weight: bold;
        }
        .color3 {
            color: #2577C6;
            font-size: 20px;
            font-weight: bold;
        }

    }
}


.cub {
    margin-top: 20px;
    ul {
        display: flex;
        li {
            width: 280px;
            height: 180px;
            background-color: #3B71CA;
            padding: 10px 20px;

        }
        
        li:nth-child(2) {
            background-color: #3EC58A;
        }

        .p2 {
            display: flex;
            flex-wrap: wrap;
            div {
                width: 50%;
                text-align: right;
            }
            div:nth-child(odd) {
                text-align: left;
            }
        }
    }

}

.Statistics {
    h2 {
        color: #fff;
        // line-height: 100px;
        padding-bottom: 40px;
        font-weight: bold;
    }

    li {
        margin-bottom: 30px;
        padding-left: 20px;
        display: flex;

    }


    p {
        font-size: 15px;
        width: 200px;
    }

    .progress {
        margin-left: 20px;
        margin-top: 5px;
        height: 5px;
        width: 280px;
        border-radius: 30px;
        background-image: linear-gradient(to right, #4F91FD , #E63222);
    }

}

.head {
    position: absolute;
    left: 50px;
    top: 30px;
    display: flex;
    align-items: center;
    .iconImg {
        width: 150px;
    }

    div {
        color: #fff;
        padding-left: 30px;
        letter-spacing:6px;
        font-size: 30px;  
    }
}


.foot {
    position:absolute;
    bottom: 30px;
    width: 100%;
    margin-left: 40px;
    ul {
        display: flex;
        justify-content: space-between;
       width: 55%;
       li {
           padding-left: 50px;
           cursor: pointer;
       }
    }
}


.Popover {
   position: absolute;


   margin-left: -100px;
   margin-top: -113px;
   padding-bottom: 100px;
   border-radius: 5px;
   overflow: hidden;
    ul {
        background-color: rgba(0,0,0,0.5);
        padding: 5px 10px;
        // border-radius: 5px;
        z-index: 100;
        border-radius: 0 0 5px 5px;
        cursor: pointer;
        padding-bottom: 15px;
        // position: absolute;
        // left: 0%;
        // top: -100px;
    }

    li {
        line-height: 30px;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        img {
            width: 30px;
            height: 30px;
        }
    }

    p {
        padding: 0 5px;
    }

    

    li:last-child {
        border-bottom: none;
    }


    li:hover {
        p {
            color: #00EEFC;
        }
    }


    // .liColor {
    //     p {
    //         color: #00EEFC;
    //     }
        
    // }

    .pos {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        p {
            line-height: 34px;
        }
    }
}

}
