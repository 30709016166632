




// .red {
//     color: brown;
// }

.deviceInspection {

    .head {
        padding: 10px;
        .echarts {
            width: 100%;
            height: 100%;
            canvas {
                width: 100%;
                height: 100%;
            }
        }
        >ul {
            display: flex;
            justify-content: space-between;
            >li {
                height: 250px;
                background-color: #fff;
                position: relative;
                width: 50%;
                margin-left: 10px;
                padding: 15px;
                border-radius: 2px;
                
                h1 {
                    font-weight: bold;

                    span {
                        font-weight: normal;
                        font-size: 12px;
                        zoom: 0.8;
                    }
                }
 
            }
            >li:nth-child(1) {
                width: 25%;
                margin-left: 0;
                
                .number {
                    display: flex;
                    
                    >div {
                        flex: 0 0 50%;
                        text-align: center;
                        h2 {
                            margin-bottom: 50px;
                            margin-top: 60px;
                            font-size: 50px;
                            color: #FFAB34;
                            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                        }
                        .blue {
                            color: #17C26C;
                        }
                        

                    }
                }

            }
            >li:nth-child(2) {
                position: relative;
                .echarts {
                    padding: 20px;
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
            >li:nth-child(3) {
                width: 25%;
                .echarts {
                    
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }



    .statusTable2 {
        color: #19BE6B;
    }
    .statusTable3 {
        color: #ff0000;
    }

    .table {
    
        background-color: #fff;
    }
    
    
    
    .input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;



        ul {
            display: flex;
            padding: 20px 30px;
            flex-wrap: wrap;
            padding-top: 0 !important;
            border-bottom: 1px solid #F2F2F2;
            // height: 70px;
            transition:all .5s;
            overflow: hidden;
            li {
                margin-left: 40px;
                display: flex;
                margin-bottom: 0 !important;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }
    
            
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
            cursor: pointer;
        }
    }
    
    .addOpen {
        height: 150px !important;
        overflow-y: auto !important;
    }
    


    .input {
        ul {
            li {
                margin-left: -10px !important;
            }
        }
        
    }
    
    
    
    
    .translate {
        transform:rotate(180deg);
    }
    
    
    .table2 {
        padding: 10px;
    
        .allBtn {
            margin-bottom: 20px;
            text-align: right;
            .bt {
                margin-left: 20px;
            }
        }
    }
    
    .operation {
        display: flex;
        justify-content: center;
        p {
            color: #6A9BF4;
            cursor:pointer;
        }
        .centp {
            padding: 0 10px;
        }
    }
    
    
    .needWarp {
        h1 {
            display: flex;
    
            p {
                margin-left: 30px;
                position: relative;
            }
    
            p::after {
                content: '';
                width: 10px;
                height: 10px;
                background: #409EFF;
                position: absolute;
                left: -15px;
                border-radius: 100px;
                top:8px;
                border: 1px solid #409EFF;
            }
            .p2::after {
                background: #fff;
                border: 1px solid #DCDFE6;
            }
        }
    
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                margin: 10px;
            }
        }
    }







    .removeOpen {
        overflow:visible !important;
    }

    .operation {
        justify-content: space-around;
    }
    .red {
        color: #ff0000 !important;
    }

    .jilu {
        color: rgb(93, 197, 93) !important;
    }

    .FormStyle>div {
        width: 300px;
    }

}

.deviceInspectionDeviceListModle {


    .deviceInspectionFormStyle {
        display: flex;
        justify-content: space-between;
    
        .ant-form-item-label {
            width: 80px;
        }

    }
    
    .deviceInspectionFormStyle>div {
        width: 27%;
    }
    
    .set50 {
        width: 40%;
    
        .setInput {
            line-height: 45px;
            background-color: #F2F2F2;
            padding: 0px 20px;
            position: relative;
            input {
                width: 90%;
                border: none;
                background-color: #F2F2F2;
                outline:none !important;
                padding-left: 20px;
            }
            input:focus { outline: none !important; }
    
            p {
                width: 80px;
                text-align: right;
                color: #ff0000;
                cursor: pointer;
            }
        }


        .setInput::after {
            content: '';
            width: 88%;
            height: 1px;
            right: 0;
            position: absolute;
            background-color: #fff;
        }

    
        .setInput>div {
            width: 90%;
        }
    
    
        
        .colorCir {
            position: relative;
            top: 2px;
            width: 15px;
            height: 15px;
            display: inline-block;
            background-color: #19BE6B;
            border-radius: 50%;
            cursor: pointer;
        }

        .gay  {
            background-color: #999999 !important;
        }
    
        .addBtn {
            background-color: #DBF1E1;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            border: 1px solid #C6D9CB;
            border-radius: 5px;
            cursor: pointer;
        }
    
    }



    .setFromTilte {
        padding-bottom: 20px;
    }


    .bodyTwo {
        margin-left: 80px;
        p {
            color: #A6A6A6;
            padding-bottom: 20px;
        }
    }


    .removeImgList {
        position: absolute;
        background-color: #000;
        display: inline-block;
        width: 20px;
        height: 20px;
        z-index: 100;
        right: -10px;
        top: -10px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        color: #fff;
        cursor: pointer;
    }


}

