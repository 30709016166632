
.VehiclePositioning {
 
.carStatistics {
    display: flex;
    box-sizing: border-box;


    ::-webkit-scrollbar{

        display:none;/*ChromeSafari*/
                
    }


    .setNone {
        display: none;
    }


    .leftData {
        width: 25%;
        position: relative;
        padding-left: 10px;


        .close {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 30px;
            cursor: pointer;
        }

        // overflow-x: hidden;
        .moduleChangeTime {
            width: 100%;
            height: 350px;
            background-color: #fff;
            position: absolute;
            top: 0;
            z-index: 100;
            box-shadow: 14px 14px 10px #888888;

            h2 {
                font-size: 15px;
                font-weight: bold;
                padding: 20px 20px;
                border-bottom: 1px solid #F1F2F6;
            }

            ul {
                display: flex;
                margin: 0 10px;
                justify-content: space-between;
                margin-top: 20px;
                li {
                    background-color: #F2F2F2;
                    padding: 10px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .timeActive {
                    background-color: #ED7D31;
                    color: #fff;
                }
            }


            .timeWarp {
                width: 100%;
            }

            .setDiv {
                display: flex;
                line-height: 30px;
                margin: 20px 0;
                padding: 0 10px;
                h3 {
                    margin-right: 20px;
                    width: 20%;
                }

                .ant-picker {
                    width: 80%;
                }
                .ant-select {
                    width: 80% !important;
                }
            }

            
            .submitBtn {
                text-align: center;
                
                button {
                    padding: 0px 70px;
                    position: relative;
                    top: 10px;
                    background-color: #ED7D31;
                    border: 1px solid #ED7D31;
                }
            }

        }



        .Statistics {
            background-color: #fff;
            h1 {
                font-weight: bold;
                font-size: 18px;
                padding: 15px 20px;
            }

            .statusWarp {
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                width: 100%;
                padding: 20px 60px;
                
                div {
                    p {
                        text-align: center;
                        line-height: 40px;
                        font-size: 16px;
                    }
                    p:first-child {
                        font-size: 16px;
                        color: #808080;
                    }
                }

                .border2 {
                    position: relative;
                    border-left: 1px solid #F0F2F5;
                    border-right:1px solid #F0F2F5;
                    padding-left: 15%;
                    padding-right: 15%;
                }


                .color1 {
                    color: #00B050;
                    font-weight: bold;
                }
                .color2 {
                    color: #FF0000;
                    font-weight: bold;
                }
                .color3 {
                    color: #7F7F7F;
                    font-weight: bold;
                }


                
                
            }
        }


        .setul {
            background-color: #fff;
            margin-top: 10px;
            padding: 10px;
            position: relative;
            ul {
                display: flex;
                
                justify-content: space-between;
            }

            li {
                cursor: pointer;
            }

            .active {
                color: #2FC59A;
                border-bottom: 3px solid #2FC59A;
                padding-bottom: 10px;
            }

            // .bar {
            //     margin: 0 10px;
            //     margin-top: 10px;
            //     width: 30px;
            //     height: 3px;
            //     position: absolute;
            //     left: 0;
            //     background-color: #2FC59A;
            // }
            
        }

        .carList {
            margin-top: 10px;
            width: 100%;
            height: 490px;
            overflow-y: auto;
            background-color: #fff;
            scrollbar-width:none;/*Firefox*/
            -ms-overflow-style:none;/*IE10+*/
           
            ul {
                padding: 10px;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    margin-bottom: 30px;
                    .imgType {
                        background-color: #F3F3F2;
                        padding: 7px 10px;
                    }
                    img {
                        width: 30px;
                        height: 30px;
                    }

                    .carMsg {
                        // display: flex;
                        line-height: 25px;
                        p {
                            display: flex;
                            .se1 {
                                display: inline-block;
                                width: 80px;
                                color: #7F7F7F;
                                // padding-right: 20px;
                                
                            }

                            .h4 {
                                color: #7F7F7F;
                            }

                            b{
                                color: #fff;
                                padding: 2px 14px;
                                border-radius: 5px;
                                font-size: 12px;
                               background-color:  #FFB5BB;
                            }

                            .statusB3 {
                                background-color: #2FC59A;
                            }

                            .h3 {
                                font-weight: bold;
                            }

                        }
                    }

                    .status {
                        background-color: #F3F3F2;
                        font-weight: bold;
                        padding: 3px 0px;
                        border-radius: 5px;
                        line-height: 20px;
                        width: 120px;
                        text-align: center;
                    }

                    .active {
                        background-color: #2979FF;
                        color: #fff;
                    }
                }
            }
        }

    }

    .rightData {
        width: 75%;
        height: 725px;
        margin: 0 10px;
        background-color: #fff;

        #container {
            border: 3px solid #fff;
            width: 100%;
            height: 100%;
        }
    }
}

   
}


.alertPPP {
    color: #333 !important;
    text-align: left;
    line-height: 25px;
    padding-right: 40px;

}