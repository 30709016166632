


.MeetingSignIn {
    margin: 10px;
    position: relative;


    .ant-table-expanded-row > td {
        padding: 0;
    }


    .tableCa {
        display: flex;
        p {
            // margin-right: 50px;
            position: relative;
        }

        span {
            padding: 0 30px;
        }

    }

    .setXUhao {
        text-align: center;

            span {
                background-color: #F1F2F6;
                padding: 8px 20px;
                border-radius: 5px;
            }
    }


    .posAbs {
        position: absolute;
        z-index: 100;
        right: 60px;
        top: 10px;

        .ant-btn-primary {
            background: #FFD400;
            border-color: #FFD400;
            color: #fff;
        }
    }

    .tableWarp {
        box-sizing: border-box;
        overflow: hidden;
        height: 100%;
    }

    .setWidth {
        width: 100%;
        position: relative;
    }

    .addWidth {
        width: 700px !important;
        white-space:normal !important;
    }



    .detail {
        width: 0px;
        height: 747px;
        // overflow-y: auto;
        white-space:nowrap;
        transition:width 1s;
        min-height: 700px;
        background-color: #fff;
        margin-left: 10px;
        position: relative;

        .close {
            position: absolute;
            width: 100%;
        }

        .reactive {
            position: absolute;
            right: 12px;
            // left: 453px;
            right: 11px;
            top: 10px;
            width: 25px;
            height: 25px;
            background-color: #8A8A8A;
            font-size: 25px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            border-radius: 50%;
            display: none;
            cursor: pointer;
        }

        .ShowBlock {
            display: block !important;
        }


        .posAbsDeatail {
            text-align: center;
            padding-top: 50px;
            position: absolute;
            width: 100%;
            bottom: 10px;
            
            .ant-btn-primary {
                background: #4954E6;
                border-color: #4954E6;
                color: #fff;
                padding: 0 30px;
            }
            
        }

        


        .head {
            width: 100%;
            min-height: 300px;
            background-size: contain;
            background-repeat: no-repeat;

            padding: 0 20px;
            padding-top: 30px;

            h1 {
                font-weight: bold;
                font-size: 20px;
                color: #4954E6;
                padding-bottom: 10px;
            }

            .tips {
                color: #BFBFBF;
                font-size: 12px;
                padding-bottom: 30px;
            }


            .title {
                h2 {
                    font-weight: bold;
                    font-size: 22px;
                    padding-bottom: 10px;
                    line-height: 30px;
                }
            }

            .setTime {
                padding-bottom: 30px;
                p {
                    margin-right: 20px;
                    padding-left: 20px;
                    font-size: 12px;
                    color: #808080;
                    position: relative;

                    span {
                        color: #2FC59A;
                        font-weight: bold;
                        padding: 0 5px;
                    }
                }

                p::after {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    background-color: #2FC59A;
                    left: 3px;
                    top: 2px;
                    border-radius: 50%;
                }
            }


            ul {
                h3 {
                    font-weight: bold;
                    color: #595959;

                    img {
                        width: 20px;
                        position: relative;
                        top: 5px;
                    }

                }

                .resources {
                    margin-top: 20px;
                    padding-bottom: 30px;
                    .li {
                        justify-content: space-between;
                        line-height: 30px;
                        margin: 10px 0;
                        height: 30px;
                        position: relative;
                        cursor: pointer;
                    }

                    .setback {
                        position: absolute;
                    }

                    .liWarp {
                        justify-content: space-between;
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                    }

                   
                    .li:hover {
                        // color: #fff;
                        position: relative;
                        .setback {
                            position: absolute;
                            bottom: 0;
                            width: 90%;
                            height: 3px;
                            background-color: #4954E6;
                            animation:mymove 5s;
                        }    

                    }

                    // animation:mymove 5s infinite;

                    @keyframes mymove
                    {
                    from {width:0px;}
                    to {width:90%;}
                    }

                      


                    img {
                        width: 30px;
                    }
                } 



            }


            .sginWarp {
                height: 340px;
                overflow-y: auto;
            }


            .siginName {
                // height: 200px;
                border: 1px dashed #F2F2F2;
                margin-top: 20px;
                border-radius: 10px;

                padding: 20px 20px;
                color: #A6A6A6;

                p {
                    line-height: 20px;
                }
                img {
                    width: 100%;
                }


                .just {
                    justify-content: space-between;
                }

                // .setP {
                //     text-align: center;
                //     line-height: 100px;
                //     font-size: 25px;
                // }
            }




        }

    }

}


.MeetingSignInModal {
    .setUPloda {
        position: absolute;
        left: -0px;
        top: -0px;
        opacity: 0;
        z-index: 100;
    }

    .time {
        display: flex;
        li {
            width: 50%;
            
        }
        li:last-child {
            margin-left: 20px;
        }
    }

    .flex {
        justify-content: space-between;
        line-height: 30px;

        // span {
        //     font-size: 12px;
        // }

        .setSize {
            font-size: 12px;
            color: #106CFF;
        }

        .ant-btn-primary {
            background: #FFD400;
            border-color: #FFD400;
            color: #fff;
        }

    }

    .fileList {
        margin-top: 10px;
        min-height: 150px;
        background-color:#F1F2F6;
        padding: 20px;

        p {
            line-height: 150px;
            color: #808080;
            text-align: center;
        }

        li {
            display: flex;
            justify-content: space-between;
            line-height: 40px;
            h4 {
                color: #808080;
            }
            h5 {
                color: #ff0000;
                cursor: pointer;
            }
        }
    }

    .ant-picker {
        width: 100%;
    }

    


}

.MeetingSignInModalCode {
    h1 {
        font-weight: bold;
        font-size: 22px;
        // text-align: center;
        padding-left: 30px;
        padding-bottom: 10px;
    }

    .setBg {
        position: absolute;
        width: 100%;
        left: 0;
        top: 55px;
        z-index: 1;
    }

    .body {
        z-index: 10;
        position: absolute;
        width: 100%;
        left: 0;
    }

    .warp {
        height: 400px;
    }



    // .body {
    //     background-size: 100% 100%;
    //     background-position-x: 0;
    //     background-position-y: 0;
    // }

    
    .qrCode {
        text-align: center;
        margin-top: 50px;
        
        img {
            width: 200px;
            height: 200px;
        }
    }


    p {
        padding-left: 30px;
    }

    h3 {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        color: #404040;
    }
    

}

