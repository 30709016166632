
.Projectdistrtion2 {

    .videoBox {
 
        width: 77%;
        position: absolute;
        height: 341px;
        display: flex;
        overflow: hidden;

        .moveBox {
            width: 100%;
            height: 100%;
            display: flex;   
            // translate: ;

            transition:transform 2s;
            // transform: translate(0%, 0);
        }

        .pove {
            border: none;
            background-color: rgba(0, 15, 23, 0.5);
            color: #fff;
            font-size: 12px;
        }

    

        
        ul {
            height: 100%;
            width: 100%;
            padding-top: 50px;
            position: relative;
            flex-shrink: 0;
            .positionText {
    
                position: absolute;
                top: 14px;
                left: 10px;
                font-weight: bold;
                font-size: 20px;
                color: #fff;
            }
            .control {
                display: flex;
                // height: 15%;
                width: 70%;
                margin-bottom: 10px;
                flex-wrap: wrap;
                position: relative;
                left: 30%;
                .video {
                    width: calc(25% - 5px);
                    margin-left: 5px;
                    height: 140px;
                    background-color: #000;
                    margin-bottom: 5px;
                }

                .nocolor {
                    background-color: rgba(0,0,0,0);   
                }

                .position {
                    position: absolute;
                    left: -42%;
                    width: 42%;
                    height: calc(100% - 5px);
                    // background-color: #000;                   
                }

                // .video:last-child {
                //     margin-left: 10px;
                // }
            }

        }
    }


    .newsBox {
        width: 22%;
        height: calc(100vh - 50px);
        position: absolute;
        right: 0;
        // top: 49px;
        .projectMsg {
            width: 100%;
            height: 17%; 
            background-color: rgba(0,15,23, 0.5);
            border-radius: 5px;
            font-size: 22px;
            font-weight: bold;
            display: flex;
            align-content: stretch;
            flex-wrap: wrap;
            
            div {
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;

                span {
                    color: #01B0ED;
                    padding: 0 5px;
                    font-size: 25px;
                }
            }
        }

        .news {
            width: 100%;
            margin-top: 2.5%;
            height: 30%;
            background-color: rgba(0,15,23, 0.5);
            border-radius: 5px;
            font-size: 17px;
            padding: 10px;
            position: relative;
            h1 {
                font-size: 16px;
            }

            .posbtn {
                position: absolute;
                bottom: 75px;
                z-index: 100;
                button {
                    color: #000;
                    font-size: 14px;
                }
            }

            .ant-carousel {
                height: calc(100% - 24px);
                .slick-slider {
                    height: 100%;
                    .slick-list {
                        height: 100%;

                        .slick-track {
                            height: 100%;
                            div {
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .CarouselBox {
                padding-top: 5px;
                cursor: pointer;
               img {
                   width: 100%;
                   height: 80%;
               } 

               p {
                //    font-weight: bold;
                   padding-top: 5px;
                   font-size: 14px;
                   color: #fff;
               }
            }

            

        }

        .projectPrcess {
            width: 100%;
            margin-top: 2.5%;
            height: 46%;
            background-color: rgba(0,15,23, 0.5);
            border-radius: 5px;
            font-size: 17px;
            padding: 10px;
            h1 {
                font-size: 16px;
            }

            ul::-webkit-scrollbar { width: 0 !important }
            

            ul {
                margin-top: 20px;
                height: calc(100% - 44px);
                overflow-y: auto;    
                
                li {
                    // margin-bottom: 40px;
                    height: 64px;
                    .projectName {
                        img {
                            width: 23px;
                            margin-right: 10px;
                        }
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        //font-weight: bold;
                    }

                    .process {
                        position: relative;
                        top: 12px;
                        
                        .gay {
                            width: 100%;
                            position: absolute;
                            height: 4px;
                            background-color: rgba(255,255,255, 0.1);
                            border-radius: 50px;
                        }

                        .green {
                            width: 30%;
                            position: absolute;
                            height: 4px;
                            background-color: #01B0ED;
                            border-radius: 50px;
                        }

                        .rund {
                            // width: 50px;
                            // height: 20px;
                            // font-size: 14px;
                            // position: absolute;
                            // top: -8px;
                            // background-color: #00BEBE;
                            // text-align: center;
                            // line-height: 20px;
                            // border-radius: 80px;
                            // // left: 87%;
                            // left: 29%;

                            width: 50px;
                            height: 14px;
                            font-size: 10px;
                            font-weight: normal;
                            zoom: 0.9;
                            position: absolute;
                            top: -6px;
                            background-color: #01B0ED;
                            text-align: center;
                            line-height: 14px;
                            border-radius: 80px;




                        }


                    }
                    
                }


            }
        }

    }
    
}