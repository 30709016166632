


.BlankingListModal {

    :global {

        .box {
            scrollbar-width: none; /* Firefox浏览器 */
            -ms-overflow-style: none; /* Internet Explorer和Edge浏览器 */
        }

        .inputLi {
           
            li {
                display: grid;
                grid-template-columns: 130px auto;
                line-height: 40px !important;
                >span {
                    width: 130px !important;
                    border: .5px solid #F4F5F9;
                    border-bottom: none;
                }

                >div {
                    padding: 0 10px;
                    border: .5px solid #F4F5F9;
                    border-bottom: none;
                    border-left: none;
                    display: flex;
                }
            }

            li:last-child {
                border-bottom: .5px solid #F4F5F9;
                >div {
                    padding: 5px 10px;
                }
            }
        }

    }


}