

.BIMCenter {
    height: calc(100vh - 50px);
    width: 100%;
    position: relative;
    .threeRef {
        width: 100%;
        height: 100%;
    }


    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 50;
    }

    .position {
        position: absolute;
        top: 20px;
        right: 10px;
        z-index: 50;
        width: 400px;


        h3 {
            background-color: #004EA1;
            color: #fff;
            padding: 10px 20px;
            border-radius: 5px 5px 0 0px;
            font-size: 12px;
        }

        .tree {
            padding: 0 0px;
            overflow-y: auto;
            height: 100%;
            padding-bottom: 10px;
            border: 1px solid #ccc;
            border-top: none;
            border-left: none;
            border-right: none;
            // background-color: #f8f8f8;
            position: relative;
        }

        .tree::after {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            bottom: 0;
            height: 100%;
            background-color: #ccc;
        }

        .tree::before {
            content: '';
            position: absolute;
            right: 0;
            width: 1px;
            bottom: 0;
            height: 100%;
            background-color: #ccc;
        } 


        .tree::-webkit-scrollbar {display:none}

        .ant-tree-treenode  {
            width: 100% !important;
            border: 1px solid #ccc;
            border-bottom: none;
            padding: 0 !important;
        }
        .ant-tree-treenode:last-child {
            border-bottom: 1px solid #ccc;
        }

        .ant-tree-node-content-wrapper  {
            width: 100% !important;
        }

        .treeD {
            width: 100% !important;
        }


        .top {
            height: 35vh;
            >ul {
                display: flex;
                background-color: #F6F6F6;
                li {
                    line-height: 25px;
                    text-align: center;
                    border-right: 1px solid #ccc;
                }
                li:nth-child(1) {
                    width: 76px;
                    border-left: 1px solid #ccc;
                }
                li:nth-child(2) {
                    width: 220px;
                }
                li:nth-child(3) {
                    width: 104px;
                }
                
            }

            .ant-tree-switcher {
                margin-left: 80px;
            }

            .ant-tree-checkbox {
                position: absolute;
                left: 30px;
            }

            .ant-tree-checkbox::before {
                 content: '';
                 width: 1px;
                 height: 150%;
                 position: absolute;
                 right: -30px;
                 background-color: #ccc !important;
                 top: -25%;   
            }

            .ant-tree-indent-unit::before {
                border-right: none !important;
            }

            .treeD {
                display: flex;
                justify-content: space-between;
            }


            .treet1 {
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                
            } 

            .treet2 {          
                position: relative;
                flex: 0 0 100px;
            }
            .treet2::after {
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #ccc;
            }



        }


        .botom {
            margin-top: 70px;
            height: 45vh;
            background-color: #fff;
            border-radius: 5px;
 
            .oneCell {
                font-weight: bold;

            }

            .ant-tree-indent-unit {
                width: 0;
            }

            .treeD {
                width: 100%;
                display: flex;
                .t1 {
                    width: 40%;
                    word-wrap: break-word;
                }
                .t2 {
                    width: 50%;
                    word-wrap: break-word;
                }

                >div {
                    position: relative;
                }
                div::after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    top: 0;
                    position: absolute;
                    background-color: #ccc;
                    right: 0;
                }

                div:last-child::after {
                    background-color: rgba(0,0,0,0);
                }
                


                // position: absolute;
                // border: 1px solid #ccc;
                // border: 1px solid #ccc;
            }
        }


    }



    .selectFun {
        position: absolute;
        bottom: 60px;
        z-index: 100;
        margin: 0 auto;
        text-align: center;
        display: flex;
        
        left: 40%;
        
        //width: 100%;

        .ul2 {
            margin-left: 10px;
            position: relative;
            .range {
                // display: none;
                position: absolute;
                width: 100%;
                top: -30px;
            }
        }
        ul {
            background-color: rgba(255,255,255, 0.7);
            box-shadow: 0px 0px 15px #cccbcb;
            border-radius: 4px;
            li {
                padding: 6px 6px;
                margin: 0 14px;
                position: relative;
                
                p {
                    pointer-events: none;
                    position: absolute;
                    background-color: rgba(255,255,255, 0.8);
                    top: -40px;
                    left: -20%;
                    white-space: nowrap;
                    width: 70px;
                    font-size: 12px;
                    line-height: 30px;
                    border-radius: 2px;
                    opacity:0;
                    zoom: 0.9;
                }

            }

            .active {
                background-color: #ccc;
            }


            li:hover {
                pointer-events:initial;
                background-color: #ccc;
                p {
                   opacity:1;
                   transition: transform 0.6s, opacity 0.6s;
                   transform:translateY(-20%);

                }
            }

            display: flex;
            justify-content: center;
            img {
                width: 30px;
            }
        }
    }



    .selectproject {
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 100;
        background-color: #004EA1;
        color: #fff;
        border-radius: 2px;

        h2 {
            color: #fff;
            text-align: center;
            padding: 15px;

        }

        .active {
            background-color: #C71D4C;
        }

        li {
            padding: 15px 30px;
            position: relative;
            cursor: pointer;
        }

        li::after {
            content: '';
            position: absolute;
            width: 80%;
            height: 1px;
            background-color: #0364D1;
            bottom: 0;
            left: 10%;
        }

        li:last-child::after {
            background-color: #004EA1;
        }

        li:hover {
            background-color: #C71D4C;
        }

    }


}