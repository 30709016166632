

.panorama {
    margin: 10px 10px;
    color: #808080;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;

    .flex {
        display: flex;
    }
}

.panorama {

.panoramaTitle {
    
    background-color: #fff;
    h1 {
        line-height: 50px;
        padding-left: 20px;
        color: #808080;
        border-bottom:1px solid #F2F2F2;        
    }

    .paddingTop {
        padding-top: 20px;
    }
}
.inputWarp {
     padding: 0px 40px;
 
    li {
        display: flex;
        width: 500px;
        padding-right: 30px;
        padding-bottom: 20px;
        .labelName {
            width: 80px;
            line-height: 32px;
            padding-right: 0px;
        }
    }

    .setBtn {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .btn {
            width: 120px;
            background-color: #63ABFF;
            border-color: #63ABFF;
        }
    }
}

.inptW2 {
    li {
        margin-bottom: 30px;
    }
}






.first2 {
    margin-top: 15px;
    
    .imgList {
        box-sizing: border-box;
        padding: 20px 20px;
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        li {
            width: 19%;
            margin-right: 1%;
            margin-bottom: 20px;
            .img {
                height: 180px;
                background-color: #595959;
                font-size: 30px;
                text-align: center;
                line-height: 180px;    
            }
            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }

            .title {
                display: flex;
                justify-content: space-between;
                line-height: 50px;
                padding: 0 20px;
                .time {
                    font-size: 13px;
                }
            }
            .edit {
                display: flex;
                justify-content: space-between;
                background-color: #F1F2F6;
                align-items: center;
                height: 50px;
                line-height: 50px;

                .shu {
                    width: 2px;
                    height: 12px;
                    background-color: #808080;
                    border-radius: 50px;
                }

                .textp {
                    width: 50%;
                    text-align: center;
                    color: #63ABFF;
                    cursor: pointer;
                }
                .pColor1 {
                    color: #FF0000;
                }
            }
        }
    }   
}

}


.panoramaTitle {
    .inputWarp2 {
        display: flex;
        justify-content: center;
         li {
             display: flex;
             width: 500px;
             padding-right: 30px;
             padding-bottom: 20px;
             .labelName {
                 width: 80px;
                 line-height: 32px;
                 padding-right: 0px;
             }
         }
     }
}