.mixingStationOverview {
  .nav-list {
    display: flex;
    margin-top: 10px;

    li {
      margin: 0 10px;
      background-color: #fff;
      padding: 8px 20px;
      border-radius: 3px;
      cursor: pointer;

      &:nth-of-type(1) {
        margin: 0;
      }

      &.active {
        background-color: #63abff;
        color: #fff;
      }
    }
  }

  .content-box {
    .content-top {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .top-left {
        flex: 1;
        background-color: #fff;
        padding: 10px 15px;

        .title-wrap {
          display: flex;
          justify-content: space-between;
          line-height: 40px;

          h3 {
            font-weight: 700;
            font-size: 16px;
          }

          .status {
            font-size: 12px;

            span {
              color: #2fd359;
            }
          }
        }

        .device-message {
          display: flex;

          .device-message-left {
            margin-right: 20px;

            h4 {
              line-height: 60px;
              font-weight: 700;
              font-size: 16px;
            }

            ul {
              width: 370px;

              li {
                display: flex;
                height: 35px;
                line-height: 35px;

                p {
                  flex: 1;
                  padding-left: 10px;

                  span {
                    display: inline-block;

                    &:nth-of-type(1) {
                      width: 60px;
                      margin-right: 10px;
                    }
                  }
                }

                &:nth-of-type(odd) {
                  background: #f4f8fe;
                }
              }
            }
          }

          .device-message-right {
            flex: 1;
            height: 100%;

            img {
              width: 200px;
              height: 270px;
            }
          }
        }
      }

      .top-right {
        flex: 2;
        background-color: #fff;
        padding: 10px 15px;
        margin-left: 15px;

        .title-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;

          h3 {
            font-weight: 700;
            font-size: 16px;
          }

          span {
            background-color: #63abff;
            padding: 8px 20px;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
          }
        }


        .flex-box {
          margin-top: 30px;
          display: flex;
          justify-content: space-around;

          .flex-item {
            flex: 0 0 200px;
            height: 200px;

            &:nth-of-type(1) {
              background-color: #e9fbfb;
            }

            &:nth-of-type(2) {
              background-color: #e9eeff;
            }

            &:nth-of-type(3) {
              background-color: #fff5e6;
            }

            &:nth-of-type(4) {
              background-color: #ffe7e7;
            }

            p {
              margin-left: 20px;

              &:nth-of-type(1) {
                margin-top: 20px;
              }

              &:nth-of-type(2) {
                margin-top: 80px;

                span {
                  &:nth-of-type(1) {
                    font-size: 35px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .content-bottom {
    margin-top: 15px;
    background-color: #fff;

    .title-wrap {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      border-bottom: 1px solid #e7e7e7;
      padding: 10px 15px;

      h3 {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .chart-wrap {
      display: flex;
      margin-top: 15px;

      .chart-left {
        flex: 0 0 50%;
        padding: 10px 15px;

        .proportionAnalysisChart {
          width: calc(100% - 100px);
          height: 280px;
        }
      }

      .chart-right {
        flex: 0 0 50%;
        padding: 10px 15px;

        .realTimeMonitoring {
          width: calc(100% - 100px);
          height: 280px;
        }
      }
    }
  }
}