



.billMonth {
    margin: 0 10px;
    margin-top: 10px;


    .setNone {
        display: none;
    }

    .head {
        background-color: #fff;
        padding: 0 20px;
        margin-bottom: 10px;


        .echart {
            width:calc(100% - 900px);
            // padding-right: 100px;
            

            .setEchart {
                width:750px;
                height: 100%;
            }            
        }

        // .tabWarp {
        //     // justify-content: space-between;
        // }

        ul {
            display: flex;
            margin-top: 20px;
        }

        li {
            line-height: 35px;
            margin-right: 20px;
            color: #808080;
            padding-bottom: 10px;
            .process {
                width: 235px;
                height: 15px;
                background-color: #6393F7;
                color: #fff;
                line-height: 15px;
                text-align: center;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .flex {
                display: flex;
                align-items: center;
                
                span {
                    display: inline-block;
                    width: 70px;
                    
                }

                .context {
                    width: 130px;

                    text-align: right;
                    padding-right: 40px;
                }

                .setW2 {
                    width: 78px;
                    text-align: right;

                }

            }

            .satisfied {
                line-height: 20px;
                border-radius: 2px;
                box-shadow: 0px 0px 8px #888888;
                font-size: 12px;
                width: 140px;
                padding: 10px;
                margin: 0 auto;

                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    position: relative;
                    padding-left: 15px;
                    span {
                        display: inline-block;
                        width: 60px;
                        text-align: right;
                    }
                }

                div::after {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    left: 0px;
                    top: 6px;
                    background-color: #578DF9;
                }

            }

        }
    }


    .setPwidth {
        // width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }



    .operationTask {
        justify-content: space-between;
    }


    .serchList {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

            i {
                padding: 0 20px;
             }
        
        //
        input {
            background-color: #F2F6FC;
            border: 1px solid #F2F6FC;
        }

        .ant-select-selector {
            background-color: #F2F6FC !important;
            border: 1px solid #F2F6FC !important;
        }

        .ant-picker {
            background: #F2F6FC;
            border: 1px solid #F2F6FC;
        }

    }

    .serchBtn {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 20px;
        margin-left: 50px;
        height: 100%;

        i {
            display: inline-block;
            line-height: 30px;
        }
        // span {
        //     padding-top: 10px;
        // }

        .ant-picker {
            width: 250px;
        }

        .date {
            margin: 0 10px;
            margin-right: 20px;
        }
        
        .setMargin {
            margin-right: 20px;
        }

        .styleBtnYellow {
            background: #FFD400;
            border-color: #FFD400;
            border-radius: 2px;
        }

        .styleBtnGreen {
            background: #2FC59A;
            border-color: #2FC59A;
            border-radius: 2px;
        }


    }






    .tableWarp {
        box-sizing: border-box;
        overflow: hidden;
        margin-top: 10px;
    }

    .setWidth {
        width: 100%;
        position: relative;
    }

    .addWidth {
        width: 800px !important;
        white-space:normal !important;
        padding: 0 15px;
        margin-left: 10px;
    }



    .detail {
        width: 0px;
        height: 837px;
        
        overflow-y: auto;
        white-space:nowrap;
        transition:width 1s;
        min-height: 700px;
        background-color: #fff;
        position: relative;
        .close {
            position: absolute;
            width: 100%;
        }

        .title {
            color: #1890FF;
            line-height: 50px;
            font-weight: bold;
            font-size: 16px;
        }

        .reactive {
            position: absolute;
            right: 12px;
            // left: 453px;
            right: 11px;
            top: 10px;
            width: 25px;
            height: 25px;
            background-color: #8A8A8A;
            font-size: 25px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            border-radius: 50%;
            display: none;
            cursor: pointer;
            z-index: 100;
        }

        .ShowBlock {
            display: block !important;
        }




    

        .setdetailedInfo {

            max-height: 470px;
            overflow-y: auto;

            h1 {
                padding-left: 8px;
                font-weight: bold;
                color: #808080;
            }
    
            li {
                display: flex;
                margin: 10px 0;
                line-height: 30px;
                span {
                    display: block;
                    width: 90px;
                    text-align: center;
                    letter-spacing:4px;
                    color: #808080;
                }
    
                .noSpacing {
                    letter-spacing:0px;
                }
    
                div {
                    // width: 60%;
                    width: 140px;
                    padding-left: 10px;
                    line-height: 30px;
                    border-radius: 5px;
                    background-color: #F1F2F6;
                    color: #595959;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
    
                .allWidth {
                    width: 370px;
                }
    
            }
        }

    table {
        margin-top: 10px;
        margin-left: 8px;
        overflow: auto;
        width: 98%;
        position: relative;
        // .firstTr {
        //     position: absolute;
        // }

        tr {
            th {
                padding: 0 10px;
                border: 1px solid #D9D9D9;
                background-color: #F1F2F6;
                color: #7F7F7F;
                line-height: 30px;
                white-space:nowrap;
            }
            td {
                text-align: center;
                padding: 0 10px;
                border: 1px solid #D9D9D9;
                border-top: none;
                white-space:nowrap;
                line-height: 30px;
            }
        }
    }

    
    }




    .setIcon {
        svg {
            font-size: 30px;
        }
    }

    .FBsfsImg {
        width: 22px;
        cursor: pointer;
    }


    .setMaxHeight {
        margin-top: 40px;
    }



    .statisticalInfo {
        color: #808080;
        display: flex;
        font-size: 12px;

        line-height: 30px;

        span {
            display: inline-block;
            width: 70px;
        }

    }

    .echartsTime {
        width: 350px;
        height: 150px;

    }



}