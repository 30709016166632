

.WorkList {
    

    .flex {
        display: flex;

        
    }

    .setFlex {
        justify-content: space-between;
    }

    .setStyle {
        position: absolute;
            background-color: #000;
            display: inline-block;
            width: 20px;
            height: 20px;
            z-index: 100;
            right: -10px;
            top: -10px;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            cursor: pointer;
    }


    .btn {
        margin:10px 0;
        margin-left: 10px;
    }
    .setBtn {
        margin-right: 10px;
    }
    
    
    .list {
        display: flex;
    
    }


    .warp1 {
        background-color: #fff;
        margin: 0 20px;
        
        .head {
            padding: 10px 15px;
            border-bottom: 1px solid #F1F2F6;
            display: flex;
            justify-content: flex-end;    
            button {
                border: none;
                color: #fff;
                background-color: #FFC000;
                padding: 10px 30px;
                border-radius: 7px;
                font-size: 15px;
                cursor: pointer;
            }
        }

        .table {
            padding: 20px;
        }
    }


    .operation {
        display: flex;
        justify-content: space-around;
        p {
            color: #6A9BF4;
            cursor:pointer;
        }
        .red {
            color: #ff0000;
        }
        .centp {
            padding: 0 10px;
        }
    }

}

.setFingerList {
    .removeImgList2 {
        position: absolute;
        background-color: #000;
        display: inline-block;
        width: 20px;
        height: 20px;
        z-index: 100;
        right: -10px;
        top: -10px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        color: #fff;
        cursor: pointer;
    }
}

.ProgressNodeModal {
    .setFlex {
        justify-content: space-between;
    }
}








