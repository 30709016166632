






.RecordModal {
    max-height: 600px;
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;

    .recordResult {
        margin: 0 auto;
        width: 400px;
        display: flex;
        justify-content: space-around;
        p {
            width: 80px;
            text-align: center;
        }

        p:nth-child(2) {
            margin-top: 10px;
        }

        .status {
            color: #19BE6B;
            font-size: 17px;
        }

        .color3 {
            color: #ff0000 !important;
        } 


        
        
    }


    .recordContext {
        li {
            display: flex;
            justify-content: space-between;
            line-height: 30px;
            padding: 0 20px;
            span {
                display: inline-block;
                width: 20px;
                height: 20px;
                position: relative;
                top: 4px;
                border-radius: 50%;
                border: 1px solid #7F7F7F;
                margin-left: 30px;
            }
        }


        .setStatus22 {
            background-color: #19BE6B;
            border: 1px solid #19BE6B;
        }

        .setStatus33 {
            background-color: #ff0000;
            border: 1px solid #ff0000;
        }

    }


    .recordQK {
        p {
            background-color: #FAFAFA;
            margin: 0 10px;
            min-height: 150px;
            padding: 10px;
        }
    }

    .recordImg {
        .setImg {
            margin: 0 10px;
        }
    }    


    h1 {
        font-size: 17px;
        font-weight: bold;
        position: relative;
        left: 10px;
        margin-top: 20px;    
    }

    h1::after {
        content: '';
        width: 5px;
        height: 20px;
        background-color: #19BE6B;
        position: absolute;
        left: -10px;
        top: 2px;
        border-radius: 50px;
    }



//c测试33333
}