





.MyTable {
    background-color: #D9D9D9;
    
    color: #808080;

    td {
        padding: 0 !important;
    }

    box-sizing: border-box;
    table {
        box-sizing: border-box;
        padding-left: 100px;
        padding-top: 10px;
        padding-bottom: 10px;

        tr {
            line-height: 30px;
        }

      

        .td1 {
            width: 100px;
            text-align: center;
            position: relative;
            p {
                background-color: #fff;
                text-align: center;
                display: inline-block;
                padding: 1px 15px;
                margin: 5px 0;
                border-radius: 5px;
                // margin-left: -15px;
            }
        }


        .td2 {
            p {
                vertical-align: middle;
            }

            img {
                width: 25px;
                position: relative;
                top: 5px;
                margin-right: 20px;
            }
        }

        .thTD4 {
            text-align: center;
            width: 20%;
        }

        .td4 {
            
            text-align: center;
            span {
                color: #fff;
                background-color: #2FC59A;
                padding: 3px 10px;
                font-size: 12px;
                border-radius: 3px;
            }


            .setType3 {
                background-color: #FEBB22;
            }

            .setType4 {
                background-color: #FF5257;
            }

        }

        .thtd5 {
            width: 25%;
            text-align: center;
            span {
                
                padding: 0 50px;
            }

            i {
                color: #63ABFF;
                cursor: pointer;
            }

            b {
                color: #ff0000;
                cursor: pointer;
            }


        }

        

        .after::after {
            content: '';
            width: 15px;
            height: 15px;
            background-color: #FEBB22;
            position: absolute;
            left: 0;
            border: 2px solid #fff;
            border-radius: 50%;
            left: -40px;
            top: 15px;
            z-index: 100;
        }


        .after::before {
            content: '';
            width: 1px;
            height: 40px;
            background-color: #fff;
            position: absolute;
            left: -33px;
            top: -14px;
        }
        .firstTd::before {
            height: 80px;
            top: -50px;
        }

    }

}
