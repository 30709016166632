


.roster {
    padding: 10px;
    // padding-left: 5px;
    padding-right: 5px;
    padding-top: 0;


    .btn {
        padding-left: 0;
    }

    .enterStatus {
        color: #FAA646 !important;
    }
.table {
    
    background-color: #fff;
}

.del {
    padding-left: 10px;
    
    color: #ff0000 !important;
}


// .setBtnList {
//     ul {
//         width: 1200px;
//     }
// }



.input {
    ul {
        display: flex;
        padding: 20px 50px 0 0;
        flex-wrap: wrap;
        border-bottom: 1px solid #F2F2F2;
        height: 70px;
        transition:all .5s;
        overflow: hidden;
        li {
            margin-left: 30px;
            display: flex;
        }
        li>span {
            padding-right: 10px;
            display: inline-block;
            line-height: 30px;
            width: 80px;
            text-align: right;
        }

    }

    .btn {
 
        background-color: #FAA646 !important;
        color: #fff !important;
        border-color: #FAA646 !important;
        margin: 0;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
    }

        


    .drapBottom {
        width: 60px;
        height: 20px;
        border: 1px solid #EFEFEF;
        text-align: center;
        line-height: 20px;
        margin: 0 auto;
        border-top: none;
        cursor: pointer;
    }
}

.addOpen {
    height: 200px !important;
    overflow-y: auto !important;
}





.translate {
    transform:rotate(180deg);
}


.table2 {
    padding: 10px;

    .allBtn {
        margin-bottom: 20px;
        text-align: right;
        .bt {
            margin-left: 20px;
        }
    }
}

.operation {
    display: flex;
    justify-content: center;
    p {
        color: #6A9BF4;
        cursor:pointer;
    }
    .centp {
        padding: 0 10px;
    }
}


.setUPloda {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    input {
        width: 80px;
        height: 30px;
        cursor: pointer;
    }
}


}


.needWarp {
    h1 {
        display: flex;

        p {
            margin-left: 30px;
            position: relative;
        }

        p::after {
            content: '';
            width: 10px;
            height: 10px;
            background: #409EFF;
            position: absolute;
            left: -15px;
            border-radius: 100px;
            top:8px;
            border: 1px solid #409EFF;
        }
        .p2::after {
            background: #fff;
            border: 1px solid #DCDFE6;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            margin: 10px;
        }
    }
}


.statusuiSa2 {
    color: #07C160;
}

.statusuiSa3 {
    color: #FF0000;
}


.ErrorTableModal {
    input {
        border: none;
        width: 100%;
    }
}

