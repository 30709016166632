.safeEducation {

  height: calc(100vh - 120px) !important;
  width: 100% !important;
  padding: 0 10px !important;
  padding-right: 5px!important;

  .nav-list {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      margin: 0 10px;
      background-color: #fff;
      padding: 8px 20px;
      border-radius: 3px;
      cursor: pointer;

      &:nth-of-type(1) {
        margin: 0;
      }

      &.active {
        background-color: #004EA2;
        color: #fff;
      }
    }
  }
}