


.Casting {

    :global {

        .box {
            scrollbar-width: none; /* Firefox浏览器 */
            -ms-overflow-style: none; /* Internet Explorer和Edge浏览器 */
        }

        .inputLi {
           
            li {
                display: grid;
                grid-template-columns: 130px auto;
                line-height: 40px !important;
                >span {
                    width: 130px !important;
                    border: .5px solid #F4F5F9;
                    border-bottom: none;
                }

                >div {
                    padding: 0 10px;
                    border: .5px solid #F4F5F9;
                    border-bottom: none;
                    border-left: none;
                    display: flex;
                }
            }

            li:last-child {
                border-bottom: .5px solid #F4F5F9;
                >div {
                    padding: 5px 10px;
                }
            }
        }

    }


}


.AddCasting {

    :global {
        .List {
            display: grid;
            grid-template-columns: 50% 50%;
            
            li {
                position: relative;
                .unit {
                    position: absolute;
                    top: 0;
                    right: 50px;
                }
            }
            
            .flex {
                position: relative;
                
                .Pos {
                    // height: 95%;
                    // margin-top: 5px;
                    position: absolute;
                    width: 80%;
                    border-top: none;
                    border-left: none;
                    border-bottom: none;
                    top: 1px;
                    left:1px;
                    bottom: 1px;
                }

                .setWidth {
                    width: 50%;
                }

             
            }

            .gridInput {
                position: relative;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                span {
                    position: absolute;
                    left: -17px;    
                    z-index: 2;
                    top: 3px;
                }

                .posLeft {
                    position: absolute;
                    width: 60%;
                }

                .posRight {
                    position: absolute;
                    width: 40%;
                    right: 0;
                    top: 0;
                    display: flex;
                   
                    
                }
                // display: grid;
                // grid-template-columns: 50% 50%;
                // grid-auto-flow: column; 
            }
        }

        .img {
            padding: 0 100px;
        }
        .setFingerList {
            .removeImgList {
                position: absolute;
                background-color: #000;
                display: inline-block;
                width: 20px;
                height: 20px;
                z-index: 100;
                right: -10px;
                top: -10px;
                border-radius: 50%;
                line-height: 20px;
                text-align: center;
                color: #fff;
                cursor: pointer;
            }
        }
    }
   
}