


.BehaviorType {
    .table {
        background-color: #fff;
    }

    .backg {
        background-color: #fff;
        position: relative;
    
        .addBtn {
            position: absolute;
            right: 30px;
            top: 25px;
        }
    }




    .colorP2 {
        color: #1AA034;
    }
    .colorP3 {
        color: #ff0000;
    }
    
    .input {
        
        ul {
            display: flex;
            padding: 0px 30px;
            padding-top: 30px;
            flex-wrap: wrap;
            opacity: 0;
            // border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }

    }


    .input {
        ul {
            li {
                margin-left: -10px !important;
                // margin-bottom: 20px;
            }
        }
        
    }


    .table2 {
        padding: 10px;
    
        .allBtn {
            margin-bottom: 20px;
            text-align: right;
            .bt {
                margin-left: 20px;
            }
        }
    }

    .setexImg {
        display: flex;

        h1 {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .setqindan {
            margin-left: 30px;
        }

        span {
            margin-right: 20px;
        }
    }

}


.ServicePointsModal {
    
    

    .input {
        ul {
            display: flex;
            padding: 0px 30px;
            // padding-top: 30px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }

    }


    .input {
        ul {
            li {
                margin-left: -10px !important;
                margin-bottom: 20px;
            }
        }
        
    }
}