

.attendance {
    padding: 10px;
    padding-right: 5px;

    .ant-table-tbody {
        svg {
            font-size: 10px !important;
        }
    }


    .ant-table-thead > tr > th {
        padding: 16px 0px !important;
    }

    h1 {
        position: relative;
        padding-left: 20px;
        img {
            position: absolute;
            left: 5px;
            top: 12px;
        }
    }

}

.attendanceHead {
    background-color: #fff;

    h1 {
        
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
        p {
            font-weight: bold;
            line-height: 30px;
        }
    }
}

.border {
    border: 1px solid #F2F2F2;
}


.projectRate {
    display: flex;
    padding-top: 15px;
    .rate {
       font-weight: bold;
       font-size: 20px;
       width: 140px;
       height: 50px;
       background-color: #F2F2F2;
       text-align: center;
       line-height: 50px;
       margin-top: 15px;
    }
}

.projectRate>div {
    width: 50%;
    // padding-left: 30px;
    padding: 10px 30px;
}

.serchInput {
    margin: 25px 0 20px 0;
    display: flex;
}

.needWarp {
   h3 {
       padding-top: 20px;
       padding-left: 10px;

       span {
           font-size: 12px;
           color: #ff0000;
           zoom: 0.9;
       }
   }

   .DatePickerHH {
     padding-top: 10px;
     padding-left: 10px;
   }
}

.setoverflow {
    .ant-select-selection-overflow {
        flex-wrap: nowrap;
        overflow: hidden;
    }
}


.attendanceDetail {
    
    .head {
        width: 800px;
        margin: 0 auto;
        padding-bottom: 10px;
        margin-top: -12px;
        // border: 1px solid #ccc;
        text-align: center;
        display: flex;
        justify-content: center;
        .msg {
           width: 300px;
           padding-left: 30px; 
        }
        p {
            text-align: left;
            line-height: 30px;
        }

        
        .flex {
            flex-wrap: wrap;
            align-content: flex-start;
            .setTime {
                width: 100%;
                font-size: 14px;
                font-weight: bold;
                line-height: 40px;
                
            }
        }

    }

    ul {
        max-height: 450px;
        overflow-y: auto;
        // display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;

        border-top: 1px solid #F0F0F0;

        // li:first-child {
        //     border-top: 1px solid #F0F0F0 !important;
        // }

        li {
            width:98%;
            overflow-y: auto;
            border: 1px solid #F0F0F0;
            // margin-bottom: 10px;
            margin-left: 0.6%;
            border-top: none;

            display: flex;

            h1 {
                border-bottom: 1px solid #F0F0F0;
                line-height: 30px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                // margin-bottom: 10px;
                background-color: #FAFAFA;
                display: flex;
                align-items: center;
                width: 60px;
                justify-content: center;
                
            }

            p {
                padding: 10px;
                display: flex;
                .text {
                    padding-left: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
            }


        }



    }

}
