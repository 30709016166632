

// .machineDeviceList {

// }


.distinguishList {

    .input {
        background-color: #fff;
        padding-bottom: 10px;
        ul {
            display: flex;
            padding: 20px 10px;
            padding-bottom:0;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;
            height: 70px;
            transition:all .5s;
             overflow: hidden;
            li {
                margin-left: 40px;
                display: flex;
                margin-bottom: 20px;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }
    
            
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
            cursor: pointer;
        }
    }


    .addOpen {
        height: 120px !important;
        overflow-y: auto !important;
    }



    .input {
        ul {
            li {
                margin-left: -10px !important;
            }
        }
    }

}


.distinguishListModal {

    .changeMsg {
        ul {
            display: flex;
            justify-content: space-between;

            li {
                display: flex;
                line-height: 30px;

                p {
                    width: 70px;
                    text-align: right;
                    padding-right: 10px;
                }
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 20px;
        }
        margin-bottom: 30px; 
    }

    .personMsg {
        h3 {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 20px;
        }
        li {
            display: flex;
            justify-content: space-between;
            line-height: 30px;
            margin-bottom: 20px;
            p:nth-child(1) {
                width: 70px;
                text-align: right;
                padding-right: 10px;
            }
            p:nth-child(2) {
                width: 300px;
                
                margin-right: 20px;
            }
        }

        // img {
        //     width: 70px;
        // }
    }
}

