


.DrawingManage {
    margin: 15px;
    position: relative;

    // .ant-table-cell {
    //     padding: 0;
    // }

    .ant-table-expanded-row > td {
        padding: 0;
    }


   


    .tableCa {
        display: flex;
        p {
            // margin-right: 50px;
            position: relative;
        }

        span {
            padding: 0 30px;
        }

        // p::after {
        //     content: '';
        //     width: 2px;
        //     height: 12px;
        //     background-color: #938093;
        //     position: absolute;
        //     left: 53px;
        //     top: 5px;
        // }
    }

    .setXUhao {
        text-align: center;

            span {
                background-color: #F1F2F6;
                padding: 8px 20px;
                border-radius: 5px;
            }
    }


    .posAbs {
        position: absolute;
        z-index: 100;
        right: 60px;
        top: 10px;

        .ant-btn-primary {
            background: #FFD400;
            border-color: #FFD400;
            color: #fff;
        }
    }

}


.DrawingManageModal {
    .setTitle {
        display: flex;
        justify-content: space-between;
        line-height:30px;
        position: relative;
        h1 {
            span {
                font-size: 12px;
                 color: #106CFF;
            }
        }
        
    }


    .upDataList {
        background: #F1F2F6;
        min-height: 150px;
        max-height: 600px;
        overflow-y: auto;
        margin-top: 10px;
        padding: 5px 20px;
       ul {
           display: flex;
           margin-bottom: 5px;
           
           li {
               line-height: 30px;
               input {
                   border: none;
                   background: #F1F2F6;
                   outline: none;
               }
           }
           
            li:nth-child(1) {
               flex: 3; 
            }
            li:nth-child(2) {
              flex: 2;    
            }

            li:nth-child(3) {
                flex: 2; 
            }

            li:nth-child(4) {
                flex: .5;   
            }


        }

    }

    .from {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .towDiv {
            display: flex;
            line-height: 30px;
            justify-content: flex-end;
            // text-align: right;
        }
    }

    .from>div {
        width: 50%;
    }



    .setUPloda {
        position: absolute;
        left: -0px;
        top: -0px;
        opacity: 0;
        z-index: 100;
    }


    .delete {
        color: #ff0000;
    }

}

