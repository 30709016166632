


#AiRebarOverview {
    height: 100%;
    position: relative;
    h1 {
        font-weight: bold;

        span {
            font-weight: normal;
            font-size: 12px;
            zoom: 0.8;
        }
    }


    .head {
        margin-bottom: 10px;
        
        .echarts {
            width: 100%;
            height: 100%;
            canvas {
                width: 100%;
                height: 100%;
            }
        }
        >ul {
            display: flex;
            justify-content: space-between;
            >li {
                height: 250px;
                background-color: #fff;
                position: relative;
                width: calc(33.33333% - 7.5px);
                padding: 15px;
                border-radius: 2px;
                overflow: hidden;
                
               h6 {
                color: #004EA2;
                position: absolute;
                right: 20px;
                top: 10px;
                font-size: 14px;
                font-weight: bold;
               }
 
            }
            >li:nth-child(1) {
                .textList {
                    display: flex;
                    padding-top: 30px;
                    .ulList {
                        line-height: 42px;

                        p {
                            font-weight: bold;
                            color: #FFAB34;
                            font-size: 25px;
                        }

                        p:last-child {
                            color: #004EA2;
                        }
                    }

                    .echarts {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 60%;
                        height: 100%;
                    } 

                }

               .rate {
                   width: 150px;
                   text-align: center;
                   position: absolute;
                   z-index: 100;
                   right: 16.5%;
                   top: 32%;
                   text-align: center;
                   line-height: 40px;
                   p {
                       color: #C4271B;
                       font-weight: bold;
                       font-size: 25px;
                   }

               }

            }
            >li:nth-child(2) {
               
                .echarts {
                    width: 100%;
                    height: 100%;
                }
            }
            >li:nth-child(3) {
                position: relative;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

            }
        }
    }


    .warp {
        display: flex;
        height: calc(100% - 250px);
        justify-content: space-between;
        .left {
            width: calc(66.6666666666666% - 5px);
            height: 100%;
            // background-color: #fff;
            .chart {
                height: 50%;
                background-color: #fff;
                position: relative;

                h1 {
                  position: absolute;
                  left: 15px;
                  top: 15px;  
                  font-weight: bold;
                  z-index: 10;
                  span {
                      font-weight: normal;
                      font-size: 12px;
                      zoom: 0.8;
                  }
                }

                .echarts {
                    position: absolute;
                    top: 0;
                    width: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .TableList {
                height: calc(50% - 10px);
                margin-top: 10px;
                background-color: #fff;
                padding: 15px;

                ul {
                    margin-top: 20px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        text-align: center;
                        p {
                            border: 1px solid #F0F0F0;
                            border-left: none;
                            border-top: none;
                        }
                        p:nth-child(1) {
                            width: 15%;
                            border-left: 1px solid #F0F0F0;
                            border-top: 1px solid #F0F0F0;
                        }

                        p:nth-child(2) {
                            width: 10%;
                        }
                        p:nth-child(3) {
                            width: 15%;
                        }
                        
                        p:nth-child(4) {
                            width: 25%;
                        }

                        p:nth-child(5) {
                            width: 11%;
                        }
                        p:nth-child(6) {
                            width: 12%;
                        }
                        p:nth-child(7) {
                            width: 12%;
                        }

                    }

                    .title {
                        p {
                            font-weight: bold;
                            padding: 15px 0;
                            background-color: #FAFAFA;
                        }
                    }

                    .scroll {
                        height: 140px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        p {
                            padding: 15px 0;
                        }
                    }

                    .move {
                        position: relative;
                        animation:mymove2 8s infinite;
                    }

                    @keyframes mymove2 {
                        0% {top:0%;}
                        50% {top:-100%;}
                        100% {top: -100%;}
                    }

                    .scroll::-webkit-scrollbar { width: 0 !important }
                }

            }

        }
        .right {
            width: calc(33.33333333% - 6px);
            height: calc(100% + 10px) ;
            background-color: #fff;
            padding: 15px;
            position: relative;
            overflow: hidden;

            position: absolute;
            right: 0;
            top: 0;

            .all {
                position: absolute;
                top: 17px;
                left: 120px;
                font-weight: bold;
                font-size: 12px;
                span {
                    color: #C71D4C;
                }
            }

            .list::-webkit-scrollbar { width: 0 !important }
            .list {
                height: 400px;
                width: 100%;
                padding-top: 20px;
                overflow-y: auto;
                

                
            }
            

            ul {
                width: 100%;
                position: relative;
                // animation:mymove2 8s infinite;
                // @keyframes mymove2 {
                //     0% {top:0%;}
                //     100% {top: -100%;}
                // }
                
                li {
                    line-height: 23px;
                    position: relative;
                    border-radius: 2px;
                    // overflow: hidden;
                    width: 100%;
                    display: flex;
                    margin: 15px 0;
                    p {
                        width: 35%;
                        text-align: right;
                        overflow: hidden;//溢出隐藏
                        white-space: nowrap;//禁止换行
                        text-overflow: ellipsis;//...
                        span {
                            opacity: 0;
                        }
                    }


                    .process {
                        margin-left: 10px;
                        width: 60%;
                        position: relative;
                        .gay {
                            width: 100%;
                            background-color: #F2F2F2;
                            height: 100%;
                        }

                        .red {
                            position: absolute;
                            height: 100%;
                            width: 20%;
                            top: 0;
                            background-color: #C5153A;
                            
                            span {
                                position: absolute;
                                right: -60px;
                                display: inline-block;
                                width: 60px;
                                text-align: left;
                                font-size: 12px;
                            }
                        }

                        // background-color: #000;
                    }
                }
               

            } 

            .echarts {
                position: absolute;
                bottom: -30px;
                
                height: calc(100% - 400px);
                width: 95%;
            }

        }
    }

}