


.Login {
    width: 100%;
    height: 100vh;
    overflow:hidden;
    position: relative;
    min-height: 700px;
    min-width: 1024px;
    // background: url("~@/assets/images/login/loginBg.png") no-repeat center;
    // background: url(../../assets//img/newReviseImg/imgAndIcon/bg2.png);
    background-size: cover;
    color: #fff;

    .LoginBack {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        img {
            width: 100%;
        }

        .wanxi-wrap {
            
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 50px;
            z-index: 10;
            color: #000;
            p {
                padding-bottom: 10px;
            }
            div {
                position: relative;
                top: -50px;
                color: #F54F35;
                font-size: 30px;
            }
        }
    }


    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .loginWarp {
        width: 350px;
        
       >img {
           width: 100%;
       }

       .projectDes {
         .china {
            font-size: 25px;
            // font-weight: bold;
         }
         .english {
            padding-top: 10px;
            font-size: 12px;
         }
       }
       
    }
    .positionRight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets//img/newReviseImg/imgAndIcon/bg2.png);
        background-size: 100% 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            position: absolute;
            top: 30px;
            left: 30px;
            >img {
                width: 200px;
            }
        } 
        
        .selectAdmin {
            display: flex;
            margin-top: 40px;
            position: relative;
            overflow: hidden;
            .cube {
                position: absolute;
                left: calc(50% - 10px);
                top: .2.5px;
                width: 20px;
                transform: skewX(20deg);
                background-color: #fff;
                border-radius: 2px 2px 0 0;
                height: 46px;
                overflow: hidden;
            }

            div {
                width: 50%;
                text-align: center;
                height: 45px;
                line-height: 45px;
                border-radius: 5px 5px 0 0;
                font-size: 17px;
                cursor: pointer;
                
            }
            .active {
                background-color: #fff;
                color: #0A4BA2;
                font-weight: bold;
                overflow: hidden;
            }
        }
        

        .from {
            background-color: #fff;
            border-radius: 0px 0px 5px 5px;
            padding: 0 20px;
            padding-bottom: 5px;

            .seOpctiy0 {
                border: none;
            }

        }


        .login-form {
            ul {
                padding-top: 30px;
                li {
                    display: flex;
                    height: 50px;
                    margin-bottom: 20px;
                    align-items: center;
                    position: relative;
                    .title {
                        width: 50px;
                        font-weight: bold;
                        font-size: 15px;
                    }
                    
                    .ant-form-item {
                        width: calc(100% - 50px);
                        margin-bottom: 0!important;
                    }
                    input {
                       height: 40px;
                       width: 100%;
                       padding-left: 10px;
                    }

                    .getPhoneMsg {
                        position: absolute;
                        width: 120px;
                        right: 6px;
                        top: 6px;
                        border-color: #0A4CA2 !important;
                        background-color: #0A4CA2;
                        color: #fff;
                        border: none;
                        padding: 3px 0;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                }

                
            }


            .selectUser {
                display: flex;
                width: calc(100% - 50px);
                justify-content: space-between;
                align-items: center;

                .active {
                    background-color: #C71D4C;
                    animation:mymove .2s 1;
                }

                @keyframes mymove
                    {
                    from {background-color:rgba(233,233,233, 0.8); zoom: 0; transform: scale(0,0);}
                    to {background-color:#C71D4C; transform: scale(1,1);}
                }
                
                
                

                


                >div {
                    display: flex;
                    align-items: center;
                    // margin-right: 80px;
                    span {
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        border-radius: 5px;
                        cursor: pointer;
                        background-color: rgba(233,233,233, 0.8);
                        // hei
                        margin-right: 30px;
                    }

                }    

            }


           
        }


        .submit {
            border-color: #0A4CA2 !important;
            background-color: #0A4CA2;
            height: 45px;
            border-radius: 5px;
            color: #fff;
        }

        .submit:hover {
            border-color: #C71D4C !important;
            background-color: #C71D4C;
        } 

        
        .wanxi-wrap {
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 50px;
            

            p {
                padding-bottom: 10px;
            }
        }


    }

}

