.ProjectPage {
    position: relative;
    margin: 10px;
    margin-right: 3px;
    padding-top: 10px;
    background-color: #fff;
    :global {

        .pos {
           
            margin-top: 20px;
            height: calc(100vh - 250px);
            overflow-y: auto;
        }

        .UseConfigTitle {
            width: 600px;
            // padding: 0 20px;
            padding-left: 30px;
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            font-weight: bold;


            .control {
                width: 150px;
                text-align: center;
            }
        }

        .useConfigUl {
            width: 630px;
            overflow-y: auto;
            
            .li {
                position: relative;
                cursor: pointer;
                .setWidth {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 50px;
                    position: relative;

                    .flex {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }

                    .control {
                        width: 150px;
                        text-align: center;
                        display: flex;
                        justify-content: center;

                        .delete {
                            color: #ff0000;
                            margin-left: 10px;
                        }

                        .edit {
                            color: #6A9BF4;
                        }
                    }    

                }
                img {
                    width: 20px;
                    margin-right: 5px;
                }

                b {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .spen {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .toSbmit {
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
            .setPadding {
                padding: 0 30px;
            }
        }

    }
}