


.BehaviorManagement {
    margin: 10px;
    position: relative;
    overflow: hidden;

    .topAllBtn {
        position: absolute;
        right: 0px;
        top: 2px;
        display: flex;
        p {
            background-color: #fff;
            padding: 8px 20px;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
        }

        .setOpctiy {
            position: absolute;
            left: 0px;
            width: 100%;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }


        .active {
            background-color: #004EA2;
            color: #fff;
        }
    }

    .table {
    
        background-color: #fff;
    }
    
    .setBack {
        
        background-color: #fff;
    }

    .btn2 {
        margin-bottom: 10px;
       
    }
    

    .input {
        
        ul {
            display: flex;
            padding: 0px 30px;
            padding-top: 30px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
            
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }

    }


    .input {
        ul {
            li {
                margin-left: -10px !important;
                margin-bottom: 20px;
            }
        }
        
    }


    .table2 {
        padding: 10px 10px;
        

        .allBtn {
            margin-bottom: 20px;
            text-align: right;
            .bt {
                margin-left: 20px;
            }
        }
    }

    .detailBehavior {
        cursor: pointer;
        color: #1890FF;
    }


    .BehaviorHead {
       


        
        .head {
            margin-bottom: 10px;

            .echarts {
                width: 100%;
                height: 100%;
                canvas {
                    width: 100%;
                    height: 100%;
                }
            }
            >ul {
                display: flex;
                justify-content: space-between;
                >li {
                    height: 250px;
                    background-color: #fff;
                    position: relative;
                    width: 26%;
                    margin-left: 10px;
                    padding: 15px;
    
                    h1 {
                        font-weight: bold;

                        span {
                            font-weight: normal;
                            font-size: 12px;
                            zoom: 0.8;
                        }
                    }
    
    

    
                    
                }
                >li:nth-child(1) {
                    
                    margin-left: 0;
                    
                    .number {
                        display: flex;
                        
                        >div {
                            flex: 0 0 50%;
                            text-align: center;
                            h2 {
                                margin-bottom: 50px;
                                margin-top: 60px;
                                font-size: 50px;
                                color: #C71D4D;
                                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; 
                            }
                            .blue {
                                color: #004EA2;
                            }
                        }
                    }
    
                }
                >li:nth-child(2) {
                   padding: 0;
                   position: relative;
                   .ant-carousel {
                       width: 100%;
                       height: 100%;
                       overflow-y: hidden;
                   } 
                }
            }
        }

        .backg {
            li {
                background-color: #C71D4D !important;
            }
        }


        .person {
            display: flex;
            height: 100%;
            li {
                flex: 0 0 100%;
                height: 100%;
                color: #fff;
                background-color: #3A7EE2;
                display: flex;
                overflow-y: hidden;

                i {
                    opacity: 0;
                }


                img {
                    width: 45%;
                    padding-right: 25px;
                }


                .briefIntroduction {
                    width: 55%;
                    .fontColor {
                        color: #fff;
                    }

                    h2 {
                        margin-top: 12px;
                        font-size: 25px;
                        font-weight: bold;
                    }
                    h3 {
                        font-size: 18px;
                        padding-top: 11px;
                        padding-bottom: 5px;
                    }
                    p {
                        font-size: 12px;
                        line-height: 24px;
                    }
                    div {
                        span {
                            font-size: 40px;
                            padding-right: 20px;
                        }
                    }
                    
                }

            }

            li:last-child {
                background-color: #FFAB34;
            }
        }



    }

}

.BehaviorManagementModal {
    .head {
        justify-content: center;

        .msgPerson {
            width: 100px;
            text-align: center;

            div {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    ul {
        li {
            display: flex;
            margin-top: 20px;
            h1 {
                width: 100px;
            }
            p {
                width: 100%;
                background: #eee;
                padding-left: 5px;
            }
            
        }

        .merk {
            margin-top: 20px;
            background: #eee;
            min-height: 50px;
            padding: 5px 8px;
        }
    }

    .allImg {
        display: flex;
        margin-top: 20px;
       
    }

    .allImg>div {
        margin-right: 10px;
    }
}