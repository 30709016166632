


.partyWarp {
    padding: 10px 15px;
    .flex {
        display: flex;
    }
    box-sizing: border-box;


    .ant-tabs-top > .ant-tabs-nav::before {
        border: none !important;
    }


    .leftData {
        cursor: pointer;
        width: 75%;
        .top {
            width: 100%;
            height: 370px;
            background-color: #fff;
            justify-content: space-between;
            
            img {
                // width: 500px;
                height: 340px;
                margin-top: 10px;
                margin-left: 50px;
            }

           ul {
                padding: 50px 40px 0 0;
               li {
                   display: flex;
                   justify-content: space-between;
                //    
                   background-color: #F7F9FB;
                   margin-bottom: 20px;
                   width: 250px;
                   padding: 0 30px;
                   p {
                    line-height: 50px;
                   }
                   span {
                       font-weight: bold;
                       font-size: 25px;
                       padding-right: 10px;
                   }
               }

               .active {
                   background-color: #E0635D !important;
                   color: #ffffff;
               }
           } 
        }

        .bottom {
            width: 100%;
            margin-top: 10px;
            height: 430px;
            background-color: #fff;
            box-sizing: border-box;

            .botLeft {
                padding: 10px;
                .ant-image {
                    width: 100% !important;
                }
                img {
                    height: 250px;
                }

                .context {
                    padding: 15px;

                    p {
                        color: #808080;
                        display: -webkit-box;
                        height: 40px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }

                h1 {
                    font-size: 18px;
                    font-weight: bold;
                    padding-left: 30px;
                    position: relative;
                }

                h1::after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background-color: #ff0000;
                    position: absolute;
                    left: 2px;
                    top: 6px;
                    border-radius: 50%;
                }
            }

            .botRight {
                padding: 10px;
                padding-right: 20px;
                box-sizing: border-box;
                .icon {
                    // background-color: #ff0000;
                    padding-right: 10px;
                    color: #ff0000;
                }
                h1 {
                    justify-content: space-between;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    border-bottom: 1px solid #F1F2F6;
                    span {
                        font-size: 15px;
                        font-weight: bold;
                    }
                    p {
                        cursor:pointer;
                    }
                }

                ul {
                    height: 360px;
                    overflow: auto;
                    li {
                        display: flex;
                        justify-content: space-between;
                        line-height: 30px;
                        cursor:pointer;
                        p{
                            width: 80%;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .bottom>div {
            width: 50%;
            height: 100%;
        }

        .setBackColor {
            background-color: #E39C99;
        }

       

    }

    .rightData {
        margin-left: 10px;
        width: 25%;
        height: 810px;
        padding: 10px;
        background-color: #fff;

        .icon {
            // background-color: #ff0000;
            padding-right: 10px;
            color: #ff0000;
        }
        h1 {
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px solid #F1F2F6;
            span {
                font-size: 15px;
                font-weight: bold;
            }
            p {
                cursor:pointer;
            }
        }

        .rul {
            height: 550px;
            overflow-y: auto;
            li {
                display: flex;
                padding: 20px 0;
                position: relative;
                border-bottom: 1px solid #F1F2F6;
                h2 {
                    font-size: 16px;
                    font-weight: bolder;
                }
                h3 {
                    padding: 20px 0;
                    color: #A9A9A9;
                }
                h4 {
                    color: #A9A9A9;
                }
            }
        }

        .rawarp {
            display: flex;
            align-items: center;
            
        }

        .rightData2 {
            background-color: #F0A55B;
            color: #fff;
            padding: 0 20px;
            height: 25px;
            line-height: 25px;
        }

        .ant-tabs-tab-active {
            .ant-tabs-tab-btn{
                color: #ff0000 !important;
            } 
        }
        .ant-tabs-ink-bar {
            background: #ff0000 !important;
        }

        .ant-tabs-tab-btn:hover  {
            color: #ff0000 !important;
        }
        
    }

    .serPagin {
        display: flex;
        justify-content: center;
        padding-top: 30px;
    }

}


.setPartyModal {



    .ant-modal-body {
        padding: 0px !important;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 10px;
    }

    .dscript {
        padding-bottom: 10px;
    }

    .setTable {
        div {
            padding: 2px 10px;
            background-color: #F0F0F0;
            color: #888888;
            margin-right: 10px;
            border-radius: 3px;
        }

        .setColor6 {
            color: #808080;
            background-color: #EDEDED;
        }

        .setColor2 {
            background-color: #FEC324 !important;
        }

        .status {
            color: #fff;
            background-color: #CD2929;
        } 
    }

    .signUpQuantity {
        padding: 10px 0;
    }

    ul {
        li {
            h1 {
                font-weight: bold;
                font-size: 15px;
            }
            p {
                color: #8E8E8E;
            }

            span {
                padding-right: 20px;
                padding-bottom: 10px;
            }
            padding-bottom: 25px;
        }
    }




    


    .body {
        padding: 10px 20px;
    }

    .setHEIGHT {
        min-height: 300px;
        max-height: 700px;
        overflow-y: auto;
    }
}

