


.EarthEchart {
    position: absolute;
    // left: 350px;
    // top: 100px;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    input {
        z-index: 100;
        color: #000;
    }

    .echart {
        width: 100%;
        height: 100%;
        background-color: #000 !important;
        // width: 1400px;
        // height: 900px;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0px;

        canvas {
            width: 100%;
            height: 100%;
        }

    }




    

    img {
        position: absolute;
        top: -105px;
        
    }

    .img1 {
        width: 1370px;
        height: 768px;
        z-index: 1;
        left: 243px;
        top: -39px;
    }

    .img2 {
        width: 801px;
        z-index: 0;
        top: 87px;
        height: 536px;
        left: 521px;
    }


    

}


.ThreeLabel {
    background-image: linear-gradient(to right, #00B1EB , #0035D3);
    border-radius: 10px 10px 10px 0;
    padding: 5px 3px;
    width: 170px;
    text-align: center;
    .number {
        margin-top: 5px;
        text-align: center;
        border-radius: 10px 10px 10px 0;
        background-image: linear-gradient(to right, #00F9F7 , #0078F7);
        line-height: 22px;
        i{
            font-size: 12px;
            zoom: 0.8;
        }
    }

}

.threeProive {
    color: #ccc;
}

.ThreeLabelProjectDistribution {
    background-color: rgba(0,0,0,0.5);
    padding: 6px;
    
    p {
        line-height: 20px;
        font-size: 14px;
    }

    img {
        width: 20px;
    }
    
}

