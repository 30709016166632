

.PartyActive_self {
    .input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bt {
            margin-right: 10px;
        }
    }

    
        
    .drapBottom {
        width: 60px;
        height: 20px;
        border: 1px solid #EFEFEF;
        text-align: center;
        line-height: 20px;
        margin: 0 auto;
        border-top: none;
        cursor: pointer;
    }

}