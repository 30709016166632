.hhlayout {
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{

    font-size: 0.12rem;
    }

    .sitePageHeader {
        padding-top: 0.5rem !important;
    }

    .ant-page-header-heading-title {
        font-size: 40px;
    }

    .ant-page-header-content {
        font-size: 0.12rem !important;
        padding-top: 0.12rem;
    }


    .ant-page-header-heading-left {
        display: block !important;
        overflow: visible !important;
    }
}

// .scale-boxhh {
//     transform-origin: 0 0;
//     position: absolute;
//     left: 50%;
//     top: 50%;
// }

