


.Authority {
    padding: 0 10px;
    position: relative;
    .btn {
        // width: 100%;
        display: flex;
        padding-left: 20px;
        justify-content: flex-end;
        position: absolute;
        z-index: 2;
         right: 10px;
         top: -52px;

    }

    .operation {
        color: #6A9BF4;
        p {
            cursor: pointer;
        }
    }

    .setColor2 {
        color: #008000;
    }
    .setColor3 {
        color: #ff0000;
    }

}