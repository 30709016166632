

.enterpriseList {
    overflow: hidden;
    margin: 10px;
    margin-right: 5px;
    // width: 100%;
    height: calc(100vh - 165px) ;
    background-color: #fff;

    .flex {
        display: flex;
    }

    .echart {
        width: 100%;
        height: 100%;
    }


    .titleName {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        background-color: #004EA1;
        color: #fff;
        line-height: 40px;

        .p1 {
            width: 100px;
            text-align: center;
        }

        .p2 {
            width: 300px;
            text-align: center;
            cursor: pointer;
        }

        .p2:hover {
            color: #C71D4C;
        }
    }


    .allBody {
        height: calc(100vh - 200px);
        overflow-y: auto;
    }



    .ant-tree-treenode {
        width: 100% !important;
        position: relative;
        padding: 0px 13px !important;
        height: 50px;
        display: flex;
        align-items: center !important;

        .ant-tree-node-content-wrapper {
            width: 100% !important;
            overflow-x: hidden;
            position: relative;
            height: 100%;

        }

        .ant-tree-switcher {
            display: flex;
            align-items: center;
        }
    }

    .ant-tree-treenode-switcher-close::after {
        content: '';
        position: absolute;
        bottom:0;
        width: 100%;
        height: 1px;
        background-color: #F0F2F5;
    }

    .toEdit2 {
        background-color: #F4F6F8;
    }

    .toEdit3 {
        background-color: #E4E9EC;
    }
    
    
    .toEditI {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        height: 100%;
        // background-color: #000;
        // text-align: right;
        i {
            cursor: pointer;
            padding-left: 20px;
        }
        .adbumen {
            color: #31D77B;
        }
    
        .editbumen {
            color: #004EA1;
        }
    
        .delbumen {
            color: #C71D4C;
        }
    }

    


    

    .setFlex {
        justify-content: space-between;
    }

    .setStyle {
        position: absolute;
            background-color: #000;
            display: inline-block;
            width: 20px;
            height: 20px;
            z-index: 100;
            right: -10px;
            top: -10px;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            cursor: pointer;
    }


    .btn {
        margin:10px 0;
        margin-left: 10px;
    }
    .setBtn {
        margin-right: 10px;
    }
    
    
    .list {
        display: flex;
    
    }


    .warp1 {
        background-color: #fff;
        margin: 0 20px;
        
        .head {
            padding: 10px 15px;
            border-bottom: 1px solid #F1F2F6;
            display: flex;
            justify-content: flex-end;    
            button {
                border: none;
                color: #fff;
                background-color: #FFC000;
                padding: 10px 30px;
                border-radius: 7px;
                font-size: 15px;
                cursor: pointer;
            }
        }

        .table {
            padding: 20px;
        }
    }


    .operation {
        display: flex;
        justify-content: space-around;
        p {
            color: #6A9BF4;
            cursor:pointer;
        }
        .red {
            color: #ff0000;
        }
        .centp {
            padding: 0 10px;
        }
    }

}

.setFingerList {
    .removeImgList2 {
        position: absolute;
        background-color: #000;
        display: inline-block;
        width: 20px;
        height: 20px;
        z-index: 100;
        right: -10px;
        top: -10px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        color: #fff;
        cursor: pointer;
    }
}

.ProgressNodeModal {
    .setFlex {
        justify-content: space-between;
    }
}








