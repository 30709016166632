
.overviewImg {
    padding: 10px;
    padding-top: 0;
    position: relative;

    .topAllBtn {
        position: absolute;
        right: 10px;
        top: -40px;
        display: flex;

        .setOpctiy {
            position: absolute;
            left: 0px;
            width: 100%;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }

        p {
            background-color: #fff;
            padding: 8px 20px;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
        }
        .active {
            background-color: #004EA2;
            color: #fff;
        }
    }

    .head {
        >ul {
            display: flex;
            justify-content: space-between;
            >li {
                height: 250px;
                background-color: #fff;
                position: relative;
                width: 25%;
                margin-left: 10px;
                padding: 15px;

                h1 {
                    font-weight: bold;
                }


                .process {
                    li {
                        margin-top: 45px;
                        display: flex;
                        align-items: center;
                        
                        span {
                            display: inline-block;
                            width: 70px;
                        }

                        i {
                            color: #24AD76;
                            position: relative;
                            left: 5px;
                        }

                        .p1 {
                            width: 50%;
                            margin-right: 20px;
                            display: flex;
                            align-items: center;
                           
                            p {
                                display: flex;
                                width: 100%;
                                height: 15px;
                                background-color: #24AD76;
                                transition: width 1s;
                            }



                        }
                    }
                }

                
            }
            >li:nth-child(1) {
                width: 28%;
                margin-left: 0;

                .number {
                    display: flex;
                    
                    >div {
                        flex: 0 0 50%;
                        text-align: center;
                        h2 {
                            margin-bottom: 50px;
                            margin-top: 50px;
                            font-size: 50px;
                            color: #C71D4D;
                            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                        }
                        .blue {
                            color: #004EA2;
                        }
                        

                    }
                }

            }
            >li:nth-child(2) {
                width: 22%;

                .echarts {
                    width: 100%;
                    height: 160px;
                }
            }
        }
    }

    .body1 {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        padding-bottom: 0;
        padding-left: 0;
        >div {
            padding: 15px;
            flex: 0 0 50%;
            margin-left: 10px;
            background-color: #fff;
            height: 250px;
            position: relative;
            h1 {
                font-weight: bold;
            }
        }
        >div:first-child {
            margin-left: 0px;
        }


        .echarts4 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .echart3 {
            width: 100%;
            height: 250px;
        }

        .noPadding {
            padding: 0;
            .person {
                display: flex;
                height: 100%;
                li {
                    flex: 0 0 50%;
                    height: 100%;
                    color: #fff;
                    background-color: #3A7EE2;
                    display: flex;
                    overflow-y: hidden;
                    img {
                        flex: 0 0 45%;
                        padding-right: 25px;
                    }


                    .briefIntroduction {
                        flex: 0 0 55%;
                        .fontColor {
                            color: #fff;
                        }

                        h2 {
                            margin-top: 12px;
                            font-size: 25px;
                            font-weight: bold;
                        }
                        h3 {
                            font-size: 18px;
                            padding-top: 25px;
                            padding-bottom: 5px;
                        }
                        p {
                            font-size: 12px;
                            line-height: 24px;
                        }
                        div {
                            span {
                                font-size: 40px;
                                padding-right: 20px;
                            }
                        }
                        
                    }

                }

                li:last-child {
                    background-color: #C71D4D;
                }
            }

           
        }
        
    }


    

    .setUlEchart {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        li {
            display: flex;
            justify-content: space-between;
            width: 50%;
            font-size: 12px;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 15px;
            position: relative;

            p:last-child {
                font-weight: bold;
            }
        }

        li::before {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            background: #000;
            border-radius: 50%;
            left: 0px;
            top: 2px;
        }

        li:first-child::before {
            background: #FF3737;
        }

        li:nth-child(2)::before {
            background: #FFAB34;
        }

        li:nth-child(3)::before {
            background: #47DCDE;
        }
        li:nth-child(4)::before {
            background: #4772FF;
        }


    }

}

