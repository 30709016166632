








.ProgressNodeModal {
    .ant-picker {
        width: 100%;
    }
}