.video-detail {
  position: relative;
  display: flex;


  .position {
    position: absolute;
    z-index: 110;
    top: 10px;
    right: 20px;
    color: #fff;
    cursor: pointer;

    background-color: rgba(24, 144, 255, .8);
    padding: 10px 20px;
    border-radius: 2px;  

    svg {
      font-size: 30px;
    }
  }


  #demo {
        width: 100%; height: 900px; margin: 100px auto;
        overflow: hidden;
        display: flex; align-items: flex-end;
        z-index: 2;
        position: absolute;
        bottom: -34px;
        padding: 0 20px;
        padding-bottom: 50px;

        .setDateHH {
          position: absolute;
          color: #fff;
          bottom: 80px;
          font-size: 30px;
        }

        ul {

          -webkit-touch-callout: none;
          -webkit-user-select: none;
          user-select: none;

          display: flex;
          position: absolute;
          width: calc(100% - 40px);
          margin: 0 auto;
          bottom: 10px;
          li {
            // padding-right: 3.49%;
            width: calc(100% / 24);
            color: #fff;
          }

        }
        
       
        input {
          width: 100%;
        }

        .progress {
            
            width: 100%; height: 6px; border-radius: 3px;
            background: #F1F5FD;

            .progress-bar {
                width: 5%; height: 100%; border-radius: 3px;
                background: #0072FF;
                .progress-thumb {
                    width: 14px; height: 14px; border-radius: 50%;
                    background: #FFFFFF; box-shadow: 0 0 15px 5px #0072FF;
                    float: right;
                    position: relative; left: 7px; top: -5px;
                    cursor: pointer;
                }
            }
        }

    }
 
 
  .video-box {
    flex: 1;

    iframe {
      // width: calc(100vw - 200px);
      height: 100vh;
    }
  }

  .video-control {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // flex: 0 0 200px;
    background-color: #fff;

    .outter-circle {
      position: relative;
      width: 150px;
      height: 150px;
      border-radius: 100%;
      overflow: hidden;
      transform-origin: center;
      // transform: rotate(45deg);
      background: #ececec;

      .direction-wrap {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        overflow: hidden;
        transform-origin: center;
        transform: rotate(45deg);
        background: #ececec;

        .inner-parts {
          float: left;
          width: 75px;
          height: 75px;
          line-height: 75px;
          text-align: center;
          background: #ececec;
          cursor: pointer;

          &:active {
            background: #585858;
          }

          &.active {
            background: #585858;
          }

          .rotate {
            display: inline-block;
            transform: rotate(-45deg);
            font-size: 18px;
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .inner-circle {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 52px;
        margin-left: 52px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 100%;
        background-color: #ccc;
        border: 1px solid #dcdcdc;

        .rotate {
          display: inline-block;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
        }

        .ok {
          width: 45px;
          height: 45px;
          line-height: 45px;
          text-align: center;
        }
      }
    }

    .speed-wrap {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      span {
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        // background-color: #181818;
        color: #000;
        padding: 5px 10px;

        &.active {
          color: #409eff;
        }
      }
    }

    .scale-box {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .scale-icon {
        cursor: pointer;
        color: #fff;
        margin: 0 20px;
      }

      svg {
        font-size: 30px;
      }
    

    }
  }




  .CalendarHH {
    .ant-radio-group {
      display: none !important;
    }
  }


  .CalendarStyle {
    .setBtn {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        width: 200px;
      }
    }
  }

}

