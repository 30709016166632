







.ProjectDistribution {
    
    .DistributionBody {
        width: 100%;
        
        justify-content: space-between;





        .rightDataDistribution {
            margin-top: 80px;

            .leftProject {
                height: 500px;
                width: 210px;
                margin-right: 15px;


                .createIng {
                    width: 100%;
                    height: 220px;
                    background-color: rgba(0,0,0,0.2);
                    border-radius: 10px;
                    box-sizing: border-box;
                    padding: 20px 10px;

                    .headDis {
                        display: flex;
                        img {
                            width: 55px;
                        }

                        .title {
                            padding-left: 20px;
                            h1 {
                                text-align: center;
                               font-size: 18px;
                               letter-spacing: 10px;
                            }

                            div {
                                font-size: 12px;
                                margin-top: 10px;
                                
                                span {
                                    font-size: 30px;
                                    color: #0EF0F0;
                                    padding-right: 50px;
                                }
                            }
                        }
                    }

                    .hr {
                        margin: 15px auto;
                        
                        width: 90%;
                        height: 2px;
                        background-color: #354A5F;
                    }


                    ul {
                        padding-right: 5px;

                        li:nth-child(2) {
                            margin: 22px 0;
                        }

                        li {
                            display: flex;
                            justify-content: space-between;
                            
                            span {
                                 position: relative;
                                 padding-right: 15px;   
                            }

                            span::after {
                                content: '';
                                position: absolute;
                                width: 2px;
                                height: 18px;
                                right: 0;
                                background-color: #5D676C;
                                
                            }

                            p {
                                width: 95px;
                                color: #7B7879; 
                                i {
                                    color: #01E5A5;
                                    display: inline-block;
                                    width: 70px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }


                }

                .key {
                    margin-top: 15px;
                    height: 265px;
                    width: 100%;
                    background-color: rgba(0,0,0,0.2);
                    border-radius: 10px;

                    .headKey {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        padding-top: 20px;
                        // justify-content: center;
                        padding-left: -30px;
                        img {
                            width: 40px;
                        }
                    }

                    ul {
                        margin-top: 20px;
                        padding-left: 20px;

                        li {
                            font-size: 12px;
                            
                        }
                    }

                }

            }


            .rightProject {
                height: 500px;
                width: 300px;
                border-radius: 10px;
                background-color: rgba(0,0,0,0.2);

                .headKey {
                    padding-top: 20px;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    img {
                        width: 40px;
                    }
                }

                li {
                    
                    display: flex;
                    margin: 0 20px;
                    padding: 0 5px;
                    margin-top: 10px;
                    align-items: center;
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 50px;
                    font-size: 13px;
                    img {
                        width: 30px;
                    }

                    p {
                        width: 160px;
                        white-space:nowrap;
                    }

                    span {
                        display: inline-block;
                        margin-right: 10px;
                        width: 25px;
                        height: 25px;
                        border: 2px solid #00F6FF;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 22px;
                    }

                }

            }

        }


    }



}