.WageEntry {
    background-color: #fff;
    margin: 0 10px;


    .setUPloda {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }


    .input {
        ul {
            display: flex;
            align-content: flex-start;
            padding: 20px 30px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;
            height: 70px;
            transition:all .5s;
            overflow: hidden;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
            

            .setHeight {
                width: 100%;
                height: 30px;
            }    

        }
    
        .btn {
            background-color: #FAA646;
            color: #fff;
            border-color: #FAA646;
            margin: 0;
            margin-left: 20px;
        }
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
        }
    }

    .addOpen {
        height: 150px !important;
    }
}


