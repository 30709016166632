

.VideoComponentHH {
    position: relative;
    height: 100%;
    span {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 100%;
        height: 100%;
        svg {
            font-size: 30px;
        }
    }
}

