.BlankingListModal {

    .cololr1 {
        color: #2979FF !important;
      }
      .cololr3, .cololr5, .cololr8, .cololr10 {
        color: #ff0000 !important;
      }
      .cololr21, .cololr22 {
        color: #2979FF !important;
      }
      
      .cololr26, .cololr24 {
        color: #ff0000 !important;
      }
    
    .box {
        max-height: 600px;
        overflow-y: auto;

    }

    .inputLi {
        padding-top: 20px;
        li {
            line-height: 30px;
            span {
                display: inline-block;
                font-weight:bold;
                width: 120px;
                text-align: right;
                margin-right: 10px;
            }
        }
    }

    h1 {
        padding-top: 20px;
        font-size: 20px;
        font-weight: bold;
    }

    .nameWidth {
        display: inline-block;
       width: 80px;
    }
    .user {
        margin-left: 10px;
        margin-top: 20px;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .name {
                width: 20%;
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    margin-right: 10px;
                }
                
            }

            .des {
                width: 60%;
            }

            .sign {
                color: #14C171;
            }

        }
        
    }

    .tableProportion {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        border-left: 1px solid #F4F5F9;
        border-top: 1px solid #F4F5F9;
        li {
            border-bottom: 1px solid #F4F5F9;
            border-right: 1px solid #F4F5F9;
            width: 33.33333333%;
            display: flex;
            height: 70px;
            line-height: 70px;
            div {
                width: 50%;
                text-align: center;
            }

            .side1 {
                border-right: 1px solid #F4F5F9;
            }
        }
    }

    .tableCount {
        margin-top: 20px;
        border-left: 1px solid #F4F5F9;
        border-top: 1px solid #F4F5F9;

        li {
            display: flex;
            .td {
                width: 16.6666666666666666666%;
                text-align: center;
                border-bottom: 1px solid #F4F5F9;
                border-right: 1px solid #F4F5F9;
                min-height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
        }
    }

    .tableRate {
        margin-top: 20px;
        border-left: 1px solid #F4F5F9;
        border-top: 1px solid #F4F5F9;
        li {
            display: flex;
            .td {
                width: 33.3333333333%;
                text-align: center;
                border-bottom: 1px solid #F4F5F9;
                border-right: 1px solid #F4F5F9;
                min-height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
        }

    }

}