


.Home {
    height: 100vh;

    a {
        color: blue;
        margin-right: 30px;
        
    }

    .iframe {
        width: 100%;
        height: 100%;
        
    }

    .toPath {
        display: flex;
        justify-content: space-around;
        // align-items: center;
        padding-top: 50px;
        
        height: inherit;
        a {
            width: 300px;
            height: 145px;
            display: inline-block;
            img {
                width: 100%;
                border-radius: 5px;
            }

            h1 {
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 10px;
            }
        }
    }

}