







.LeaveRequestForm {

    padding: 10px;
    height: 100%;

    :global {


        .tableCa {
            display: grid;
            grid-template-columns: 50px 50px 50px 50px;
            justify-content: center;    
        }
        
        

        .input {
            display: flex;
            
            justify-content: space-between;

            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-bottom: 1px solid #F2F2F2;
                transition: all 0.5s;
                margin-bottom: 10px;
                padding-left: 30px;
                
                .btn {
                    background-color: #FAA646 !important;
                    color: #fff !important;
                    border-color: #FAA646 !important;
                    margin: 0;
                    
                    border: none;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                }
            }

        }



       


    }
}


.LeaveRequestFormModal {


    
    :global {

   

        .btn {
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        ul {
                display: grid;
                grid-template-columns: 50% 50%;


                li {
                    .textImg {
                        
                        img {
                            height: 30px;
                            display: none;
                        }
                    }
                }
        }


        .ant-picker {
            width: 100%;
        }

        .SignCanvas {
            position: relative;
            width: 430px;
        }

      

    }
}



.Casting {

    :global {

        h1 {
            padding-top: 0 !important;
        }
        
        .SignCanvas {
            position: relative;
            width: 430px;
            padding-bottom: 20px;
        }

 

        .setBtn {
            display: flex;
            justify-content: space-between;
            width: 200px;
            margin: 0 auto;    
            padding-bottom: 20px;
        
            button {
                border: none;
                background-color: #fff;
                border: 1px solid #EBEDF0;
                padding: 5px 25px;
                border-radius: 5px;
                transition: box-shadow .5s;
            }
            
            
            button:active {
                // background-color: #EBEDF0;
                box-shadow: 0px 0px 10px black;
                transition: box-shadow .1s;
            }

            .active {
                border: 1px solid #004EA1;
                background-color: #004EA1;
                color: #fff;
            }
            .active:active {
                box-shadow: 0px 0px 10px #004EA1;
            } 

            .activeNo {
                border: 1px solid #FAA646;
                background-color: #FAA646;
                color: #fff;
            }

            .activeNo:active {
                box-shadow: 0px 0px 10px #FAA646;
            }
        
        }


        .box {
            scrollbar-width: none; /* Firefox浏览器 */
            -ms-overflow-style: none; /* Internet Explorer和Edge浏览器 */
        }

        .inputLi {
           
            li {
                display: grid;
                grid-template-columns: 130px auto;
                line-height: 40px !important;
                >span {
                    width: 130px !important;
                    border: .5px solid #F4F5F9;
                    border-bottom: none;
                }

                >div {
                    padding: 0 10px;
                    border: .5px solid #F4F5F9;
                    border-bottom: none;
                    border-left: none;
                    display: flex;
                }
            }

            li:last-child {
                border-bottom: .5px solid #F4F5F9;
                >div {
                    padding: 5px 10px;
                }
            }
        }

    }


}


