

.BannerConfig {

    .flex {
        display: flex;
        flex-wrap: wrap;
        .warp1 {
            width: 100%;

            padding-bottom: 50px;
            
            
        }

       
    }

    .ma1 {
        margin-right: 7.5px !important;
    }
    .ma2 {
        margin-left: 7.5px !important;
    }

    .warp1 {
        background-color: #fff;
        margin: 0 15px;
        margin-bottom: 15px;
        padding-bottom: 20px;

        h1 {
            font-weight: bold;
            color: #808080;
            line-height: 50px;
            padding: 0px 10px;
            border-bottom: 1px solid #F0F2F5;
        }

        .allData {
            padding: 0 10px;
            h2 {
                color: #808080;
                line-height: 50px;
            }
        }

        .setData {
            padding-bottom: 20px;
        }

        .setINputWidth {
            width: 500px;
        }
    }


    // ul {
    //     span {
    //         // margin-bottom: 10px;
    //     }
    // }


    .setFingerList {
        .removeImgList2 {
            position: absolute;
            background-color: #000;
            display: inline-block;
            width: 20px;
            height: 20px;
            z-index: 100;
            right: -10px;
            top: -10px;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
    }


}