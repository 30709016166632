

.wages {
    .flex {
        display: flex;
    }
    .btn {
        margin:10px 0;
    }
    .setBtn {
        margin-right: 10px;
    }
    
    
    .list {
        display: flex;
    }


    .contentBox {
        padding: 0 10px;
    }

    .box {
        box-sizing: border-box;
    }

    .commonStyle {
        width: 100%;
        height: 350px;
        background-color: #fff;
    }

    .setH1 {
        border-bottom: 1px solid #F0F2F5;
        line-height: 30px;
        padding-bottom: 10px;
    }

    h2 {
        padding-top: 10px;
    }
    
    
    .leftData {
        width: 35%;
        margin-right: 10px;
        .topData {
            padding: 10px;
            margin-bottom: 10px;
            h1 {
                justify-content: space-between;
                p {
                    font-weight: bold;
                }
            }
        }

        .echarts {
            width: 90%;
            height: 230px;
        }

        .footData {
            justify-content: space-between;
            padding: 0 30%;
            text-align: center;
            div {
                p:first-child {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .bottomData {
            padding: 10px;
        }
    
    }
    
    .rightData {
        width: 65%;
        h1 {
            justify-content: space-between;
            p {
                font-weight: bold;
            }
        }

        .rightTop {
            padding: 10px;
            .side  {
                width: 100%;
                height: 90%;
                
            }
            
            .echarts2 {
                width: 500px;
                height: 70%;
                
                // left: -180px;
            }

            .setUl {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 20px;
                    img {
                        width: 80px;
                        
                        padding-right: 10px;
                    }
                    li {
                        width: 50%;
                        display: flex;
                        margin: 30px 0;
                        p:nth-child(2) {
                            font-weight: bold;
                            font-size: 16px;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }

        .rightBotm {
            padding: 10px;
            margin-top: 10px;

            .echarts4 {
                width: 100%;
                height: 310px;
            }
        }
    }

}






