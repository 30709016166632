/******** 滚动条样式 ********/
* {
  font-size: 14px;
  outline: none;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(72, 87, 106, 0.4);
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track-piece {
  border-radius: 4px;
  background: transparent;
}
.fade-enter {
  opacity: 0;
  transform: translateX(30px);
}
.fade-enter-active,
.fade-exit-active {
  opacity: 1;
  transition: all 500ms ease-out;
  transform: translateX(0);
}
.fade-exit {
  opacity: 0;
  transform: translateX(-30px);
}
.diy-container {
  padding: 0 20px;
  width: calc(100vw - 210px);
  height: calc(100vh - 145px);
  display: flex;
  flex-direction: column;
}
.diy-container .search-wrap {
  padding: 20px;
  background-color: #fff;
}
.diy-container .table-wrap {
  margin-top: 20px;
}
.ant-table td {
  padding: 14px 16px !important;
}
.ant-menu-item-icon {
  font-size: 12px;
  width: 20px;
  color: #000;
}
.ant-menu-item-icon ::v-deep svg {
  stroke: red;
  fill: red;
}
.ant-btn-primary {
  color: #fff;
  border-color: #004EA1 !important;
  background: #004EA1 !important;
}
.ant-btn {
  border: none !important;
}
.ant-modal-header {
  background: #004EA1 !important;
  color: #fff;
  padding: 8px 24px !important;
}
.ant-modal-close-x {
  height: 40px !important;
  line-height: 40px !important;
}
.ant-modal-close-x svg {
  font-size: 19px !important;
}
.ant-modal-title {
  color: #fff !important;
}
.ant-modal-close {
  color: #fff !important;
}
.ant-popover-buttons {
  display: flex !important;
}
