


.EnterpriseLevelUsers {
    :global {


        .btn {
            height: 30px;
        }

        .input {
            display: flex;
        }

        .ul {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-right: 10px !important;
        }

        .left {
            display: flex;
        }

    }
}

.gridModal {
    :global {
        
      

        .ant-checkbox-group {
            width: 100%;
            
        }

        .grid_flex {
            display: grid;
            grid-template-columns: 50% 50%;
        }

        .grid_half {
            width: 100%;
            grid-template-columns: 33.333% 33.333% 33.333%;
        }


        // .grid_half {
            
        // }

    }
}