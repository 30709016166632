


.EvaluationSummary {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;


    .topAllBtn {
        position: absolute;
        right: 0px;
        top: -40px;
        display: flex;

        .setOpctiy {
            position: absolute;
            left: 0px;
            width: 100%;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }

        p {
            background-color: #fff;
            padding: 8px 20px;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
        }
        .active {
            background-color: #004EA2;
            color: #fff;
        }
    }

    .padding {
        padding: 10px;
    }

    .title {
        font-weight: bold;

        span {
            font-size: 12px;
            font-weight: normal;
        }
    }

    .top {
        height: 35%;
        background-color: #fff;
    }
    .bottom {
        margin-top: 10px;
        height: calc(65% - 10px);
        background-color: #fff;
    }


    .left {
        flex: 0 0 calc(25% - 10px);
        height: 100%;
        // background-color: #fff;
        border-radius: 2px;

        .top {
            
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    flex: 0 0 50%;
                    padding: 0 20px;
                    padding-top: 30px;
                    p {
                        padding-top: 30px;
                        font-weight: bold;
                        font-size: 30px;
                    }
                }
            }
        }


        .bottom {
           

            .headerTable {
                display: flex;
                div {
                    flex: 0 0 25%;
                    text-align: center;
                    line-height: 50px;
                    padding-top: 10px;
                   
                }
               
            }

            ul {
                height: calc(100% - 100px);
                overflow-y: auto;
                li {
                    display: flex;
                    
                    div {
                        flex: 0 0 25%;
                        text-align: center;
                        line-height: 40px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    div:first-child {
                        color: #004EA2;
                        font-weight: bold;
                    }

                }
                li:nth-child(odd) {
                    background-color: #F7F8FA;
                }
            }

        }

    }

    .right {
        flex: 0 0 75%;
        height: 100%;
        border-radius: 2px;


        .top {
            .trophy {
                display: flex;
                justify-content: space-between;
                height: 100%;
                position: relative;
                .imgPos {
                    height: 100%;
                    display: flex;
                    position: relative;
                    width: 300px;
                    height: 100%;
                    img {
                        position: absolute;
                        bottom: 0;
                        width: 300px;
                    }

                    .ranking {
                        width: 100%;
                        display: flex;
                        text-align: center;
                        .row {
                            flex: 0 0 33.333%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            align-content: flex-start;
                            position: relative;
                            
                            .circle {
                                width: 80px;
                                height: 80px;
                                background-color: #F7F8FA;
                                border-radius: 50%;
                                position: relative;
                                overflow: hidden;
                                img {
                                    position: absolute;
                                    left: 0;
                                    width: 100%;
                                     height: 80px;
                                     border-radius: 50%;
                                }
                            }

                            .descript {
                                position: relative;
                                top: -10px;
                               font-size: 12px;
                               zoom: 0.8;
                               background-color: #FFBB40;
                               color: #fff;
                               padding: 10px 0;
                               width: 120px;
                                overflow: hidden;//溢出隐藏
                                white-space: nowrap;//禁止换行
                                text-overflow: ellipsis;//
                               border-radius: 5px;
                            }
                        }

                        .first {
                            top: 48px;
                            .descript {
                                background-color: #CFD2F8; 
                            }
                        }    
                        .second {
                            top: 23px;
                            
                        }
                        .thirst {
                            top: 60px;
                            .descript {
                                background-color: #F4B290; 
                            }
                        }

                    }
                    

                }

                ul::-webkit-scrollbar { width: 0 !important; overflow: -moz-scrollbars-none; }

                ul {
                    position: absolute;
                    width: calc(100% - 400px);
                    height: 95%;
                    right: 0;
                    overflow-y: auto;


                    li {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        line-height: 40px;
                        // div {
                        //     // text-align: center;
                        // }
                        div:nth-child(1)  {
                            width: 10%;
                            text-align: center;
                        }
                        div:nth-child(2)  {
                            width: 15%;
                            
                        }

                        div:nth-child(3)  {
                            width: 38%;
                            overflow: hidden;//溢出隐藏
                            white-space: nowrap;//禁止换行
                            text-overflow: ellipsis;//...
                        }

                        div:nth-child(4)  {
                            width: 30%;
                            overflow: hidden;//溢出隐藏
                            white-space: nowrap;//禁止换行
                            text-overflow: ellipsis;//...
                        }
                        div:nth-child(5)  {
                            width: 10%;
                        }

                    }
                    li:nth-child(odd) {
                        background-color: #F7F8FA;
                    }
                }   

                
            } 
            
             


        }


        .bottom {
            position: relative;
            padding: 10px;
            .echart {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;

                #main2 {
                    width: 100%;
                    height: 100%;
                }
            }


            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 30px;
                width: 97%;
                overflow-x: auto;
                z-index: 100;
                position: absolute;
                li {
                    padding: 10px 30px;
                    background-color: #F7F8FA;
                    border-right: 1px solid #D9D9D9;
                    cursor: pointer;
                }
                li:last-child {
                    border-right: none;
                }
                .active {
                    background-color: #004EA2;
                    color: #fff;
                }
            }
        }

    }
}