


.EducationQuestionBank {
   height:calc(100% - 20px) ; 
   overflow-y: auto;
   overflow-x: hidden;
   display: flex;
   

   .left {
       flex: 0 0 10%;
       height: 100%;
       margin-right: 10px;
       .title {
           background-color: #0DC076;
           color: #fff;
           text-align: center;
           height: 40px;
           margin-bottom: 5px;
           display: flex;
           align-items: center;
           justify-content: center;
           position: relative; 
           cursor: pointer;
           img {
               position: absolute;
               left: 8px;
               width: 30px;
               border-radius: 6px;
               background-color: #fff;
           }
       }

       ul {
           height: calc(100% - 45px);
           background-color: #fff;

           li {
               line-height: 40px;
               padding-left: 10px; 
               cursor: pointer;
           }

           .active {
               font-weight: bold;
               color: #0DC076;
           }
       }
   }

   .right {
       flex: 0 0 90%;
       height: 100%;
       overflow-y: auto;
       padding-right: 10px;
       background-color: #fff;
       
       .firstDiv {
           display: flex;
           align-items: center;
           img {
               width: 25px;
               margin-right: 5px;
           }
       }

       .editUl {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;

            li {
                cursor: pointer;
            }
            li:nth-child(1) {
            color: #0DC076;
            }
            li:nth-child(2) {
            color: #004EA2; 
            }
            li:nth-child(3) {
            color: #C71D4D; 
            }
        }
    


       .currentTable {
           width: 100%;
           
           .firstTh {
            text-align: left;
            display: flex;
            align-items: center;
            width: 280px;
            img {
                width: 30px;
                
            }
           } 
           thead {
               th {
                   font-weight: bold;
                   height: 50px;
                   line-height: 50px;
                   padding: 0 20px;
               }
           }

           tbody {
            //    td {
            //        text-align: center;
            //        height: 50px;
            //        line-height: 50px;
            //        padding: 0 20px;
            //    }

               ul {
                   display: flex;
                   justify-content: space-between;
                   li {
                       cursor: pointer;
                   } 
                   li:nth-child(1) {
                    color: #0DC076;
                   }
                   li:nth-child(2) {
                    color: #004EA2; 
                   }
                   li:nth-child(3) {
                    color: #C71D4D; 
                   }
               }

               tr:hover {
                background-color: #0DC076 !important;
                color: #fff;
                ul {
                    li {
                        color: #fff;
                    }
                }
               }
           }

       }

   }




}



.QuestionBankModalTitle {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    position: relative;
    left: 50px;
    img {
        left: -50px;
        position: absolute;
        margin-right: 20px;
        width: 40px;
    }
}


.QuestionBankModalBody {
    position: relative;


    .head {
        display: flex;
        justify-content: space-between;


        .from {
          
           width: 30%;
           
           li {
               display: flex;
               align-items: center;
               margin-bottom: 15px;
               position: relative;
               >span {
                    width: 80px;
               }

               .input {
                   height: 30px;
                   width: calc(100% - 80px);
                   background-color: #F2F2F2;

                   .ant-select-selector {
                    background-color: #F2F2F2 !important;
                    height: 30px;
                } 

               }

               
           }

           li:last-child {
            margin-bottom: 0px;
           }
         }


         .TwoFrom {
            .input {
                width: calc(100% - 180px) !important;
            }

            button {
                position: absolute;
                right: 0;
                width: 80px;
                height: 100%;
                border: none;
                background-color: #004EA2;
                color: #fff;
                border-radius: 2px;
            }

            
                // .ant-checkbox-inner {
                //     background-color: #F2F2F2;
               
                // }
         }


         .sbmitBtn {
             width: 30%;
             display: flex;
             justify-content: flex-end;
             align-items: flex-end;
             
             .warp {
                 width: 160px;
                 height: 75px;
                 background-color: #0DC076;
                 margin-left: 10px;
                 color: #fff;
                 border-radius: 2px;
                 text-align: center;
                //  font-size: 12px;
                 display: flex;
                 justify-content: center;
                 align-items: center;   
                 position: relative;
                 svg {
                     font-size: 30px;
                 }
             }

             .div {
                 position: absolute;
                 left: 0;
                 top: 0;
                 opacity: 0;
             }
             
             .warp:last-child {
                background-color: #004EA2;
             }

         }
    }


    .tips {
        width: 100%;
        // padding-left: 40px;
        display: flex;
        align-items: flex-end;
        font-weight: bold;
        span {
            color: #17C26C;
            padding-right: 20px;
        }
    }


    .table {
        width: 100%;
        margin-top: 40px;

        max-height: 500px;
        overflow-y: auto;

        table {
            width: 100%;
            
            thead {
                font-weight: bold;
                

                th {
                    text-align: center;
                    padding: 0 20px;
                    padding-bottom: 10px;
                }

                th:nth-child(2) {
                    width: 15%;
                    text-align: left;
                }

                th:nth-child(3) {
                    width: 15%;
                    text-align: left;
                }

                th:nth-child(4) {
                    width: 40%;
                    text-align: left;
                }

            }

            tbody {
                
                tr {
                    td {
                        text-align: center;
                        padding: 0 20px;
                        height: 90px;
                    }

                    td:nth-child(2) {
                        text-align: left;
                    }    

                    td:nth-child(3) {
                        text-align: left;
                    }
                    td:nth-child(4) {
                        text-align: left;
                    }
                }

                tr:nth-child(odd) {
                    background-color: #F7F6F6;
                    
                }

            }

  
        }
        
    }

    .noList {
        width: 100%;
        text-align: center;
    }

    


    .foot {
        position: absolute;
        bottom: -80px;
        width: 100%;
        display: flex;
        justify-content: center;

        div {
            width: 140px;
            background-color: #0DC076;
            color: #fff;
            text-align: center;
            line-height: 35px;
            margin: 0 10px;
            border-radius: 2px;
            cursor: pointer;
        }

        div:last-child {
            background-color: #D9D9D9;
            
        }
    }    
}

.QuestionBankCode {
    .ant-modal-header {
        background-color: #004EA2;
        h1 {
            color: #fff;
        }
    }

    .content {
        margin: 0 auto;
        width: 70%;

        h2 {
            font-weight: bold;
            padding-bottom: 10px;
        }  

        img {
            width: 100%;
            margin: 10px 0;
        }

        h3,h4 {
            text-align: center;
        }

        h4 {
            padding-top: 10px;
            color: #C00000;
            font-size: 12px;
        }

        button {
            width: 100%;
            height: 40px;
            background-color: #C5153A;
            color: #fff;
            border: none;
            border-radius: 2px;
            margin-top: 80px;
        }

    }

    .ant-modal-close-x {
        color: #fff;
    }
}


// v-deep.ant-table-tbody{
//     > tr:hover:not(.ant-table-expanded-row) > td,.ant-table-row-hover,.ant-table-row-hover>td{
//     background:none !important;
//     //这里是将鼠标移入时的背景色取消掉了
//     }
//   }