
.machineCommodityAll {
    .input {
        background-color: #fff;
        ul {
            display: flex;
            padding: 20px 0px;
            padding-bottom: 0;
            border-bottom: 1px solid #F2F2F2;
            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
                margin-bottom: 20px;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }
    
            
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
            cursor: pointer;
        }
    }

    .input {
        ul {
            li {
                margin-left: -10px !important;
            }
        }
    }


    .defindStatus {
        color: #ff0000;
    }

    .status3 {
        color: #00B050;
    }

    .status2 {
        color: #6A9BF4;
    }




    .head {
        margin-bottom: 10px;

        .echarts {
            width: 100%;
            height: 100%;
            canvas {
                width: 100%;
                height: 100%;
            }
        }
        >ul {
            display: flex;
            justify-content: space-between;
            >li {
                height: 250px;
                background-color: #fff;
                position: relative;
                width: 37.25%;
                margin-left: 10px;
                padding: 15px;
                border-radius: 2px;
                
                h1 {
                    font-weight: bold;

                    span {
                        font-weight: normal;
                        font-size: 12px;
                        zoom: 0.8;
                    }
                }
 
            }
            >li:nth-child(1) {
                width: 25%;
                margin-left: 0;
                
                .number {
                    display: flex;
                    
                    >div {
                        flex: 0 0 50%;
                        text-align: center;
                        h2 {
                            margin-bottom: 50px;
                            margin-top: 60px;
                            font-size: 50px;
                            color: #FFAB34;
                            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                        }
                        .blue {
                            color: #17C26C;
                        }
                        

                    }
                }

            }
            >li:nth-child(2) {
                .echarts {
                    width: 100%;
                    height: 100%;
                }
            }
            >li:nth-child(3) {
                padding: 0px;
                img {
                    width: 100%;
                    height: 100%;
                    border: 2px solid #fff;
                }
            }
        }
    }



   
}

.machineCommodityAllModal {
    .justCont {
        justify-content: space-between;
    }
    h1 {
        margin-top: -10px;
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
    }
}




