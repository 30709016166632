




.maxHeight {
  

    height: 100%;
    overflow: hidden;
    
    .leftData .carList {
        height: calc(100vh - 1.157rem)  !important;
    }

    p, span, i {
        font-size: 0.048rem;
    }
    
    svg {
        position: absolute;
    }
    .ant-select {
        width: 80%;
    }
    
    .carStatistics {
        display: flex;
        box-sizing: border-box;
    
    
        ::-webkit-scrollbar{
    
            display:none;/*ChromeSafari*/
                    
        }
    
        .setNone {
            display: none;
        }
    
    
        .leftData {
            width: 25%;
            position: relative;
            padding-left: 0.04rem;
    
    
            .close {
                position: absolute;
                top: 0.02rem;
                right: 0.08rem;
                font-size: 0.12rem;
                cursor: pointer;
            }
            
            // overflow-x: hidden;
            .moduleChangeTime {
                width: 100%;
                 height: 1.4rem;
                background-color: #fff;
                position: absolute;
                top: 0;
                z-index: 100;
                box-shadow: 14px 14px 10px #888888;
    
                h2 {
                    font-size: 0.06rem;
                    font-weight: bold;
                    padding: 0.04rem 0.04rem;
                    border-bottom: 1px solid #F1F2F6;
                }
    
                ul {
                    display: flex;
                    margin: 0 0.04rem;
                    justify-content: space-between;
                    margin-top: 0.04rem;
                    li {
                        background-color: #F2F2F2;
                        padding: 0.04rem 0.08rem;
                        border-radius: 0.002rem;
                        cursor: pointer;
                        font-size: 0.04rem;
                    }
    
                    .timeActive {
                        background-color: #ED7D31;
                        color: #fff;
                    }
                }
    
    
                .timeWarp {
                    width: 100%;
                }
    
                .setDiv {
                    display: flex;
                    line-height: 0.12rem !important;
                    height: 0.12rem !important;
                    margin: 0.04rem 0;
                    padding: 0 0.04rem;
                    h3 {
                        margin-right: 0.008rem;
                        width: 20%;
                        font-size: 0.006rem;
                    }
    
                    .ant-picker {
                        width: 80%;
                    }
                }
    
                
                .submitBtn {
                    text-align: center;
                    button {
                        padding: 0px 0.28rem;
                        position: absolute;
                        top: 1.1rem;
                        margin-left: -0.3rem;
                        background-color: #ED7D31;
                        border: 1px solid #ED7D31;
                    }
                }
    
            }
    
    
    
            .Statistics {
                background-color: #fff;
                h1 {
                    font-weight: bold;
                    font-size: 0.07rem;
                    padding: 0.05rem 0.06rem;
                }
    
                .statusWarp {
                    display: flex;
                    box-sizing: border-box;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0.08rem 0.3rem;
                    
                    div {
                        p {
                            text-align: center;
                            line-height: 0.16rem;
                            font-size: 0.06rem;
                        }
                        p:first-child {
                            font-size: 0.065rem;
                            color: #808080;
                        }
                    }
    
                    .border2 {
                        position: relative;
                        border-left: 1px solid #F0F2F5;
                        border-right:1px solid #F0F2F5;
                        padding-left: 15%;
                        padding-right: 15%;
                    }
    
    
                    .color1 {
                        color: #00B050;
                        font-weight: bold;
                    }
                    .color2 {
                        color: #FF0000;
                        font-weight: bold;
                    }
                    .color3 {
                        color: #7F7F7F;
                        font-weight: bold;
                    }
    
    
                    
                    
                }
            }
    
    
            .setul {
                background-color: #fff;
                margin-top: 0.04rem;
                padding: 0.04rem;
                position: relative;
                ul {
                    display: flex;
                    
                    justify-content: space-between;
                }
    
                li {
                    cursor: pointer;
                    font-size: 0.05rem;
                }
    
                .active {
                    color: #2FC59A;
                    border-bottom: 0.012rem solid #2FC59A;
                    padding-bottom: 0.04rem;
                }
    
                
            }
    
            .carList {
                margin-top: 0.04rem;
                width: 100%;
                height: 1.96rem;
                overflow-y: auto;
                background-color: #fff;
                scrollbar-width:none;/*Firefox*/
                -ms-overflow-style:none;/*IE10+*/
               
                ul {
                    padding: 0.04rem;
    
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        margin-bottom: 0.12rem;
                        font-size: 0.03rem;
                        .imgType {
                            background-color: #F3F3F2;
                            padding: 0.028rem 0.04rem;
                        }
                        img {
                            width: 0.1rem;
                            height: 0.1rem;
                        }


                        .carMsg {
                            // display: flex;
                            line-height: 0.1rem;
                            p {
                                display: flex;
                                .se1 {
                                    display: inline-block;
                                    width: 0.32rem;
                                    color: #7F7F7F;
                                    // padding-right: 20px;
                                    
                                }
    
                                .h4 {
                                    color: #7F7F7F;
                                }
    
                                b{
                                    color: #fff;
                                    padding: 0.008rem 0.056rem;
                                    border-radius: 0.02rem;
                                    font-size: 0.03rem;
                                   background-color:  #FFB5BB;
                                }
    
                                .statusB3 {
                                    background-color: #2FC59A;
                                }
    
                                .h3 {
                                    font-weight: bold;
                                }
    
                            }
                        }
                        
                        .status {
                            background-color: #F3F3F2;
                            font-weight: bold;
                            padding: 0.012rem 0px;
                            border-radius: 0.02rem;
                            line-height: 0.08rem;
                            width: 0.48rem;
                            text-align: center;
                        }
    
                        .active {
                            background-color: #2979FF;
                            color: #fff;
                        }
                    }
                }
            }
    
        }
    
        .rightData {
            width: 75%;
            height: calc(100vh - 0.252rem);
            margin: 0 0.05rem;
            background-color: #fff;
    
            #container {
                border: 3px solid #fff;
                width: 100%;
                height: 100%;
            }
        }
    }



    .alertPPP {
        color: #333 !important;
        text-align: left;
        line-height: 0.1rem;
        padding-right: 0.16rem;
    
    }


    // .ant-btn {

    // }


    input {
        height: 0.12rem !important;
    }

   
}

