

.GIScenter {
    height: calc(100vh - 50px);
    position: relative;

    .threeRef {
        width: 100%;
        height: 100%;
    }


    .selectFun {
        position: absolute;
        bottom: 60px;
        z-index: 100;
        margin: 0 auto;
        text-align: center;
        display: flex;
        
        left: 40%;
        
        //width: 100%;

        .ul2 {
            margin-left: 10px;
            position: relative;
            .range {
                // display: none;
                position: absolute;
                width: 100%;
                top: -30px;
            }
        }
        ul {
            background-color: rgba(255,255,255, 0.7);
            border-radius: 4px;
            li {
                padding: 6px 6px;
                margin: 0 14px;
                position: relative;
                
                p {
                    pointer-events: none;
                    position: absolute;
                    background-color: rgba(255,255,255, 0.8);
                    top: -40px;
                    left: -20%;
                    white-space: nowrap;
                    width: 70px;
                    font-size: 12px;
                    line-height: 30px;
                    border-radius: 2px;
                    opacity:0;
                    zoom: 0.9;
                }

            }

            .active {
                background-color: #ccc;
            }


            li:hover {
                pointer-events:initial;
                background-color: #ccc;
                p {
                   opacity:1;
                   transition: transform 0.6s, opacity 0.6s;
                   transform:translateY(-20%);

                }
            }

            display: flex;
            justify-content: center;
            img {
                width: 30px;
            }
        }
    }


    .selectproject {
        position: absolute;
        top: 10px;
        left: 5px;
        z-index: 100;
        background-color: #004EA1;
        color: #fff;
        border-radius: 2px;

        h2 {
            color: #fff;
            text-align: center;
            padding: 15px;

        }

        .active {
            background-color: #C71D4C;
        }

        li {
            padding: 15px 30px;
            position: relative;
            cursor: pointer;
        }

        li::after {
            content: '';
            position: absolute;
            width: 80%;
            height: 1px;
            background-color: #0364D1;
            bottom: 0;
            left: 10%;
        }

        li:last-child::after {
            background-color: #004EA1;
        }

        li:hover {
            background-color: #C71D4C;
        }

    }
}