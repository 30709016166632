

// .machineDeviceList {

// }


.machineDeviceList {

    .setType2 {
        color: #5DC55D;
    }
    
    .setType3 {
        color: #ff0000;
    }



    .setColor {
        color: #6A9BF4;
        cursor: pointer;
    }

}

.machineDeviceListModal {
    
    >div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    span {
       display: inline-block;
       width: 120px;
    }
}

