.layout {
  overflow: hidden;
  .acitveNamehh {
    color: #63ABFF;
  }

  .ulNamehh {
    background-color: #fff;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;

    li {
      cursor: pointer;
      padding-right: 20px;
    }
  }

  height: calc(100vh - 50px);
  color: #333;

  .layout-sider {
    background-color: #fff;
    // box-shadow: 1px 1px 3px #eee;

    .logo {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #f0f0f0;

      img {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }

      .logoTitle {
        display: inline-block;
        transition: all 0.3s;
        height: 32px;
        line-height: 32px;
        overflow: hidden;
        color: #1890ff;
        font-size: 18px;
        font-weight: 700;

        &.active {
          width: 0;
        }
      }
    }

    .menuWrap {
      height: calc(100vh - 50px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .ant-menu-item {
        margin-top: 0;
      }
    }


  }

  .layoutHeader {
    padding: 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    height: 48px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    z-index: 9;

    // .projectName {
    //   line-height: 40px;
    //   font-size: 14px;
    // }

    .headerLeft {
      flex: 1;
      text-align: left;
      height: 48px;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .headerRight {
      height: 48px;
      flex: 1;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .iconItem {
        width: 48px;
        height: 48px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #eee;
        }
      }

      .avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }

      .userName {
        margin-left: 10px;
        line-height: 48px;
        cursor: pointer;
      }
    }
  }

  .layoutContent {
    // padding: 10px;
    height: calc(100vh - 145px);
    overflow-y: scroll;
  }

  .sitePageHeader {
    background-color: #fff;
    padding: 10px 20px;

    display: flex;
    align-items: center;
   

    .title {
      position: relative;
      padding-right: 60px;
      h2 {
        font-weight: bold;
      }

      p {
        margin-top: 5px;
        font-size: 12px;
        zoom: 0.8;
      }
    }

    .title::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      right: 30px;
      top: 0;
      background-color: #D9D9D9;
    }


    ul {
      display: flex;

      li {
        margin-right: 20px;
        cursor: pointer;
        font-size: 16px;

        &.active {
          color: #004EA2;
          font-weight: bold;
          
        }
      }
    }
  }

  .b1 {
    background-image: linear-gradient(to right, #EC3C2E , #F04861);
    color: #F3C1BF !important;
    .ant-page-header-heading-title {
      color: #fff !important;
    }
    .active {
      color:  #fff !important;
    }
  }

  .ant-popover {
    .ant-popover-inner-content {
      padding: 0;

      .ant-list-item {
        padding: 12px 16px;
      }
    }

    .newsMenu {
      border-bottom: 1px solid transparent !important;
    }

    .btnUl {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        border-top: 1px solid #f0f0f0;
        flex: 1;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        &:nth-last-of-type(1) {
          border-left: 1px solid #f0f0f0;
        }
      }
    }
  }
}