


.engineering {
   
    
    // .enterpriseList {
    //     overflow: hidden !important;
    // }

    .allBody {
        padding-left: 30px;
        width: 98%;
    }

    .allBtn {
        text-align: right;
        margin-right: 5px;
    }
    
}

