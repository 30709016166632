


.FloodPrevention {
  

    .table {
    
        background-color: #fff;
    }
    

    .input {
        ul {
            display: flex;
            padding: 0px 10px;
            padding-top: 20px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }

    }


    .input {
        ul {
            li {
                margin-left: -10px !important;
                margin-bottom: 10px;
            }
        }
        
    }


    .table2 {
        padding: 10px;
    
        .allBtn {
            margin-bottom: 0px;
            text-align: right;
            .bt {
                margin-left: 20px;
            }
        }
    }

}