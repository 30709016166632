


   
.overviewWarp {
    padding: 10px;
    padding-right: 5px;
    overflow: hidden;


    

   
    h1 {
        position: relative;
        // left: 40px !important;
        padding-left: 30px !important;
        .posImg {
            position: absolute;
            left: 0px;
            top: -5px;
            width: 20px;       
        }
        .p2 {
            top: 10px;
            left: 4px;
        }
    }

    .topWarp {
        display: flex;
        width: 100%;
        justify-content: space-between;    
    }

    .leftData {
        // width: 100%;
        width: calc(70% - 10px);
        .context  {
            
            h1 {
                position: relative;
                color: #262626;
                font-weight: bold;
                left: 10px;
                top: 20px;
            }
            background-color: #fff;
            height: 340px;
            width: 100%;

            

            .text {
                display: flex;
                position: relative;

               
              
                .echarts {
                    width: 55%;
                    height: 300px;
                    margin-right: 50px;
                    position: relative;
                
                    .number {
                        position: absolute;
                        right: 5px;
                        top: 25px;
                        text-align: right;
                        p {
                            padding-bottom: 8px;
                            padding-top: 5px;
                        }
                        span {
                            font-size: 30px;
                            color: #c24e63;
                            font-family: Impact,Haettenschweiler,"Arial Narrow Bold",sans-serif;
                        }
                    }

                    #main {
                        width: 100%;
                        height: 100%;
                    }

                }



                .personList {
                    // width: calc(100% - 55%);
                    width: 45%;
                }
            }


            .personList {
                padding-top: 5px;
                font-weight: bold;

                .current {
                    padding-top: 10px;
                    span {
                        font-weight: normal;
                        font-size: 40px;
                        color: #C71D4C;
                        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                    }
                }

                .scroll {
                    width: 92%;
                    overflow-x:auto;
                    overflow-y:scroll; 
                    white-space: nowrap;
                    height: 170px;
                }

                ul {
                    display: flex;
                    margin-top: 40px;
                    
           
                    li{
                        background-color: #E3ECF5;
                        padding: 10px;
                        padding-bottom: 0px;
                        padding-right: 15px;
                        margin-right:10px;
                        position: relative;
                        color:#004EA1;
                        width: 160px;
                        img {
                            position: absolute;
                            width: 70%;
                            bottom: 0px;
                            left: 15%;
                        }

                        .p2 {
                            height: 100px;
                            display: flex;
                            align-items: flex-end;
                            position: relative;
                            box-sizing: border-box;
                            //line-height: 80px;
                            span {
                                font-size: 40px;
                                font-weight: normal;
                            }
                            i {
                                padding-left: 0px;
                                position: relative;
                                top: -6px;
                                left: 12px;
                            }
                        }
                        .p3 {
                            font-size: 13px;
                            // line-height: 40px;
                            span {
                                color: #FFC000;
                            }
                            padding-bottom: 10px;
                        }
                        .p4 {
                            font-size: 13px;
                            span {
                                color: #FF0000;
                            }
                        }
                        span {
                            font-size: 18px;
                        }

                    }

                    li:first-child {
                        background-color: #004EA2;
                        color: #fff;
                    }

                }
               

            }

        }
    }

    .rightData {
        width: 30%;
        margin-left: 10px;
        background-color: #fff;
        height: 340px;
    }

    .setBack {
        background-color: #004EA1;
        position: relative;
        .baImg {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
        }
    }


    .topRdata {
        padding: 20px;
        position: relative;
        .tText {
            display: flex;
            padding: 20px 0;
            color: #fff; 
            .myImg {
                position: relative;
                width: 160px;
                height: 270px;
                background-color: rgba(255,255,255, 0.1);
                overflow: hidden;
                margin-right: 20px;
                img {
                    width: 100%;
                    height: 100%;
                }
                p {
                    position: absolute;
                    z-index: 100;
                    bottom: 0;
                   width: 100%;
                   text-align: center;
                   font-size: 20px;
                   background-color: rgba(0, 0, 0, 0.4);
                }
            }
        }

        
        li {
            line-height: 30px;
        }

        

        .handHover {
            display: none;
            width: 100%;
            // height: 100%;
            position: absolute;
            top: 48%;
            left: 0;
            z-index: 10;

            .iconStyle {
                width: 100%;
                // height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px;
                
                .icon {
                    
                    svg {
                        font-size: 30px;
                        color: rgba(0,0,0,0.2);
                        cursor: pointer;
                    }
                    
                }
            }
        }
    }

    
    // .topRdata:hover {
    //     // .handHover {
    //     //     display: block;
    //     // }
    // }









    .flex {
        display: flex;
        justify-content: space-between;
        h1 {
            line-height: 44px;
            padding-left: 20px;
            font-weight: bold;
            
        }
    
        .chuqing {
            width: 70%;
            background-color: #fff;
            height: 465px;
    
            h1 {
                border-bottom:1px solid #F2F2F2;
            }
    
            .echart2 {
                width: 100%;
                height: 400px;
            }
        }

        scrollbar-width:none;
        -ms-overflow-style:none;

        .zhongdian::-webkit-scrollbar{
            display:none;/*ChromeSafari*/
        }

    
        .zhongdian {
            font-size: 13px;
            width: 30%;
            margin-left: 10px;
            background-color: #fff;
            height: 465px;
            overflow-y: auto;


            

            
            .setSpanColor {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }

            .setWidth {
                display: inline-block;
                width: 70px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                position: relative;
                top: 6px;
            }

            .zdUl {
                display: flex;
                 li {
                     width: 50%;
                 }   
                .firstLi {
                    width: 50%;
                    text-align: center;
    
                    .firtT1 {
                        padding-bottom: 15px;
                    }
    
                    .title {
                      color: #4287F4;
                      font-weight: bold;
                      font-size: 20px;
                      padding-bottom: 5px;
                    }
                }
    
                .firstLi> div {
                    margin-top: 8px;
                }
    
                .echarts3 {
                    width: 100%;
                    height: 100%;
                }
    
                
            }
    
            .personLiable {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                padding-left: 30px;
                li {
                    
                    width: 48%;
                    
                    // text-align: center;
                    line-height: 20px;
                }
            }
            
            .personLiable2 {
                margin-top: 5px;
                font-size: 10px;
                span {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 500px;
                    background-color: #0049CB;
                }

                .span1 {
                    background-color: #00BEFE;
                }
                
            }
    
            .title2 {
                font-weight: bold;
                text-align: center;
                line-height: 40px;
    
            }
                
        }
    
    }

    

    .slick-dots {
       li {
           background-color: cadetblue !important;
       } 

       .slick-active {
           background-color: #000 !important;
       }
    }
    
    .topRdata {
        position: relative;
        h3 {
            text-align: center;
            font-size: 16px;
           span {
               color: #00B050;
               
           }
        }

        .setH3 {
            margin-top: 20px;
            font-size: 18px;
        }

        .posRate {

            position: absolute;
            left: 37.5%;
            top: 43%;
            font-size: 40px;
            font-weight: bold;
        }
    }

    #echarts5 {
        margin-left:-30px;
        height: 300px;

    }


    .bottomWarp {
        margin-top: 10px;
        height: calc(100% - 200px); 
    
        .rightData {
            height: 465px;
            margin-left: 10px;
        }
    }
    
    

}

.overviewModal {
    max-height: 600px;
    overflow: auto;
}




