.educationOverview {
  width: 100%;
  display: flex;
  height: 100%;
  .overview-left {
    flex: 1;
    max-width: 67%;
    .statistics-wrap {

      h2 {
        font-weight: bold;
        padding: 10px;
      }
      background-color: #fff;
      .list {
        
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #a2aaab;
        font-size: 16px;
        // margin-bottom: 49px;
        // margin-top: 50px;
        height: 105px;
        margin-bottom: 15px;
        
        .statistics-item {
          &>p {
            &:nth-of-type(2) {
              margin-top: 10px;
              .num {
                font-size: 36px;
                color: #364263;
                // font-weight: bold;
                margin-right: 10px;
                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
              }
            }
          }
        }
      }

    }
    .education-message {
      display: flex;
      color: rgba(255,255,255,0.8);
      margin-bottom: 15px;
      .message-left {
        flex: 1;
        // background-color: #F9AD3F;
        background-color: #00B050;
        border-radius: 2px;
        margin-right: 10px;
        padding: 20px;
        position: relative;
        & > p {
          font-size: 16px;
          &:nth-of-type(1) {
            margin-bottom: 26px;
            font-size: 18px;
            span {
              &:nth-of-type(1) {
                margin-right: 10px;
              }
            }
          }
          &:nth-of-type(2) {
            margin-bottom: 26px;
            span {
              &:nth-of-type(1) {
                margin-right: 10px;
                font-size: 22px;
                color: rgba(255,255,255,1);
              }
            }
          }
        }
        .avatar {
          position: absolute;
          right: 10px;
          bottom: 0;
          // width: 150px;
          height: 265px;
        }
      }
      .message-right {
        flex: 1;
        background-color: #399DF2;
        border-radius: 2px;
        margin-left: 10px;
        padding: 20px;
        position: relative;
        & > p {
          font-size: 16px;
          &:nth-of-type(1) {
            margin-bottom: 26px;
            font-size: 18px;
            span {
              &:nth-of-type(1) {
                margin-right: 10px;
              }
            }
          }
          &:nth-of-type(2) {
            margin-bottom: 26px;
            span {
              &:nth-of-type(1) {
                margin-right: 10px;
                font-size: 22px;
                // color: rgb(236, 148, 148);
              }
            }
          }
        }
        .avatar {
          position: absolute;
          right: 10px;
          bottom: 0;
          height: 265px;
        }
      }
      .yellow-point {
        position: relative;
        padding-left: 20px;
        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #FFD44F;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
      .red-point {
        position: relative;
        padding-left: 20px;
        line-height: 26px;
        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #FF6060;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
      .blue-point {
        position: relative;
        padding-left: 20px;
        line-height: 26px;
        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #00A6FF;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
      .green-point {
        position: relative;
        padding-left: 20px;
        line-height: 26px;
        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #16E9EC;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
    }
    .chart-wrap {
      width: 100%;
      background-color: #fff;
      border-radius: 2px;
      padding: 10px;
      .educationChart {
        width: 100%;
        height: 340px;
      }

      h2 {
        font-weight: bold;
      }
    }
  }
  .overview-right {
    flex: 0 0 530px;
    height: 100%;
    margin-left: 20px;
    background-color: #fff;
    border-radius: 2px;
    .btn-list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 16px;
      margin-top: 30px;
      color: #a2aaab;
      span {
        padding: 0 20px;
        line-height: 30px;
        border-radius: 2px;
        cursor: pointer;
        &.active {
          background-image: linear-gradient(to right,#2D8FFF, #006DFF);
          color: #fff;
        }
      }
    }
    .education-list {
      margin-top: 20px;
      padding: 0 20px;
      height: calc(100vh - 256px);
      overflow: scroll;
      .education-item {
        display: flex;
        padding: 15px 0;
        & + .education-item {
          border-top: 1px solid #eee;
        }
        .left {
          flex: 0 0 50px;
          margin-right: 20px;
          img {
            width: 50px;
          }
        }
        .right {
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex: 1;
          p {
            display: flex;
            justify-content: space-between;
            line-height: 26px;
            &:nth-of-type(2) {
              color: #a2aaab;
            }
            .fraction {
              color: #16E9EC;
            }
          }
        }
      }
    }
  }
}