




// .red {
//     color: brown;
// }

.deviceInspection {

    


    .removeOpen {
        overflow:visible !important;
        li  {
            margin-bottom: 20px;
        }
    }


    .input ul {
        padding: 0px 30px;
        padding-top: 20px;
    }


}

.vehicleControlList {
    padding: 0 10px;
    .table {
    
        background-color: #fff;
    }

    .btn2 {
        margin-left: -10px;
    }



    
    .input {
        background-color: #fff;
        ul {
            display: flex;
            padding: 20px 0px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;

            transition:all .5s;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }

    }

    
    
    .input {
        ul {
            li {
                margin-left: -22px !important;
            }
        }
        
    }

    .page1 {
        .head {
            margin-bottom: 10px;

            .echarts {
                width: 100%;
                height: 100%;
                canvas {
                    width: 100%;
                    height: 100%;
                }
            }
            >ul {
                display: flex;
                justify-content: space-between;
                >li {
                    height: 250px;
                    background-color: #fff;
                    position: relative;
                    width: 37.25%;
                    margin-left: 10px;
                    padding: 15px;
                    border-radius: 2px;
                    
                    h1 {
                        font-weight: bold;

                        span {
                            font-weight: normal;
                            font-size: 12px;
                            zoom: 0.8;
                        }
                    }
     
                }
                >li:nth-child(1) {
                    width: 25%;
                    margin-left: 0;
                    
                    .number {
                        display: flex;
                        
                        >div {
                            flex: 0 0 50%;
                            text-align: center;
                            h2 {
                                margin-bottom: 50px;
                                margin-top: 60px;
                                font-size: 50px;
                                color: #C71D4D;
                                font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                            }
                            .blue {
                                color: #004EA2;
                            }
                            
    
                        }
                    }
    
                }
                >li:nth-child(2) {
                   
                    .echarts {
                        width: 100%;
                        height: 100%;
                    }
                }
                >li:nth-child(3) {
                    background-color: #181717;
                    color: #fff;
                    padding: 0;
                    position: relative;
                    h1 {
                        color: #fff;
                        padding-left: 15px;
                        padding-top: 15px;
                    }

                    .bgimg {
                        position: absolute;
                        right: -14px;
                        top: -40px;

                        height: 120%;
                    }


                    .context {

                        padding:8px 15px;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        color: #fff;
                        .img {
                            width: 60%;
                            border: 1px solid #FFAB34;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                            
                        }

                        .text {
                            padding-left: 15px;
                            p {
                                line-height: 38px;
                                span {
                                    font-weight: bold;
                                    color:#FFAB34;
                                }
                            }
                        }

                       
                    }

                }
            }
        }
    }
}