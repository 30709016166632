.ConstructionProgress {
    background-color: #fff;
    margin: 0 10px;

    margin-top: 10px;
    position: relative;

    .allBtn {
        position: absolute;
        right: 10px;
        top: 20px;
    }

    .read {
        color: #ff0000;
    }


    .removeOpen {
        padding: 20px 0 !important;
        li {
            margin-left: 0 !important;
        }
    }


    .ant-table {
        td {
            padding: 14px 16px !important; 
        }
    }


    .greenBackgreen {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #38E274;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.editModalTunnel {
    ul {
        li {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .width {
                width:48%;
            }
        }
    }
}


.ConstructionProgressModal {
    .setUPloda {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }


    .bt {
        width: 340px;
    }

    .warpConstruction {
       
        padding-bottom: 100px;

        ul {
            li {
                padding-bottom: 20px;
                .span {
                   display: inline-block;
                   width: 50px;
                   text-align: right;
                   margin-right: 10px;     
                }
            }
        }
    }
    
}


