



.setWidth {
    width: 95%;
}


.ProjectOverview {
    h2 {
        color: #fff;
    }
}

.viewHead {
    h1{
        letter-spacing: 5px;
    }
  

    display: flex;
    justify-content: space-between;

    button {
        background-image: linear-gradient(to right, #25C1FE , #4F14CE);
        border: none;
        border-radius: 2px;
        padding: 7px 15px;
    }


    
}

.p {
    padding-top: 20px;
    p {
        font-size: 12px;
        text-indent: 2rem;
        margin-bottom: 10px;
        line-height: 17px;
        letter-spacing: 3px;
    }

}



.ul {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    
    h2 {
        color: #fff;
        font-weight: bold;
    }

    li {
        line-height: 30px;

        p {
            font-size: 20px;
            font-weight: bold;
        }

        .p1 {
            color: #DEAA3A;
        }
        .p2 {
            color: #2474C2;
        }
    } 



    .lastLi {
        line-height: 20px;
        padding-top: 35px;
        font-size: 12px;
        div {
            display: flex;
        }

        .setColor {
            margin-left: 20px;
            width: 40px;
            color: #CE2D58;
            font-weight: bold;
        }
    }

}


.jinzhan {
    padding-top: 10px;
    .title {
        font-weight: bold;
        letter-spacing: 3px;
        font-size: 18px;
    }
    span {
        font-size: 12px;
    }

    .rouad {
        padding-top: 25px;
        display: flex;
        
    }

    .propes {
        margin-top: 20px;
        width: 100%;
        padding-left: 30px;

        .number {
            font-size: 25px;
            i {
                color: #3BEC9F;
            }
        }

        .year {
            padding-top: 20px;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
        }
    }
}

// .video {
//     margin-top: 30px;
//     width: 100%;
//     height: 240px;
//     background-color: #fff;
// }

.earth {
    .ant-progress-text {
        color: #3BEC9F !important;
    }
    
    .ant-slider-track {
        background-color: #3BEC9F !important;
    
    }
    
    .ant-slider-handle {
        display: none !important;
    }
    
    ::v-deep .ant-progress-text {
        color: #fff;
    }
}






