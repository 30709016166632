


.companyWarp {
    .set {
        display: flex;
       
    }
    
    .select {
        width: 200px;
    }
    .set> div {
        width: 200px;
        margin-right: 20px;
    }
    
    .right {
        display: flex;
        justify-content: end;
    } 



    .ant-form-item-control-input-content {
        width: 300px !important;
    }
    
}









