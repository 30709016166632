.WageEntry {
    background-color: #fff;
    margin: 0 10px;

    .allBtn {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
        .bt {
            margin-left: 20px;
        }
    }


    .table2 {
        padding: 0 10px;
        margin-top: 20px;
    }

    .input {
        ul {
            display: flex;
            padding: 20px 0px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;
            height: 70px;
            transition:all .5s;
            overflow: hidden;
            li {
                margin-left: 0px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
            background-color: #FAA646;
            color: #fff;
            border-color: #FAA646;
            margin: 0;
            margin-left: 20px;
            display: flex;
            align-items: center;
        }
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
        }
        
       
    }

    .addOpen {
        height: 150px !important;
    }


    .operation {
        display: flex;
        justify-content: space-around;
    }


    .setProblemLevel3 {
        color: orange;
    }
    .setProblemLevel4 {
        color: red;
    }


    .logSucess {
        color: #5DC55D;
    }

}

.WageEntryModal {

    .setHeight {
        padding-top: 10px;
        max-height: 600px;
        overflow-y: auto;
    }

    .allWarp {
        max-height: 600px;
        overflow-y: auto;
        padding-right: 10px;


        .setWarp {
            padding-left: 20px;
            margin-bottom: 30px;
        }

        h1 {
            font-size: 16px;
            font-weight: bold;
            position: relative;
        }

        h1::after {
            content: '';
            width: 5px;
            height: 20px;
            background: #07C160;
            position: absolute;
            left: -15px;
            border-radius: 30px;
            top: 2px;
        }

        ul {
            li {
                display: flex;

                div {
                    display: flex;
                    margin-right: 30px;
                }

                p {
                    line-height: 40px;
                    margin-top: 10px;
                    
                }
                p:first-child {
                    width: 80px;
                    font-weight: bold;
                }

                .divResult {
                    background: #F4F5F9;
                    padding: 0 10px;
                    
                }
                
                .result {
                    background: #F4F5F9;
                    width: calc(100% - 80px) ;
                    border-radius: 4px;
                    padding-left: 10px;
                }
            }
        }

        .remark {
            margin-top: 20px;
            p:first-child  {
                font-weight: bold;
            }
            p:last-child {
                margin-top: 10px;
                min-height: 80px;
                background: #F4F5F9;
                padding: 5px 10px;
            }

        }

        .img {
            margin-top: 20px;
            span {
                margin-right: 10px;
            }
        }

    }    


    .log {
        margin-top: 10px;
        p:first-child {
            width: 80px;
            font-weight: bold;
        }
    }

}


