.WageEntry {
    background-color: #fff;
    margin: 0 10px;


    .input {
        ul {
            display: flex;
            padding: 20px 30px;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
            background-color: #FAA646;
            color: #fff;
            border-color: #FAA646;
            margin: 0;
            
        }
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
        }
    }
}


