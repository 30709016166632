




.ant-upload {
    width: 100% !important;
    height: 100% !important;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 100% !important;
}

.hhLoad {
    width: 100% !important;
    height: 100% !important;
}

.div {
    display: flex;
}


.removeImgList {
    position: absolute;

}
