

.btn {
    margin:10px 0;
}
.setBtn {
    margin-right: 10px;
}


.list {
    display: flex;

}

.gongziNum {
    display: flex;
    padding: 0px 10px; 

    .border {
        border: 1px solid #F2F2F2;
        margin-bottom: 60px;
        overflow: hidden;
    }
    h1 {
        padding: 20px 20px;
        font-weight: bold;
    }


    .echarts {
        width: 100%;
        height: 500px;
    }


    .tongji {
        margin-right: 7.5px;

        .list> div {
            width: 50%;
        }

        .list{
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            padding: 0 90px;

            .pc {
                font-weight:bold;
                font-size: 25px;
                padding: 10px 0 20px 0;
            }

            .pcolor1 {
                color: #1DC7B5;
            }

            .pcolor2 {
                color : #2977F4;
            }

            .pcolor3 {
                color: #FD3476;
            }

            .pcolor4 {
                color: #FFB81F;
            }
        }

    }
    .fafang {
        margin-left: 7.5px;


        .echarts2 {
            width: 70%;
            height: 500px;
            margin: 0 auto;
            margin-top: 100px;
        }

    }
}

.gongziNum> div {
    width: 50%;
    background-color: #fff;
}