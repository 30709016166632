
.machineCommodityAll {
    .input {
        background-color: #fff;
        ul {
            display: flex;
            padding: 20px 0px;
            padding-bottom:0;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;
            // height: 70px;
            transition:all .5s;
            
            // overflow: hidden;
            li {
                margin-left: 0px;
                display: flex;
                margin-bottom: 20px;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }
    
            
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
            cursor: pointer;
        }
    }

    .input {
        ul {
            li {
                margin-left: -10px !important;
            }
        }
    }


   
}

.machineCommodityAllModal {
    .justCont {
        justify-content: space-between;
    }
    h1 {
        margin-top: -10px;
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
    }
}




