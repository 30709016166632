


.warp {
    display: flex;
}

.leftWarp {
    width: 300px;
}

.flex {
    display: flex;
}

.content {
    justify-content: space-between;
}

.setMargin20 {
    margin-left: 40px;
}

.setMargin150 {
    margin-left: 150px;
}

.setMargin60 {
    margin-left: 60px;
}

.userListModeal {

    .allWarp {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    
        
        
        .rightData {
            margin-left: 30px;
        }
        
        .ul {
            display: flex;
            li {
                width: 300px;
            }
        }
    }
    
    
    
    
    .bankCard {
        
        .bankMsg {
           
            div {
                width: 500px;
            }
        }
    }
    
    
    
    .singStyle {
        .setWidth {
            width: 600px;
        }
    }
    
    
    .personUl {
        li {
            margin-bottom: 10px;
            display: flex;
            width: 500px !important;
            p {
                margin-right: 20px;
            }
        }
        
    }
    
    .personUl2 {
        // margin-top: 30px;
        
        li {
            width: 90% !important;
        }
    
        margin-top: 20px;
    }
    
    .removeImgList {
        position: absolute;
        background-color: #000;
        display: inline-block;
        width: 20px;
        height: 20px;
        z-index: 100;
        right: -10px;
        top: -10px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        color: #fff;
        cursor: pointer;
    }
    
    .specialType {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }

}



