




// .red {
//     color: brown;
// }

.deviceInspection {
    margin-top: 10px;
    .removeOpen {
        overflow:visible !important;
        li  {
            margin-bottom: 20px;
        }
    }


}