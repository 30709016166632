.environmentalMonitoring {
  padding: 0 10px;
  .nav-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .nav-list {
      display: flex;
      margin-top: 10px;

      li {
        margin: 0 10px;
        background-color: #fff;
        padding: 8px 20px;
        border-radius: 3px;
        cursor: pointer;

        &:nth-of-type(1) {
          margin: 0;
        }

        &.active {
          background-color: #004EA1;
          color: #fff;
        }
      }
    }
  }

  .flex-top-box {
    display: flex;

    .flex-left {
      flex: 1;
      background-color: #fff;
      padding: 15px;
      margin-right: 15px;

      h3 {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        margin-bottom: 20px;
      }

      ul {
        padding: 0 30px;
        display: flex;
        justify-content: space-around;

        li {
          .chart-wrap {
            position: relative;

            .ringChart1,
            .ringChart2,
            .ringChart3 {
              width: 160px;
              height: 160px;
            }

            .text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              line-height: 16px;
              font-size: 12px;
              letter-spacing: 0.2px;
              font-weight: 700;
              font-size: 16px;
            }

            .color1 {
              color: #004EA1;
            }

            .color2 {
              color: #23ca96;
            }

            .color3 {
              color: #ffc257;
            }
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90px;

            .item-icon {
              width: 40px;
              height: 40px;

              img {
                vertical-align: bottom;
              }
            }

            .item-content-1 {
              line-height: 26px;
              font-size: 14px;
              letter-spacing: 0.24px;
            }

            .item-content-2 {
              line-height: 28px;
              letter-spacing: 0.27px;
              color: #23ca96;

              span {
                &:nth-of-type(1) {
                  font-size: 20px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    .flex-right {
      flex: 1;
      background-color: #fff;
      padding: 15px;

      h3 {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .air-quality-chart {
        width: 90%;
        height: 100%;
      }
    }
  }

  .flex-bottom-box {
    display: flex;
    margin-top: 15px;
    .flex-left {
      flex: 1;
      min-width: 570px;
      background-color: #fff;
      padding: 15px;
      margin-right: 15px;

      h3 {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .search-wrap {
        padding: 0;
      }
    }

    .flex-right {
      flex: 1;
      background-color: #fff;
      padding: 15px;

      h3 {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .noise-chart {
        width: 90%;
        height: 280px;
      }
    }
  }
}