


.maxWarp {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    // overflow-x: hidden;
}

.titleImg {
    width: 100%;
    height: 400px;
    // background-color: #fff;
   
    padding: 0 10px;
    padding-right: 5px;
    margin: 10px 0;
    img {
        
        width: 100%;
        height: 400px;
    }
    iframe {
        width: 100%;
        height: 400px;
    }
}

.btn {
   display: flex;
   padding-left: 10px;
   p {
       margin-right: 20px;
   }
}

.imgWarp {
    display: flex;
    flex-wrap: wrap;
   // justify-content: space-between;
    li {
        margin: 10px;
        // margin-right: ;
        width: 18.829%;
        .img {
            width: 100%;
            height: 180px;
            background-color: #595959;
            text-align: center;
            line-height: 180px;
            font-size: 30px;
            color: #758080;
            img {
                width: 100%;
                height: 180px;
                cursor: pointer;
            }
        }

        .imgFoot {
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            line-height: 50px;
            padding: 0 20px;
        }

    }


   
    
}

.btn23 {
 
    background-color: #FAA646 !important;
    color: #fff !important;
    border-color: #FAA646 !important;
    margin: 0;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}