




// .red {
//     color: brown;
// }



.operation {
      cursor: pointer;
      color: #6A9BF4;

    display: flex;
    justify-content: space-around;

    .del {
        color: #ff0000 !important;
    }
}  



.carList {
    position: relative;
    .head {
        padding: 10px 0;
        background-color: #fff;
        padding-left: 20px;        
    }

    .allBtn {
        text-align: right;
        position: absolute;
        right: 10px;
    }

    .table2 {
        padding-top: 10px;
        background-color: #fff;
    }

    .table {
        margin-top: 10px;
    }
}
