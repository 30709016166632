.monitoringDetails {
  background-color: #fff;
  .table-wrap {
    margin-top: 0 !important;
    padding: 0 20px 20px;
  }


  .search-wrap {
    padding: 20px;
  }
}