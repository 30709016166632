


.MuckMonitoring {
    position: relative;
    padding: 10px 15px;
    .head {
        margin-bottom: 10px;
    
        >ul {
            display: flex;
            justify-content: space-between;
            >li {
                height: 230px;
                background-color: #fff;
                position: relative;
                width: 37.25%;
                margin-left: 10px;
                // padding: 15px;
                border-radius: 2px;
                
                h1 {
                    font-weight: bold;
    
                    span {
                        font-weight: normal;
                        font-size: 12px;
                        zoom: 0.8;
                    }
                }
    
            }
            >li:nth-child(1) {
                width: 35%;
                margin-left: 0;
                padding: 15px;
                .number {
                    display: flex;
                    
                    >div {
                        flex: 0 0 33.333333%;
                        text-align: center;
                        h2 {
                            margin-bottom: 50px;
                            margin-top: 60px;
                            font-size: 50px;
                            color: #7030A0;
                            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                        }
                        .blue {
                            color: #004EA2;
                        }

                        .red {
                            color: #C71D4D;
                        }
                        
    
                    }
                }
    
            }
            >li:nth-child(2) {
                width: 20%;
                background-color: #C71D4D;
                color: #fff;
                padding: 15px;
                h1 {
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                }
                h2 {
                    padding-top: 30px;
                    color: #fff;
                    font-size: 20px;
                    padding-bottom: 10px;
                    font-weight: bold;
                }
                h3 {
                    color: #fff;
                    padding-top: 50px;
                    font-size: 20px;
                }

                p {
                    line-height: 20px;
                    font-size: 12px;
                }

            }
            >li:nth-child(3) {
                background: none;
                width: 45%;
                // background-color: #fff;
                // display: flex;
                position: relative;

                .warp {
                    width: 100%;
                    height: 230px;

                    display: flex;
                    // overflow-x: auto;
                    // background-color: #C71D4D;
                }

                .item {
                    background-color: #fff;
                    width: 50%;
                    // padding: 20px;
                    padding: 0 20px;
                    padding-top: 20px;
                    margin-left: 10px;
                    // flex-shrink: 0;

                    h2 {
                        font-weight: bold;
                        font-size: 17px;
                        // font-size: 16px;
                    }

                    .pos {
                        display: flex;
                        justify-content: space-between;
                        padding:0 20px;
                        font-weight: bold;
                        font-size: 17px;
                        padding-top: 30px;
                        padding-bottom: 20px;
                        align-items: center;
                        img {
                            width: 50px;
                        }
                        
                    }

                }

                .item:nth-child(1) {
                    margin-left: 0px;
                }

                .process {
                    li {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        
                        span {
                            display: inline-block;
                            width: 70px;
                            font-size: 14px;
                        }

                        i {
                            color: #24AD76;
                            position: relative;
                            left: 5px;
                        }

                        .p1 {
                            width: 50%;
                            margin-right: 20px;
                            display: flex;
                            align-items: center;
                           
                            p {
                                display: flex;
                                width: 100%;
                                height: 15px;
                                background-color: #24AD76;
                                transition: width 1s;
                            }
                        }
                    }

                    li:last-child {
                        .p1:last-child {
                            p {
                                background-color: #FFAB34;
                            }
                        }
                    }


                }

                // div {

                // }

            }
        }
    }


    .select {
        background-color: #fff;
        padding: 15px 10px;
    }


    .listPage {
        margin-top: 10px;
        width: 100%;
        background-color: #fff;
        padding: 20px 30px;
        ul {
            width: 100%;
        }
        li {
            width: 99%;
            
            position: relative;
            .icon {
                display: flex;
                justify-content: space-between;
                
                img {
                    width: 29px;
                }

                .site {
                    // width: 100px;
                    text-align: center;
                    font-size: 12px;    
                    z-index: 10;
                    .img {
                      margin: 10px 0;
                       width: 40px;
                       height: 40px;
                       background-color: #00B050;
                       border-radius: 50%;
                       text-align: center;
                       padding-top: 5px;
                       position: relative;
                       left: 10px;
                       p {
                           width: 100%;
                           position: absolute;
                           text-align: center;
                           top: -20px;
                       }
                    //    margin: 0 auto;
                    }
                }

                .point {
                    width: 100px;
                    text-align: center;
                    font-size: 12px;    
                    z-index: 10;

                    .img {
                        line-height: 20px;
                    }

                }



            }
          
        }

        li::after {
            content: '';
            position: absolute;
            width: calc(100% - 40px);
            height: 1px;
            // border-bottom: 1px dashed #262626;
            top: 32px;
            left: 50px;
            background-image: linear-gradient(to right, #262626 0%, #262626 50%, transparent 0%);
            background-size: 10px 1px;
            background-repeat: repeat-x;
        }

        .addGreen::after {
            background-image: linear-gradient(to right, #00B050 0%, #00B050 50%, transparent 0%);
        }


        .content {
            display: flex;
           font-size: 12px; 
           >div {
               line-height: 20px;
           }

           .cube {
               width: 160px;
               height: 80px;
               background-color: #D9D9D9;
               border: 1px dashed #BFBFBF;
               text-align: center;
               line-height: 80px;
               color: #7F7F7F;
               margin-top: 5px;
           }

           .center {
               text-align: center;
               padding-left: 20px;
           }

           .right {
               text-align: right;
           }


        }

    }
}

