.Projectdistrtion {
        // 底部内容
        .body {
            justify-content: space-between;

            margin-top: 50px;

            padding: 0 30px;

            .leftData {
                display: flex;

                /*自定义动画类----顺时针旋转（使用这个动画的时候才设置动画执行时间）*/
                @keyframes turn{
                    0%{-webkit-transform:rotate(0deg);}
                    25%{-webkit-transform:rotate(90deg);}
                    50%{-webkit-transform:rotate(180deg);}
                    75%{-webkit-transform:rotate(270deg);}
                    100%{-webkit-transform:rotate(360deg);}
                }


                .active {
                    img {
                        animation:turn 1s linear infinite;   
                    }
                    background-color: rgba(0,251,255, 0.2);
                    padding-right: 20px;
                    border-radius: 4px;

                    
                }

                .anmateUl {
                    li {
                        cursor: pointer;
                    }
                }



                .projectList {
                    // display: flex;
                    .title {
                        display: flex;
                        padding-bottom: 15px;                        
                        

                        div {
                            padding-left: 10px;
                            position: relative;
                            line-height: 20px;
                            h3 {
                                color: #00FBFF;
                            }
                        }

                        div::after {
                            content: '';
                            width: 150px;
                            height: 1px;
                            position: absolute;
                            background-color: #556173;
                            bottom: -10px;
                            left: -30px;
                        }

                        img {
                            // width: 30px;
                            width: 40px;
                        }
                    }


                    .tips {
                        margin-top: 20px;
                        p {
                            padding-bottom: 10px;
                            font-size: 14px;
                            span {
                                font-size: 20px;
                                font-weight: bold;
                                color: #00FBFF;
                                i {
                                    font-weight: normal;
                                    font-size: 14px;
                                }
                            }
                        }
                    }


                    ul {
                        margin-top: 20px;
                        li {
                            display: flex;
                            align-items: center;
                            width: 250px;
                            img {
                                width: 40px;
                            }
                            // padding-left: 30px;
                            font-size: 14px;
                        }
                    }

                }


                


                .videoList {
                    z-index: 100;
                    margin-left: 50px;
                    padding-top: 20px;
                    // border-bottom: 1px solid #00FBFF;

                    h1 {
                        width: 700px;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #00FBFF;
                        padding-bottom: 12px;

                        span {
                            color: #A6ACB7;
                        }
                    }


                    .setHeight {
                        height: 640px;
                        overflow: hidden;


                        .moveDiv {
                            // transform: translate(0px, -640px);
                            transition-duration: 1s;
                        }

                        .moveDiv0 {
                            transition-duration: 0s;
                        } 

                    }

                    // .allVideoActive {
                        
                        
                    //     trans

                    // }





                    .allVideo {
                        display: flex;
                        width: 700px;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-content: flex-start;
                        height: 640px;

                        li {
                            width: 345px;
                            height: 200px;
                            // border: 1px solid #fff;
                            margin-top: 10px;
                            cursor: pointer;
                            img {
                                width: 100%;
                                height: 100%;
                            }    
                        }
                    }

                    
                }


            }


            .rightData {
                padding-right: 40px;
                padding-top: 30px;
                z-index: 100;

                img {
                    width: 40px;
                }

                .title {
                    width: 300px;
                    height: 200px;
                    // background-color: rgba(13,30,55,0.2);
                    background-color: rgba(0,0,0,0.2);
                    padding: 10px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    font-size: 14px;
                    position: relative;

                    p {
                        display: flex;
                        align-items: center;
                        position: relative;
                        span {
                            position: absolute;
                            right: 30px;
                            color: #8A949B;
                            font-size: 12px;
                        }
                    }
                }


                .echartBar {
                    width: 100%;
                    height: 150px;
                }

                ul {
                    position: absolute;
                    bottom: 10px;
                    right: 20px;
                    font-size: 12px;
                    li {
                        width: 130px;
                        display: flex;
                        justify-content: space-between;
                        padding: 3px 10px;
                        margin-bottom: 8px;
                        border-radius: 50px;
                        background-color: rgba(33,50,63, 0.7);
                        background-image: linear-gradient(to right,  #0B192C,  rgba(33,50,63, 0.7));
                        position: relative;
                    }

                    

                    .colorActive {
                        // background-color: ;
                        border:1px solid #ddd;
                        border-image: linear-gradient(to right, #284D58, #01FCEB) 20 20;
                        border-left: none;
                        border-right: none;

                        span {
                            color: #00FBFF;
                        }
                    }

                    .colorActive::after {
                       content: '';
                       width: 100%;
                       position: absolute;
                       right: 0;
                       top: 0px;
                       border: 1px solid #00FBFF;
                       height: 18px;
                       border-radius: 50px;
                       border-top: none;
                       border-left: none;
                       border-bottom: none;
                     
                    } 
                }

                .round {
                    position: absolute;
                    top: 80px;
                    left: 40px;
                    width: 90px;
                    height: 90px;
                    border: 1px solid #3D494F;
                    border-radius: 50%;
                    text-align: center;
                    display: flex;
                    align-items: center;

                    .context {
                        padding-top: 0px;
                    }
                    .p {
                        
                        text-align: center;
                        width: 90px;
                        font-size: 10px;
                        line-height: 0px;
                    }

                }


            }

        }


        .setEarth {
            position: absolute;
            top: 0;
            left: 0;
        }


}


.VideoComponentHHModal .setP{
    position: absolute;
    width: 1400px;
    height: 900px;
    left: 0;
    top: 0;
}