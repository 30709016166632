



.Invoice {
    margin: 0 10px;
    margin-top: 10px;


    .setNone {
        display: none;
    }


    .setPwidth {
        // width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }



    .operationTask {
        justify-content: space-between;
    }





    .tableWarp {
        box-sizing: border-box;
        overflow: hidden;
        margin-top: 10px;
    }

    .setWidth {
        width: 100%;
        position: relative;
    }

    .addWidth {
        width: 700px !important;
        white-space:normal !important;
        padding: 0 15px;
        margin-left: 10px;
    }



    .detail {
        width: 0px;
        height: 710px;
        
        overflow-y: auto;
        white-space:nowrap;
        transition:width 1s;
        min-height: 700px;
        background-color: #fff;
        position: relative;
        .close {
            position: absolute;
            width: 100%;
        }

        .title {
            color: #1890FF;
            line-height: 50px;
            font-weight: bold;
            font-size: 16px;
            position: relative;
        }

        .title::after {
            content: '';
            position: absolute;
            width: 70px;
            height: 2px;
            background-color: #1890FF;
            left: 0;
            bottom: 10px;
        }



        .reactive {
            position: absolute;
            right: 12px;
            // left: 453px;
            right: 11px;
            top: 10px;
            width: 25px;
            height: 25px;
            background-color: #8A8A8A;
            font-size: 25px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            border-radius: 50%;
            display: none;
            cursor: pointer;
            z-index: 100;
        }

        .ShowBlock {
            display: block !important;
        }




    .setdetailedInfo {

        h1 {
            padding-left: 8px;
            font-weight: bold;
            color: #808080;
        }

        li {
            display: flex;
            margin: 10px 0;
            line-height: 30px;
            span {
                display: block;
                width: 90px;
                text-align: center;
                letter-spacing:4px;
                color: #808080;
            }

            .noSpacing {
                letter-spacing:0px;
            }

            div {
                // width: 60%;
                width: 140px;
                padding-left: 10px;
                line-height: 30px;
                border-radius: 5px;
                background-color: #F1F2F6;
                color: #595959;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

            }

            .allWidth {
                width: 370px;
            }

        }
    }

    table {
        margin-top: 10px;
        // margin-left: 8px;
        tr {
            th {
                width: 119px;
                border: 1px solid #D9D9D9;
                background-color: #F1F2F6;
                color: #7F7F7F;
                line-height: 30px;
            }
            td {
                text-align: center;
                border: 1px solid #D9D9D9;
                border-top: none;
                line-height: 30px;
            }
        }
    }

    
    }




    .setIcon {
        svg {
            font-size: 30px;
        }
    }

    .FBsfsImg {
        width: 22px;
        cursor: pointer;
    }


    .setMaxHeight {
        height: 400px;
        overflow-y: auto;
        margin-top: 20px;
    }


    .serchBox {
        justify-content: flex-end;

        .setInput {
            background-color: #F2F6FC;
            border: none;
        }

        .styleBtnYellow {
            background: #FFD400;
            border-color: #FFD400;
            border-radius: 3px;
            margin-left: 10px;
        }
    }


    .statisticalInfo {
       .li {
           display: flex;
           justify-content: space-between;
           margin: 10px 0;
           .text {
               display: flex;
               line-height: 30px;
               span {
                   margin-right: 20px;
               }
               div {
                   width: 150px;
                   height: 30px;
                   background-color: #F1F2F6;
                   border-radius: 3px;
                   white-space: nowrap;
                   text-overflow: ellipsis;
                   overflow: hidden;
               }
           }
       }
    }


}