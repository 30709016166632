

.PartyBuild {
    color: #fff;


    .Translate {
    
        .scale-box {
            transform-origin: 0 0;
            position: absolute;
            left: 50%;
            top: 50%;
            overflow: hidden;
            font-size: 16px;
            color: #fff;
        }
    
    }


    .body {
        width: 100%;
        height: 100%;
        background-color: #A54431;
        padding: 0px 10px;
        padding-top: 30px;

        .echartLine {
            width: 100%;
            height: 80%;
        }


        .allContent {
            width: 100%;
            height: 100%;
            border-top: 5px solid #FCDFA7;
            background-color: #A22020;
            position: relative;

            .headImg {
                position: absolute;
                top: -35px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0 20px;
                z-index: 10;
                
                img {
                    width: 200px;
                }
                .flip-vertical {
                    transform: scaleX(-1);   
                }
            }


            .allText {
                display: flex;
                justify-content: space-between;
                height: calc(100% - 10px);
                padding: 0 20px;

                .side {
                    z-index: 10;
                    margin-top: 30px;
                    width: 420px;
                    height: 100%;
                    border-top: 10px solid #F0C872;
                    position: relative;
                    // img {
                    //     position: absolute;
                    //     width: 100%;
                    //     margin-top: -49px;
                    // }    
                    background-color: rgba(172,42,43, 0.9);

                }

                .side::after {
                    content: '';
                    width: 90%;
                    height: 10px;
                    background-color: #F0C872;
                    top: 0;
                    left: 0;
                }
                
                .leftSide {
                    box-shadow: 5px 2px 10px #932121;
                    

                    .sideHead {
                        display: flex;
                        padding-left: 10px;
                        padding-top: 5%;
                        
                        .fistUl {
                            margin-left: 10px;
                            // margin-bottom: 20px;
                            li {
                                font-size: 13px;
                            }
                            // line-height: 20px;
                            li:nth-child(1) {
                                font-size: 25px;
                            }
                            li:nth-child(2) {
                                padding: 5% 0;
                                color: #F1C773;
                            }

                            li:nth-child(3) {
                                color: #D47A77;
                            }

                        }
                        img {
                            width: 60px;
                        }
                    }

                    .line {
                        width: 100%;
                        height: 3px;
                        background-color: #B74141;
                        margin: 5% 0;

                    }

                    .num {
                        font-weight: bold;
                        position: relative;
                        padding-left: 30px;
                        b {
                            font-size: 17px;

                            color: #F1C773;
                            
                            padding-right: 20px;
                        }
 
                    }

                    .num::after {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background-color: #C15646;
                        position: absolute;
                        transform:rotate(45deg);
                        left: 10px;
                        top: 3px;
                    }

                    .typeSex {
                        height: 12%;
                        padding-left: 10px;
                        padding-top: 10px;
                        position: relative;

                        display: flex;
                        justify-content: space-between;

                        h1 {
                            font-size: 25px;
                            line-height: 30px;
                            font-weight: bold;
                            color: #FEB97D;
                            padding-left: 10px;
                            span {
                                font-size: 15px;
                            }
                        }


                        .echartPie {
                            width: 100px;
                            height: 100px;
                            
                        }

                        .setFont {
                            width: 100px;
                            height: 100px;
                            margin-right: 20px;
                            position: relative;
                        
                            .fontCOlor {
                                position: absolute;
                                width: 100px;
                                text-align: center;
                                top: 32px;
                                color: #FACD85;
                                p:first-child {
                                    font-weight: bold;
                                    span {
                                        font-size: 12px;
                                    }
                                }
                                p:last-child {
                                    font-size: 12px;
                                    zoom: 0.8;
                                    padding-top: 10px;
                                }
                            }
                        }

                       

                        ul {
                            display: flex;
                        }
                        li {
                            width: 14px;
                            height: 40px;
                            margin-right: 5px;
                            position: relative;
                            .head {
                                width: 10px;
                                height: 10px;
                                background-color: #80201E;
                                border-radius: 50%;
                                margin-left: 2px;
                            }
                            .bodyHH {
                                border-bottom: 12px solid #80201E;
                                border-left: 3px solid transparent;
                                border-right: 3px solid transparent;
                                height: 0;
                                width: 14px;
                                margin-top: 2px;
                            }

                            .leg {
                                width: 5px;
                                height: 7px;
                                margin-left: 4.3px;
                                background-color: #80201E;
                            }

                            
                            .isFalse {
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 2;
                                width: 0;
                                overflow: hidden;
                                transition: width 1s;
                                .head {
                                    background-color: #E6B495;
                                }
                                .bodyHH {
                                    border-bottom: 12px solid #E6B495;
                                }
                                .leg {
                                    background-color: #E6B495;
                                }

                                .bodyHHMan {
                                    background-color: #E6B495;
                                }

                            }

                            // .isFalse:hover {
                            //     width: 100%;
                            // }
                        }


                        .man {
                            margin-top: 15px;
                            .bodyHHMan {
                                width: 12px;
                                height: 12px;
                                background-color: #80201E;
                                margin-left: 1px;
                                border-radius: 2px;
                                margin-top: 3px;
                            }
                        }


                    }


                    .addPartyNum {
                        height: 18%;
                        h1 {
                            color: #F1C773;
                            font-weight: bold;
                            font-size: 17px;
                            padding-left: 20px;
                        }
                    }

                    .partyActive {
                        .activeList {
                            height: 300px;
                            overflow: hidden; 
                        }

                        .move {
                            position: relative;
                            top: 0;
                            transform: translate(0px, 0px);
                            transition-duration: 1s;
                        }
                        ul {
                            padding: 0 20px;
                            li {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 13px;
                                cursor: pointer;
                                // margin: 7% 0;
                                height: 65px;
                                line-height: 20px;
                                p {
                                    width: 260px;
                                    color: #F1C773;
                                }
                                div {
                                    padding-right: 10px;
                                    width: 100px;
                                    text-align: right;
                                }

                                
                                
                            }
                        }
                    }

                }  
                
                
                .rightSide {
                    box-shadow: -5px 2px 10px #932121;
                    .addPartyNum {
                        .setImg {
                            padding: 0 20px;
                            margin-top: 3%;
                            img {
                                width: 100%;
                            }
                        }
                        
                    }

                    .gailan {
                        height: 16%;

                        .echartPie {
                            width: 100px;
                            height: 100px;
                        }

                        .flex {
                            padding-left: 20px;
                            justify-content: space-between;
                        }

                        .setFont {
                            margin-top: 10px;
                            width: 100px;
                            height: 100px;
                            margin-right: 20px;
                            position: relative;
                        
                            .fontCOlor {
                                position: absolute;
                                width: 100px;
                                text-align: center;
                                top: 32px;
                                color: #FACD85;
                                p:first-child {
                                    font-weight: bold;
                                    span {
                                        font-size: 12px;
                                    }
                                }
                                p:last-child {
                                    font-size: 12px;
                                    zoom: 0.8;
                                    padding-top: 10px;
                                }
                            }
                        }


                    }

                    .Progress {
                       
                        padding: 0 20px;
                        
                        

                        ul {
                            height: 220px;
                            overflow: hidden;
                        }

                        .move {
                            transform: translate(0px, 0px);
                            transition-duration: 1s;
                        }

                        li {
                            // margin:3% 0;
                            height: 55px;
                        }
                        h2 {
                            color: #fff;
                            font-size: 13px;
                            padding-bottom: 5px;
                            font-weight: bold;
                            // font-style:italic;
                        }
                    }
                }



                .earth {
                    width: 980px;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    // z-index: 2;

                    h1 {
                        position: absolute;
                        z-index: 11;
                        font-size: 30px;
                        font-weight: bold;
                        color: #F1C773;
                        top: 95px;
                    }
                    
                    .headImg {
                        width: 900px;
                        margin-top: -10px;
                        z-index: 10;
                    }
                    .build {
                        z-index: 0;
                        position: absolute;
                        bottom: -10px;
                        z-index: 2;
                    }

                    .star {
                        position: absolute;
                        width: 120%;
                        opacity: 0.7;
                    }
                    




                    // background-color: #000;
                }



            }

        }




    }


    .earthCanvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


}

