



// .ant-popover-content {
//     max-width: 500px !important;
// }


.EmergencyManagement {
    padding: 0 15px;

    

    .setContent {
        display: inline-block;
         width: 700px;
         text-align: left;
        // white-space:nowrap;
        // text-overflow: ellipsis;
        // overflow:hidden;
        // cursor:pointer;
    }

    .input {
        ul {
            display: flex;
            padding: 20px 0px;
            padding-bottom: 0;
            flex-wrap: wrap;
            border-bottom: 1px solid #F2F2F2;
            // height: 70px;
            // transition:all .5s;
            // overflow: hidden;
            li {
                margin-left: 40px;
                display: flex;
            }
            li>span {
                padding-right: 10px;
                display: inline-block;
                line-height: 30px;
                width: 80px;
                text-align: right;
            }
    
        }
    
        .btn {
     
            background-color: #FAA646 !important;
            color: #fff !important;
            border-color: #FAA646 !important;
            margin: 0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
        }
    
            
    
    
        .drapBottom {
            width: 60px;
            height: 20px;
            border: 1px solid #EFEFEF;
            text-align: center;
            line-height: 20px;
            margin: 0 auto;
            border-top: none;
            cursor: pointer;
        }
    }
    
    .addOpen {
        height: 150px !important;
        overflow-y: auto !important;
    }
    
    
    
    .input {
        ul {
            li {
                margin-left: -10px !important;
            }
        }
        
    }
}


.setUserRecoveModal {
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;   
}


