



.dTaskList2 {
    margin: 0 0.2rem;
    margin-top: 0.2rem;

    .ant-table-row {
        td {
            padding:0.16rem !important;
        }
    }
    

    .ant-picker {
        padding: 0.04rem 0.11rem 0.04rem;
    }
    .ant-input {
        padding: 0.04rem 0.11rem;
        font-size: 0.14rem;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 0.32rem;
    }
    .ant-select-selection-item {
        line-height: 0.3rem !important; 
    }
   

    .setPwidth {
        // width: 130rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 1.9rem;
    }



    .operationTask {
        justify-content: space-between;
    }


    .serchList {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;

            i {
                padding: 0 0.2rem;
             }
        
        //

        p {
            display: flex;
            align-items: center;
        }     


        .setWidth200 {
            width: 2rem;
        }
        .setWidth100 {
            width: 1rem;
        }
        .setWidth300 {
            width: 4rem;
        }     

        input {
            background-color: #F2F6FC;
            border: 1px solid #F2F6FC;
        }

        .ant-select-selector {
            background-color: #F2F6FC !important;
            border: 1px solid #F2F6FC !important;
        }

        .ant-picker {
            background: #F2F6FC;
            border: 1px solid #F2F6FC;
        }

    }

    .serchBtn {
        display: flex;
        justify-content: space-between;

        .setMargin {
            margin-right: 0.2rem;
        }

        .styleBtnYellow {
            background: #FFD400;
            border-color: #FFD400;
            border-radius: 0.05rem;
        }

        .styleBtnGreen {
            background: #2FC59A;
            border-color: #2FC59A;
            border-radius: 0.05rem;
        }


    }






    .tableWarp {
        box-sizing: border-box;
        overflow: hidden;
        height: 100%;
    }

    .setWidth {
        width: 100%;
        position: relative;
    }

    .addWidth {
        width: 7rem !important;
        white-space:normal !important;
        padding: 0 0.15rem;
        margin-left: 0.1rem;
    }



    .detail {
        width: 0rem;
        height: 7.1rem;
        
        overflow-y: auto;
        white-space:nowrap;
        transition:width 1s;
        min-height: 7rem;
        background-color: #fff;
        position: relative;
        .close {
            position: absolute;
            width: 100%;
        }

        .reactive {
            position: absolute;
            right: 0.12rem;
            // left: 453rem;
            right: 0.11rem;
            top: 1rem;
            width: 0.25rem;
            height: 0.25rem;
            background-color: #8A8A8A;
            font-size: 0.25rem;
            text-align: center;
            line-height: 0.25rem;
            color: #fff;
            border-radius: 50%;
            display: none;
            cursor: pointer;
            z-index: 100;
        }

        .ShowBlock {
            display: block !important;
        }




    .setdetailedInfo {

        h1 {
            padding-left: 0.08rem;
            font-weight: bold;
            color: #808080;
        }

        li {
            display: flex;
            margin: 0.1rem 0;
            line-height: 0.3rem;
            span {
                display: block;
                width: 0.9rem;
                text-align: center;
                letter-spacing:0.04rem;
                color: #808080;
            }

            .noSpacing {
                letter-spacing:0rem;
            }

            div {
                // width: 60%;
                width: 1.4rem;
                padding-left: 0.1rem;
                line-height: 0.3rem;
                border-radius: 0.05rem;
                background-color: #F1F2F6;
                color: #595959;
            }

            .allWidth {
                width: 3.7rem;
            }

        }
    }

    table {
        margin-top: 1rem;
        margin-left: 0.08rem;
        tr {
            th {
                width: 1.125rem;
                border: 1px solid #D9D9D9;
                background-color: #F1F2F6;
                color: #7F7F7F;
                line-height: 3rem;
            }
            td {
                text-align: center;
                border: 1px solid #D9D9D9;
                border-top: none;
                line-height: 3rem;
            }
        }
    }

    
    }




    .setIcon {
        svg {
            font-size: 0.2rem;
        }
    }

    .FBsfsImg {
        width: 0.2rem;
        cursor: pointer;
    }




}